<template>
    <footer>
        <div class="footer-navItem v-container">
            <div class="navs-box">
                <div class="logo">
                    <h1>
                        <img :src="logoUrl" :alt="appName" style="height:70px; width:auto;">
                    </h1>
                    <span style="color: #4268ef;">{{appName}}</span>
                </div>
                <el-row :gutter="20" style="width: 100%;">
                    <el-col :xl="4" :lg="8" :md="8">
                        <div class="left-col">
                            <div>
                                <el-dropdown trigger="click" class="lang-switcher" @command="handleCommand">
                                    <span class="lang-link flex">
                                        {{ curLangName }}<i class="el-icon-arrow-down el-icon--right"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item v-for="item in langOptions" :key="item.lang"
                                            :command="item.lang" class="flex align-center">
                                            <img :src="item.flag" alt=""
                                                style="width: 24px;height:24px; margin-right: 6px;">
                                            {{ item.name }}
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                            <div style="margin-top: 10px;">© 2024 {{appName}}</div>
                            <div style="margin-top: 10px;">{{$t('1146')}}</div>

                            <div class="service flex flex1">
                                <a href="javascript:;" @click.stop="$store.commit('SET_SERVICE', {status: true, url: serviceUrl})" class="item flex align-center">
                                    <img src="./whatsapp.png" alt="">
                                    <span>{{$t('service')}}</span>
                                </a>
                                <a v-if="!!emailUrl&&emailUrl!=='0'" :href="`mailto:${emailUrl}`"
                                    class="item flex align-center" target="_blank">
                                    <img src="./email.png" alt="">
                                    <span>Mail</span>
                                </a>
                                <a v-if="showLine" :href="lineAppUrl.link" class="item flex align-center"
                                    target="_blank">
                                    <img src="./line.png" alt="">
                                    <span>Line</span>
                                </a>
                                <a v-if="showTg" :href="tgUrl" class="item flex align-center" target="_blank">
                                    <img src="./tg.png" alt="">
                                    <span>Telegram</span>
                                </a>
                                <a v-if="showWhatsapp" :href="whatsAppUrl.link" class="item flex align-center"
                                    target="_blank">
                                    <img src="./service.png" alt="">
                                    <span>WhatsApp</span>
                                </a>
                            </div>
                        </div>
                    </el-col>
                    <el-col :xl="4" :lg="8" :md="8" v-for="(navItem, i) in navs" :key="i" class="navs-box-item">
                        <h3 class="navs-title">{{ navItem.title }}</h3>
                        <div class="navs-item-box">
                            <div v-for="(pathRouter, i) in navItem.routes" :key="`${i}_${pathRouter}`"
                                @click="handleRouter(pathRouter.path)" class="navs-item">
                                <!-- <i class="fa fa-chevron-right"></i> --><span>{{ pathRouter.pathName }}</span>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="footer-copyright">
            <p class="col-12 copyright">
                COPYRIGHT © 2017-2024 <span>{{appName}}</span>. ALL RIGHTS RESERVED.
            </p>
        </div>
    </footer>
</template>

<script>
import utils from "@/utils/misc"
import { LANG_OPTIONS } from '@/constants/const'
import i18n from "@/lang";

export default {
    name: "Footer",
    data() {
        return {
            langOptions: LANG_OPTIONS,
            curLang: localStorage.official_locale || window.configObj.defaultLang || 'en-US',
            showMenu: false,
            navs: [
                {
                    title: this.$t("nav5"),
                    routes: [
                        {
                            path: "about",
                            pathName: this.$t("nav6"),
                        },
                        {
                            path: "about",
                            pathName: this.$t("nav7"),
                        },
                        {
                            path: "about",
                            pathName: this.$t("nav8"),
                        },
                        {
                            path: "about",
                            pathName: this.$t("nav9"),
                        },
                    ],
                },
                {
                    title: this.$t("nav14"),
                    routes: [
                        {
                            path: "about",
                            pathName: this.$t("nav10"),
                        },
                        {
                            path: "about",
                            pathName: this.$t("nav11"),
                        },
                        {
                            path: "about",
                            pathName: this.$t("nav12"),
                        },
                        {
                            path: "about",
                            pathName: this.$t("nav13"),
                        },
                    ],
                },
            ],
        };
    },
    mounted() {
        if (window.innerWidth >= 640) {
            this.showMenu = true
        }
        window.onresize = () => {
            if (window.innerWidth >= 640) {
                this.showMenu = true
            }
        }
    },
    methods: {
        onMenu() {
            this.showMenu = !this.showMenu
        },
        handleRouter(path) {
            this.$router.push(path);
        },
        handleCommand(lang) {
            i18n.locale = lang
            this.curLang = lang
            utils.setLs("locale", lang)
            this.$store.dispatch('getAccountNames')
        },
    },
    computed: {
        logoUrl() {
            return window.configObj.logoUri
        },
        appName() {
            return window.configObj.appName
        },
        userInfo() {
            return this.$store.state.userInfo
        },
        curLangName() {
            let result = ''
            for (const item of this.langOptions) {
                if (item.lang === this.curLang) {
                    result = item.name
                }
            }
            return result
        },
        showWhatsapp() {
            return window.configObj.isShowWhatsapp === '1'
        },
        showLine() {
            return window.configObj.isShowLine === '1'
        },
        showTg() {
            return window.configObj.telegram && window.configObj.telegram.trim() !== '0'
        },
        whatsAppUrl() {
            return window.configObj.whatsapp.split('\n').map(item => ({
                name: item,
                link: `https://wa.me/${item}`
            }))[0]
        },
        lineAppUrl() {
            return window.configObj.line.split('\n').map(item => ({
                name: item,
                link: item
            }))[0]
        },
        serviceUrl() {
            let arr = []
            try {
                arr = JSON.parse(window.configObj.customerUrl)
            } catch (e) {
                console.log(e)
            }
            let _arr = arr.map(item => {
                if (this.userInfo.uuid) {
                    let params = item.params || ''
                    params = params.replace(/\{USER_ID\}/g, this.userInfo.uuid)
                    params = params.replace(/\{ACCOUNT\}/g, this.userInfo.mobile || this.userInfo.email)
                    params = params.replace(/\{NICKNAME\}/g, this.userInfo.nickname)
                    item.url += `&${params}&clientid=xclient${this.userInfo.uuid}`
                }
                return item
            })
            return _arr.length ? _arr[0].url : ''
        },
        tgUrl() {
            const arr = window.configObj.telegram.split('\n').map(item => ({
                name: item,
                link: item
            }))
            return arr.length ? arr[0].link : ''
        },
        emailUrl() {
            return window.configObj.customerEmail
        },
    },
};
</script>

<style lang="scss" scoped>
footer {
    position: relative;
    bottom: 0;
    text-align: center;
    background: #19123A;
    //  height: 182px;
    color: #fff;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    overflow: hidden;

    //  padding: 10px;
    .footer-navItem {
        width: 100%;
        // border: 1px solid blue;
        display: flex;
        justify-content: space-between;

        .navs-box {
            display: inline-flex;
            flex-direction: column;
            // margin: 60px 0;
            //   flex-wrap: wrap;
            width: 100%;
            justify-content: space-between;
            // border-bottom: 1px solid rgba(0, 136, 255, 0.3);
            padding-bottom: 40px;

            .logo {
                //  width: 100%;
                position: relative;
                font-size: 25px;
                text-align: center;
                color: #00b5ac;
                margin: 30px 0 0px 0;
                display: flex;
                align-items: center;

                img {
                    width: 134px;
                    height: 51px;
                    margin-right: 20px;
                }
            }

            .left-col {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                color: #B6B3CD;
                font-size: 16px;

                .lang-switcher {
                    color: #fff;
                    font-size: 22px;
                    margin: 20px 0;

                    .lang-link {
                        display: flex;
                        align-items: center;
                    }
                }
                .service {
                    margin-top: 10px;
                    img {
                        width: 36px;
                        height: 36px;
                    }
                    .item {
                        display: flex;
                        flex-direction: column;
                        margin-right:  20px;
                        span {
                            font-size: 12px;
                            margin-top: 10px;
                        }
                    }
                }
            }

            .navs-box-item {
                text-align: left;

                .navs-title {
                    margin: 20px auto;

                    &::before {
                        // content: "";
                        // height: 2px;
                        // width: 33px;
                        // background: #0088ff;
                        // position: absolute;
                        // transform: translate(0, 33px);
                    }
                }

                .navs-item-box {
                    padding-top: 0px;
                    // display: flex;

                    .navs-item {
                        margin: 10px 20px 10px 0px;
                        cursor: pointer;

                        span {
                            color: #fff;
                        }

                        i {
                            margin-right: 8px;
                            color: #0088ff;
                        }
                    }
                }
            }
        }

        .des-long {
            font-size: 8px;
            margin-bottom: 50px;
            color: #999;

            p {
                text-align: left;
            }
        }

        .copyright {
            margin-bottom: 50px;
            height: 80px;
            color: #999;
        }
    }

    .footer-copyright {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        // background: #000;

        .des-long-bg {
            width: 100%;
            background: #222;
            display: flex;
            justify-content: center;
            padding: 50px 20px;

            .des-long {
                color: #999;
                max-width: 1100px;
                text-align: left;
            }
        }

        .copyright {
            line-height: 80px;
            color: #fff;
            font-size: 12px;
        }
    }
}

@media (max-width: 996px) {
    .logo {
        justify-content: center!important;
    }
    .left-col {
        align-items: center!important;
    }
    .navs-box-item {
        text-align: center!important;
        margin-top: 10px;
    }
    // footer .footer-navItem {
    //     padding: 0 10px;

    //     .navs-box {
    //         flex-wrap: wrap;
    //         justify-content: center;

    //         .navs-box-item {
    //             margin: 10px 0;
    //             text-align: center;

    //             .navs-title {
    //                 margin: 0 auto;
    //                 width: 38vw;
    //             }

    //             .navs-title::before {
    //                 display: none;
    //             }

    //             .navs-item-box {
    //                 //  justify-content: space-between;
    //                 justify-content: center;

    //                 .navs-item {
    //                     margin: 10px 10px;
    //                 }
    //             }
    //         }
    //     }
    // }

    // footer .footer-navItem .logo {
    //     margin-top: 100px;
    // }

    // footer .footer-copyright .copyright {
    //     line-height: 18px;
    //     padding: 30px 10px;
    // }
}
</style>
