/*
 @desc 消息提醒
*/
export default {
  methods: {
    /* 所有确认弹窗都能调用该方法，通过传入title和content的值来改变内容
       增加可以设置customClass（自定义类名）；
       */
    confirmWarning({
      title,
      content = '',
      showConfirmButton = true,
      showCancelButton = true,
      confirmButtonText = this.$t(127),
      cancelButtonText = this.$t(1072),  
      customClass,
      type = 'warning',
      confirmButtonClass = 'el-button--danger confirm'
    }) {
      return this.$confirm(content, title, {
        confirmButtonText,
        cancelButtonText,  
        showConfirmButton,
        showCancelButton,
        customClass,
        type,
        confirmButtonClass
      })
    },

    showErrorMsg(message) {
      this.$message({
        message: message,
        type: 'error'
      })
    },

    showWarningMsg(message) {
      this.$message({
        message: message,
        onClose: function() {
          // 关闭事件的回调
        },
        type: 'warning'
      })
    },

    showWarningBox(message) {
      this.$alert(message, {
        closeOnClickModal: true,
        type: 'warning'
      })
    },

    showSuccessMsg(msg) {
      this.$message({
        message: msg,
        type: 'success'
      })
    },
    showInfoMsg(msg) {
      this.$message({
        message: msg,
        type: 'info'
      })
    }
  }
}
