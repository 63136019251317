<template>
  <div class="main-coins">
    <div class="title-box">
      <h1>{{$t('1167')}}</h1>
      <h3>{{$t('1168')}}</h3>
    </div>
    <div class="coins-list">
      <ul>
        <li v-for="(item,index) in mainCoins" :key="index">
          <div class="left-box">
            <img :src="coinMsg[item.coin]?coinMsg[item.coin].logoUri:''"/>
          </div>
          <div class="right-box">
            <div class="currency-type">{{item.coin }}</div>
            <div class="currency-info">
              <div class="title">USDT {{item.price}}</div>
              <div class="ups-and-downs" :class="[item.change > 0 ? 'green-color' : 'red-color']">{{item.change > 0 ? '+'+item.change : item.change}}%</div>
            </div>
          </div>
          <img class="k-line" v-if="item.change > 0" src="@/assets/img/home6/green.png" />
          <img class="k-line" v-else src="@/assets/img/home6/red.png" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import utils from '@/utils/misc'
export default {
  data() {
    return {
    }
  },
  computed: {
    mainCoins() {
      const hotCoins = ['BTC', 'ETH', 'DOGE', 'EOS', 'FIL']
      const arr = []
      for (const key in this.$store.state.spotList) {
        arr.push(this.$store.state.spotList[key])
      }
      console.log(arr.filter(item => hotCoins.includes(item.coin)))
      return arr.filter(item => hotCoins.includes(item.coin))
    },
    coinMsg() {
      return this.$store.state.coins
    }
  },
}    
</script>


<style lang="scss" scoped>
@media screen and (max-width: 1000px) {
  .main-coins {
    .coins-list {
      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          width: 100% !important;
          background: red;
          height: 120px;
        }
      }
    }
  }
}

.main-coins {
  .title-box {
    h1 {
      color: #fff;
    }

    h3 {
      color: #8393AF;
      margin-top: 20px;
    }
  }

  .coins-list {
    margin-top: 20px;

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        position: relative;
        width: 31%;
        height: 120px;
        display: flex;
        margin-right: 2%;
        align-items: center;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 12px;
        margin-top: 18px;

        .left-box {
          width: 80px;
          text-align: center;
          height: 50px;

          img {
            width: 50px;
            height: 50px;
          }
        }

        .right-box {
          flex: 1;

          .currency-type {
            font-size: 26px;
          }

          .currency-info {
            display: flex;
            margin-top: 10px;
            padding-right: 15px;

            .ups-and-downs {
              font-size: 16px;
              text-align: right;
              flex: 1;
            }

            .title {
              font-size: 16px;
            }
          }
        }
      }

      .k-line {
        position: absolute;
        right: 15px;
        top: 20px;
        width: 40%;
      }
    }
  }

  .green-color {
    color: #48D49E;
  }

  .red-color {
    color: #E4474B;
  }
}
</style>
