/* eslint-disable no-unused-vars */

import isEmpty from 'lodash.isempty'
import Vue from 'vue'
const lsPrefix = 'official_'

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}
export function findIndex(arr, fun) {
	if (!arr || typeof fun !== 'function') return -1
	let index = -1
	for (let i = 0; i < arr.length; i++) {
		if (fun.call(null, arr[i])) {
			index = i
			break;
		}
	}
	return index
}
export function handleScrollHeader(callback) {
  let timer = 0

  let beforeScrollTop = window.pageYOffset
  callback = callback || function() { }
  window.addEventListener(
    'scroll',
    event => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        let direction = 'up'
        const afterScrollTop = window.pageYOffset
        const delta = afterScrollTop - beforeScrollTop
        if (delta === 0) {
          return false
        }
        direction = delta > 0 ? 'down' : 'up'
        callback(direction)
        beforeScrollTop = afterScrollTop
      }, 50)
    },
    false
  )
}

export function isIE() {
  const bw = window.navigator.userAgent
  const compare = (s) => bw.indexOf(s) >= 0
  const ie11 = (() => 'ActiveXObject' in window)()
  return compare('MSIE') || ie11
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate(id = '', timeout = 1500) {
  if (id === '') {
    return
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id))
  }, timeout)
}

export function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}


/**
 * 去除对象中所有符合条件的对象
 * @param {Object} obj 来源对象
 * @param {Function} fn 函数验证每个字段
 */
export function compactObj(obj, fn) {
  for (var i in obj) {
    if (typeof obj[i] === 'object') {
      compactObj(obj[i], fn)
    }
    if (fn(obj[i])) {
      delete obj[i]
    }
  }
}
/**
 *获取北京时间
 *
 * @export
 * @returns
 */
export function getBJDate() {
  // 获得当前运行环境时间
  var d = new Date(); var currentDate = new Date(); var tmpHours = currentDate.getHours()
  // 算得时区
  var timeZone = -d.getTimezoneOffset() / 60
  // 少于0的是西区 西区应该用时区绝对值加京八区 重新设置时间（西区时间比东区时间早 所以加时区间隔）
  if (timeZone < 0) {
    timeZone = Math.abs(timeZone) + 8; currentDate.setHours(tmpHours + timeZone)
  } else {
    // 大于0的是东区  东区时间直接跟京八区相减
    timeZone -= 8; currentDate.setHours(tmpHours - timeZone)
  }
  return currentDate
}

export function filterDefaultRouter(routers, name) {
  for (let i = 0; i < routers.length; i++) {
    if (routers[i].name === name) {
      return true
    }
    if (routers[i].children && routers[i].children.length > 0) {
      if (filterDefaultRouter(routers[i].children, name)) {
        return true
      }
    }
  }
  return false
}

const GlobalView = new Vue({})

const utils = {
  on(type, fn) {
    GlobalView.$on(type, fn)
  },

  off(type) {
    GlobalView.$off(type)
  },

  emit(type, args) {
    GlobalView.$emit(type, args)
  },

  isArray(obj) {
    return Object.prototype.toString.call(obj) === '[object Array]'
  },

  isEmpty(value) {
    if (typeof value === 'object') {
      return isEmpty(value)
    }
    return value === '' || value === undefined
  },
  // 格式化日期，将Date实例转换成格式化的字符串
  formatDate(date, showHours = false) {
    if (date === '' || date === undefined) return ''
    if (typeof date === 'string') return date
    date = new Date(date)
    const year = date.getFullYear()
    const month = `0${date.getMonth() + 1}`.slice(-2)
    const day = `0${date.getDate()}`.slice(-2)
    let extra = ''
    if (showHours) {
      const hours = `0${date.getHours()}`.slice(-2)
      const minutes = `0${date.getMinutes()}`.slice(-2)
      const seconds = `0${date.getSeconds()}`.slice(-2)
      extra = ` ${hours}:${minutes}:${seconds}`
    }
    return `${year}-${month}-${day}${extra}`
  },
  // 格式化时间，将时间戳转换成HH:mm:ss
  formatTime(stamp) {
    if (stamp === '' || stamp === undefined) return ''
    if (typeof stamp === 'number') {
      const date = new Date(stamp)
      let time = ''
      const hours = `0${date.getHours()}`.slice(-2)
      const minutes = `0${date.getMinutes()}`.slice(-2)
      const seconds = `0${date.getSeconds()}`.slice(-2)
      time = ` ${hours}:${minutes}:${seconds}`
      return time
    } else {
      return stamp
    }
  },
  // 防抖动
  debouce(fn, context, time = 50) {
    return (...arg) => {
      clearTimeout(fn.id)
      fn.id = setTimeout(() => {
        fn.apply(context, arg)
      }, time)
    }
  },
  // 节流
  throttle(func, delay) {
    var timer = null
    var startTime = Date.now()
    return function() {
      var curTime = Date.now()
      var remaining = delay - (curTime - startTime)
      var context = this
      var args = arguments
      clearTimeout(timer)
      if (remaining <= 0) {
        func.apply(context, args)
        startTime = Date.now()
      } else {
        timer = setTimeout(func, remaining)
      }
    }
  },
  isObject(value) {
    return Object.prototype.toString.call(value) === '[object Object]'
  },
  // timePicker HH:mm:ss格式化为分钟数(秒数)
  timeToSeconds(time, toSecond = true) {
    if (typeof time !== 'string' || time === '') return null
    let value = 0
    const count = toSecond ? 2 : 1
    time.split(':').map((item, index) => {
      value += item * (Math.pow(60, count - index))
    })
    return value
  },
  /**
     * timePicker 将分钟(秒数)数转换为字符
     * @params value: Number
     * @params size 3：HH:mm:ss; 2：HH:mm
     *
     *  */
  secondsToTime(value, size = 3, valueIsSecond = true) {
    if (utils.isEmpty(value)) return ''
    let num = valueIsSecond ? value : value * 60
    let count = 1
    let str = ''
    while (count < 4) {
      str += ('0' + (0 | (num / Math.pow(60, 3 - count)))).slice(-2) + ':'
      num = num % Math.pow(60, 3 - count)
      count++
    }
    str = str.replace(/:$/, '')
    return size === 3 ? str : str.replace(/:\d{2}$/, '')
  },
  setLs(key, value) {
    const _key = `${lsPrefix}${key}`
    if (utils.isObject(value)) {
        localStorage[_key] = JSON.stringify(value)
    } else {
        localStorage[_key] = value
    }
  },
  getLs(key) {
    const _key = `${lsPrefix}${key}`
    let value = localStorage[_key]
    if (/^({|\[).*(]|})$/.test(value)) {
        return JSON.parse(value)
    }
    return localStorage[_key]
  },
  removeLs(key) {
    const _key = `${lsPrefix}${key}`
    return localStorage.removeItem(_key)
  },
  getQuery() {
    const url = window.location.href
    const queryStr = url.split('?')[1] || ''
    const arr = queryStr.split('&')
    const obj = {}
    arr.map(item => {
      const _arr = item.split('=')
      obj[_arr[0]] = _arr[1]
    })
    return obj
  }
}

export default utils
