<template>
    <div class="HowItWorks">
      <div class="title-box">
          <h1>{{$t('1108')}}</h1>
          <h3>{{$t('1162')}}</h3>
      </div>
      <div class="works-list">
        <ul>
          <li>
            <div class="how-img">
              <img src="@/assets/img/home7/how1.png" />
            </div>
            <p>{{$t('1163')}}</p>
          </li>
          <li>
            <div class="how-img">
              <img src="@/assets/img/home7/how2.png" />
            </div>
            <p>{{$t('1164')}}</p>
          </li>
          <li>
            <div class="how-img">
              <img src="@/assets/img/home7/how3.png" />
            </div>
            <p>{{$t('1165')}}</p>
          </li>
        </ul>
      </div>
    </div>
</template>

<script>
import { getCarousels } from "@/api";    
export default {
    data() {
        return {
       
        }
    },
    methods: {

    }
}    
</script>

<style lang="scss" scoped> 
.HowItWorks{
  margin-top: 120px;
  .title-box{
    h1{

    }
    h3{
      margin-top: 20px;
    }
  }
  .works-list{
    margin-top: 180px;
    ul{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li{
        width: 31%;
        height: 190px;
        background: rgba(255, 255, 255, 0.43);
        box-shadow: 0px 4px 50px rgba(116, 71, 117, 0.25);
        border-radius: 24px;
        padding: 0 20px;
        padding-top: 70px;
        position: relative;

        .how-img{
          display: flex;
          justify-items: center;
          img{
            height: 200px;
            margin: 0 auto;
            margin-top: -200px;
          }
        }
        p{
          text-align: center;
          font-size: 16px;
          padding-bottom: 30px;
          padding-top: 15px;
          display: block;
          height: 100px;
        }
      }
    }
  }
}
@media screen  and (max-width: 1000px) {
  .works-list{
    margin-top: 40px !important;
    ul{
      li{
        width: 100% !important;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 24px;
        padding: 15px;
        margin-top: 20px;
        padding-top: 20px !important;
        img{
          width: 100%;
          display: none !important;
        }
        p{
          text-align: center;
          font-size: 16px;
          padding-bottom: 30px;
          padding-top: 15px;
          display: block;
          height: 100px;
        }
      }
    }
  }
}
</style>