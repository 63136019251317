
<template>
  <modal :visible="value" @onClosed="doClose" size="xsmall">
    <span slot="head">{{ $t(1080) }}</span>
    <div slot="body">
        <div v-html="wealthProtocol" class="financial-htmlbody-des"></div>
    </div>
    <span slot="foot">
      <el-button type="primary" @click="doClose">{{
        $t(1038)
      }}</el-button>
    </span>
  </modal>
</template>
<script>
import Modal from "@/components/Modal";
import { getIntro } from "@/api";
export default {
  name: "aboutDialog",
  components: { Modal },
  props: {
    value: Boolean
  },

  data() {
    return {
        wealthProtocol: ''
    };
  },
  mounted() {
    getIntro().then(res => {
      this.wealthProtocol = res.wealthProtocol
    })
  },
  methods: {
    doClose() {
      this.$emit("input", false);
    },
  },
};
</script>

<style lang="scss">
.financial-htmlbody-des {
    max-height: 58vh;
    overflow: auto;
}
</style>