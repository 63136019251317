<template>
  <div class="HowItWorks">
    <div class="v-container">
      <div class="title-box">
        <h1>{{$t('1108')}}</h1>
        <h3>{{$t('11081')}}</h3>
      </div>
    </div>
    <div class="works-list">
      <ul>
        <li class="how-bg-one">
          <div class="text">
            {{$t('1200')}}
          </div>
        </li>
        <li class="how-bg-two">
          <div class="text">
            {{$t('1201')}}
          </div>
        </li>
        <li class="how-bg-three">
          <div class="text">
            {{$t('1204')}}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getCarousels } from "@/api";
export default {
  data() {
    return {

    }
  },
  methods: {

  }
}    
</script>

<style lang="scss" scoped>
.HowItWorks {
  margin-top: 120px;

  .title-box {
    text-align: center;

    h1 {
      font-size: 50px;
    }

    h3 {
      margin-top: 20px;
      color: #91929E;
    }
  }

  .works-list {
    margin-top: 180px;

    ul {
      li {}

      .how-bg-one {
        height: 400px;
        background: url("~@/assets/img/home8/img_1.png") no-repeat right;
        background-size: auto 100%;
        display: flex;
        align-items: center;

        .text {
          width: 700px;
          font-size: 28px;
          color: #91929E;
          margin-left: 20%;
        }
      }

      .how-bg-two {
        height: 500px;
        background: url("~@/assets/img/home8/img_2.png") no-repeat left;
        background-size: auto 100%;
        display: flex;
        align-items: center;

        .text {
          width: 700px;
          font-size: 28px;
          color: #91929E;
          margin-left: 40%;
        }
      }

      .how-bg-three {
        height: 520px;
        background: url("~@/assets/img/home8/img_3.png") no-repeat right;
        background-size: auto 100%;
        display: flex;
        align-items: center;

        .text {
          width: 700px;
          font-size: 28px;
          color: #91929E;
          margin-left: 20%;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .works-list {
    margin-top: 40px !important;

    ul {
      li {
        width: 100% !important;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 24px;
        padding: 15px;
        margin-top: 20px;
        padding-top: 20px !important;

        img {
          width: 100%;
          display: none !important;
        }

        p {
          text-align: center;
          font-size: 16px;
          padding-bottom: 30px;
          padding-top: 15px;
          display: block;
          height: 100px;
        }
      }
    }
  }
}
</style>