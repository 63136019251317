<template>
  <div class="wraper quotes">
    <div
      class="Box row active animated "
      v-animated="{ className: 'fadeInRight' }"
    >
      <div class="col-12">
        <div class="wraper-box">
          <div v-if="modle === '1'" class="title-box">
            <div class="title-box-text">
              <h1 class="title">{{ $t('59') }}</h1>
              <h3>{{ $t('601') }}</h3>
            </div>
          </div>
          <!-- 內文 -->
          <div class="content-box" :class="{'content-box-2': modle === '2'}">
            <div class="content-box-item">
              <div class="cadvBox">
                <div v-for="item in hotCoins" :key="item.marketShowName" class="cadvBox-item-2">
                  <div class="cadvBox-item">
                    <div class="left">
                      <h2>{{ item.marketShowName }}</h2>
                      <h3 :class="{'red':item.riseRate<0,'gr':item.riseRate>0}">
                        {{item.lastPrice}}<span class="des">/{{(item.lastPrice*1.02).toFixed(2)}}USD</span>
                      </h3>
                    </div>
                    <div class="right">
                      <img
                        :src="item.riseRate>0?upchart:downchart"
                        alt="icon"
                        class="icon"
                      />
                    </div>
                    <div class="buttom">
                      <span class="mr10" :class="{'red':item.riseRate<0,'gr':item.riseRate>=0}">{{item.riseRate}}%</span>
                      <span class="des">VOL： {{item.vol}} {{item.showName}}</span>
                    </div>
                  </div>
                </div>
              </div>

              <TableView
                :headers="tableHeaders"
                :rows="dataList"
                :sort="sort1"
                :tabs="tabs"
                @changeTab="tabCoin"
              >
                <template v-slot:operate>
                  <span>{{$t('operate')}}</span>
                </template>

                <template v-slot:items="{ row }">
                  <td>{{ row.marketShowName }}</td>
                  <td :class="[parseInt(row.riseRate) > 0 ? 'gr' : 'red']">
                    {{ row.lastPrice }}
                  </td>
                  <td :class="[parseInt(row.riseRate) > 0 ? 'gr' : 'red']">
                    {{ row.riseRate }}%
                  </td>
                  <td>{{ row.hightPrice }}</td>
                  <td>{{ row.lowPrice }}</td>
                  <td>{{ row.vol }}</td>
                </template>

                <template v-slot:no-data>
                  <span>Loading data...</span>
                </template>
              </TableView>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableView from "@/components/TableView";
import { createSocket, sendWSPush } from '../utils/socket'
const upchart = require('../assets/img/risechart.png')
const downchart = require('../assets/img/downchart.png')
export default {
  name: "Quotes",
  props: ["lang"],
  components: {
    TableView,
  },
  data() {
    return {
      upchart,
      downchart,
      dataList: [],
      currentCoin: 'top_all_usdt',
      searchQuery: "",
      tabs: ["USDT", "SXC", "BTC", "ETH", "XT"],
      sort1: {
        field: "first_name",
        order: "asc",
      },
      sort2: {
        field: "email",
        order: "asc",
      },
      pagination: {
        itemsPerPage: 7,
        align: "center",
        visualStyle: "select",
      },
      hotCoins:[]
    };
  },
  created(){
    createSocket()
    this.tabCoin(this.currentCoin)
  },
  computed: {
    modle() {
      return window.configObj.officialModel
    },  
    // quotesDatas() {
    //   return {
    //     items: [
    //         {right: true, long_line: false,type: this.$t('60'), t: this.$t('61'), p: this.$t('62')},
    //         {right: false, long_line: true, type: this.$t('63'), t: this.$t('64'), p: this.$t('65')},
    //         {right: true, long_line: true, type: this.$t('66'), t: this.$t('67'), p: this.$t('68')},
    //         {right: false, long_line: true, type: this.$t('69'), t: this.$t('70'), p: this.$t('71')}
    //     ]
    //   }
    // },
    tableHeaders() {
      return [
          { "label": this.$t('88'), "field": "first_name", "sortable": true, "type": "String" },
          { "label": this.$t('89'), "field": "last_name", "sortable": true, "type": "String" },
          { "label": this.$t('90'), "field": "email", "sortable": true, "type": "String" },
          { "label": this.$t('91'), "field": "age", "sortable": true, "type": "Number" },
          { "label": this.$t('92'), "field": "country", "sortable": true, "type": "String" },
          { "label": this.$t('93'), "field": "category", "sortable": true, "type": "String" }
      ]
    }
  },
  mounted() {
  },
  methods: {
    handleTable() {
      console.log("handleTable");
    },
    tabCoin(channel) {
      this.currentCoin = channel
      var params = {
        event: 'addChannel',
        channel: channel
      }
      sendWSPush(params)
      window.removeEventListener('onmessageWS',null)
      window.addEventListener('onmessageWS', (e) => {
        var data = JSON.parse(e.detail.data.data.replace('(', '').replace(')', ''))
        if (data.channel === this.currentCoin) {
          this.dataList = data.datas.slice(1,54).filter(item => item.lastPrice > 0)
          this.hotCoins =data.datas.slice(1,5)
        }

      })
    }
  },
};
</script>

<style lang="scss" scoped>
.wraper {
  margin: 0 0 100px;
  .row {
    justify-content: space-around;
  }
  .wraper-box {
    display: flex;
    align-items: center;
    text-align: left;
    // justify-content: center;
    flex-wrap: wrap;
    padding: 0;
    .title-box {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      .title-box-text {
        width: 85%;
        max-width: 1200px;
        text-align: left;
        .title {
          font-size: 65px;
          font-weight: bold;
          margin-bottom: 40px;
        }
        h3 {
          font-size: 25px;
          font-weight: 300;
          margin: 15px 0;
        }
      }
    }
    .content-box {
      width: 100%;
      display: flex;
      justify-content: center;
      background: #fbfbfb;
      color: #222;
      .content-box-item {
        width: 85%;
        max-width: 1200px;
        padding: 120px 15px;
        .cadvBox {
          width: 100%;
          display: flex;
          .cadvBox-item-2 {
            width: 25%;
            display: flex;
            .cadvBox-item {
              display: flex;
              flex-wrap: wrap;
              background: #fff;
              margin: 0 10px;
              padding: 15px;
              padding-bottom: 60px;
              margin-bottom: 20px;
              h2 {
                  font-weight: 400;
              }
              h3 {
                margin: 10px 0;
              }
              .des {
                font-size: 13px;
                color: #999;
              }
              .buttom {
                width: 100%;
                // position: relative;
                // right: 0;
                // top: 0;
              }
              .left {
                width: 61%;
                max-height: 80px;
                margin-top: 20px;
                display: inline-flex;
                flex-wrap: wrap;
                align-items: center;
                h2 {
                  width: 100%;
                }
              }
              .right {
                width: 39%;
                height: 100%;
                overflow: hidden;
                display: flex;
                align-items: center;
                img {
                  object-fit: fill;
                }
              }
            }
          }
        }
        .table-icon {
          transform: translateY(3px);
          width: 20px;
        }
      }
      &.content-box-2 {
          background:  #020017;
          .cadvBox-item{
              background: #181730 !important;
              color: #fff; 
          }
      }
    }
  }
}
.quotes {
  .wraper-box {
    .title-box {
      height: 72vh;
      background-image: url(../assets/img/行情-banner.png);
    }
  }
}
@media (max-width: 1200px) {
  .wraper {
        margin: 0 0 10px;
    .wraper-box {
      .title-box {
        height: 50vh;
        margin-top: 40px;
        .title-box-text {
          padding: 0 8px;
          .title {
            font-size: 40px;
            margin-bottom: 15px;
            color: #fff;
          }
          h1 {
            text-align: left;
            font-size: 18px;
            font-weight: 300;
            color: rgb(215, 215, 215);
          }
          h3 {
            font-size: 18px;
          }
        }
      }
      .content-box {
        .content-box-item {
          width: 100%;
          padding: 10px 15px;
          .cadvBox {
              overflow-x: scroll;
            //   flex-wrap: wrap;
              .cadvBox-item-2 {
                  min-width: 173vw;
                  .cadvBox-item {
                      min-width: 300px;
                  }
              }
          }
        }
      }
      .content-box-2 {
          margin-top: 10vh;
      }
    }
  }
}
</style>