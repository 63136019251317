const WSS_URL = `wss://api.zb.sale/websocket`
let Socket = ''
let heartbeatTimer = null

/** 建立连接 */
export function createSocket() {
  if (!Socket) {
    Socket = new WebSocket(WSS_URL)
    Socket.onopen = onopenWS
    Socket.onmessage = onmessageWS
    Socket.onerror = onerrorWS
    Socket.onclose = oncloseWS
  }
}
/** 打开WS之后发送心跳 */
export function onopenWS() {
//   sendPing() //发送心跳
}
/** 连接失败重连 */
export function onerrorWS() {
  clearInterval(heartbeatTimer)
  Socket.close()
  createSocket() // 重连
}
/** WS数据接收统一处理 */
export function onmessageWS(e) {
  // console.log(e)
  window.dispatchEvent(new CustomEvent('onmessageWS', {
    detail: {
      data: e
    }
  }))
}
/** 发送数据
 * @param eventType
 */
export function sendWSPush(eventTypeArr) {
  if (Socket !== null && Socket.readyState === 3) {
    Socket.close()
    createSocket() // 重连
  } else if (Socket.readyState === 1) {
    Socket.send(JSON.stringify(eventTypeArr))
  } else if (Socket.readyState === 0) {
    setTimeout(() => {
      Socket.send(JSON.stringify(eventTypeArr))
    }, 3000)
  }
}
/** 关闭WS */
export function oncloseWS() {
  clearInterval(heartbeatTimer)
}
/** 发送心跳 */
export function sendPing() {
  Socket.send('ping')
  heartbeatTimer = setInterval(() => {
    Socket.send('ping')
  }, 5000)
}
