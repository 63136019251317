<template>
    <div class="case-block" :class="{'black':color === 'black', 'model-2': styleModel === '2'}" @click="fn" ref="buttom">
        <img v-if="pic" :src="pic" alt="pic">
        {{text}}
    </div>
</template>
<script>
    export default {
        props:['buttonText','color', 'pic','styleModel'],
        data(){
            return{
                text: this.buttonText
            }
        },
        methods:{
            fn(e){
                e.stopPropagation()
                this.$emit('click')
            },
        },
    }
</script>
<style lang="scss" scoped>
    .case-block{
        max-width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 90px;
        height: 40px;
        color: #fff;
        background: transparent;
        border: 1px solid #fff;
        border-radius: 10px;
        cursor: pointer;
        user-select:none;
        padding: 10px 20px;
        &.black {
          background: transparent;
          color: #00b5ac;
          border: 1px solid #00b5ac;
          border-radius: 30px;  
        }
        &.model-2 {
          border-radius: 0;
          padding: 10px;
        }
        img {
            width: 25px;
            margin: 0 5px;
        }
    }
</style>