import Vue from 'vue'
import VueI18n from 'vue-i18n'

import tw from './langPackage/tw'
import en from './langPackage/en'
import zh from './langPackage/zh'
import ja from './langPackage/ja'
import ko from './langPackage/ko'
import fr from './langPackage/fr'
import th from './langPackage/th'
import ms from './langPackage/ms'
import ru from './langPackage/ru'
import it from './langPackage/it'
import de from './langPackage/de'
import es from './langPackage/es'
import pt from './langPackage/pt'
import vi from './langPackage/vi'
import tr from './langPackage/tr'
import ElementLocale from 'element-ui/lib/locale'
ElementLocale.i18n((key, value) => i18n.t(key, value))
Vue.use(VueI18n)

const locale = localStorage.getItem('official_locale') || 'en-US'
const messages = {
    'en-US': en,
    'zh-CN': zh,
    'zh-TW': tw,
    'th-TH': th,
    'ja-JP': ja,
    'ms-MY': ms,
    'ko-KR': ko,
    'ru-RU': ru,
    'fr-FR': fr,
    'it-IT': it,
    'de-DE': de,
    'es-ES': es,
    'pt-PT': pt,
    'vi-VN': vi,
    'tr-TR': tr,
}

const i18n = new VueI18n({
    locale:locale,
    messages
})
export const switchLang = (lang) => {
    i18n.locale = lang
    localStorage.lang = lang
}
window.transLang = (title) => i18n.t(title)
export default i18n