<template>
  <div class="download-area">
    <div class="title-box">
      <h1>{{$t('1202')}}</h1>
      <h3>{{$t('85')}}</h3>
    </div>
    <div class="v-container down-box">
      <div class="down-left">
        <div class="down-info">
          <div class="downIOS">
            <div class="QRcode">
              <vue-qr :size="180" :text="downloadUrl" style="width: 100%; height: 100%;"></vue-qr>
            </div>
            <div class="QRinfo">
              <p class="title">{{$t('1166')}}</p>
              <p class="text">ios & Android</p>
            </div>
          </div>
          <div class="but-box">
            <div class="but" @click="goToDownload">
              IOS {{$t('1127')}}
            </div>
            <div class="but" @click="goToDownload">
              Android {{$t('1127')}}
            </div>
            <div class="but" @click="goToH5">
              H5 {{$t('1128')}}
            </div>
          </div>
        </div>
      </div>
      <div class="down-right">
        <img src="~@/assets/img/home8/img_4.png" />
      </div>
    </div>
  </div>
</template>

<script>
import vueQr from 'vue-qr'
export default {
  computed: {
    downloadUrl() {
      return window.configObj.downloadUrl
    }
  },
  components: {
    vueQr
  },
  methods: {
    goToDownload() {
      window.location.href = window.configObj.downloadUrl;
    },
    goToH5() {
      window.location.href = location.origin + "/h5/";
    },
  }
}    
</script>

<style lang="scss" scoped>
.download-area {
  //  height: 500px;
  margin: 0 auto;
  padding: 100px 0;
  color: #fff;

  .title-box {
    text-align: center;

    h1 {
      font-size: 50px;
    }

    h3 {
      margin-top: 20px;
      color: #91929E;
    }
  }

  .down-box {
    display: flex;
    margin-top: 100px;

    .down-left {
      flex: 1;
      display: flex;
      align-items: center;
      height: 500px;

      h1 {
        font-size: 40px;
        font-weight: bold;
      }

      h3 {
        font-size: 16px;
        padding: 30px 0;
      }

      .down-info {
        .downIOS {
          display: flex;
          background: #0F3043;
          // width: 500px;
          align-items: center;
          padding: 20px;
          border-radius: 10px;

          .QRcode {
            width: 180px;
            height: 180px;
          }

          .QRinfo {
            flex: 1;
            text-align: center;

            .title {
              font-size: 20px;
            }

            .text {
              font-size: 30px;
            }
          }
        }
      }

      .but-box {
        display: flex;
        margin-top: 20px;

        .but {
          width: 200px;
          height: 58px;
          border: 2px solid #81EEDC;
          display: flex;
          color: #81EEDC;
          cursor: pointer;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          margin-right: 15px;

          img {
            width: 24px;
            margin-left: 15px;
          }
        }
      }
    }

    .down-right {
      flex: 1;
      height: 500px;
      text-align: right;

      img {
        height: 90%;
        position: relative;
        right: 0;
        top: 5%;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .download-area {
    width: 100% !important;
    height: auto !important;

    .down-right {
      display: none !important;
    }

    .down-left {
      display: block !important;
    }

    .down-box {
      display: block !important;
    }

    .but-box {
      display: block !important;

      .but {
        margin-top: 15px;
      }
    }
  }
}
</style>