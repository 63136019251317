<template>
  <div class="wrapper">
    <header :class="{'timex': isTimeX}">
      <section class="tp-banner-container" v-if="modle === '1'">
        <div class="tp-banner"  :class="{'timex': isTimeX}">
          <div
            class="tp-caption active animated "
            v-animated="{ className: 'fadeInDown' }"
          >
            <div class="logoBox">
              <h1>{{ $t("homeTitle_1_1") }}</h1>
              <div class="text-box">
                <h3>{{ $t("homeTitle_1_2") }}</h3>
              </div>
              <div class="btn flex-nw">
                <a href="#" class="d-btn" @click="goToDownload">IOS Download</a>
                <a href="#" class="d-btn" @click="goToDownload">Android Download</a>
                <a href="#" class="d-btn" @click="goToH5">H5 Login</a>
              </div>
            </div>
          </div>
          <div v-if="isTimeX" class="timexBanner">
            <img src="@/assets/img/timex/banner.png" alt="">
          </div>
        </div>
      </section>

      <section
        class="tp-banner-container tp-banner-container-2"
        v-if="modle === '2'"
      >
        <div class="tp-banner tp-banner-2">
          <div
            class="tp-caption active animated "
            v-animated="{ className: 'fadeInDown' }"
          >
            <div class="logoBox-2">
              <div class="left">
                <h1>Trading</h1>
                <br />
                <h1>platform</h1>
                <div class="text-box">
                  <h3>The world's leading</h3>
                </div>
              </div>
              <div class="btn">
                <img
                  src="@/assets/img/iosd.png"
                  @click="goToDownload"
                  alt="ios"
                />
                <img
                  src="@/assets/img/androidd.png"
                  @click="goToDownload"
                  alt="an"
                />
                <img src="@/assets/img/h5d.png" @click="goToH5" alt="h5" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </header>
    <div class="v-container">
    <div class="main-coins">
        <div class="coin-item flex align-center" v-for="item in mainCoins" :key="item.coin">
        <img :src="coinMsg[item.coin]?coinMsg[item.coin].logoUri:''" style="width: 30px;margin-right: 10px;">
        <div>
          <span class="name">{{ item.coin }}/{{ item.medium }}</span>
          <span :style="{color: item.change>=0?riseColor: fallColor}">
              {{ item.price }}
            </span>
          <span :style="{color: item.change>=0?riseColor: fallColor}">
              {{ item.change>=0?'+': '' }}{{ (item.change).toFixed(1) }}%
            </span>
        </div>
      </div>
      </div>
    </div>
    <div class="our-feature">
      <div class="v-container">
        <h2>{{ $t(1103) }}</h2>
        <el-row >
          <el-col :xl="8" :lg="8" :md="12">
            <div class="single">
              <div class="part-icon">
                <i class="fa fa-lock"></i>
              </div>
              <div class="part-text">
                <h3>{{ $t(1104) }}</h3>
                <p :title="$t(80)">{{  $t(80) }}</p>
              </div>
            </div>
          </el-col>
          <el-col :xl="8" :lg="8" :md="12">
            <div class="single">
              <div class="part-icon">
               <i class="fa fa-headset"></i>
              </div>
              <div class="part-text">
                <h3>{{ $t(1106) }}</h3>
                <p :title="$t(87)">{{  $t(87) }}</p>
              </div>
            </div>
          </el-col>
          <el-col :xl="8" :lg="8" :md="12">
            <div class="single">
              <div class="part-icon">
               <i class="fa fa-globe-americas"></i>
              </div>
              <div class="part-text">
                <h3>{{ $t(1107) }}</h3>
                <p :title="$t(1109)">{{  $t(1109) }}</p>
              </div>
            </div>
          </el-col>
          <el-col :xl="8" :lg="8" :md="12">
            <div class="single">
              <div class="part-icon">
               <i class="fa fa-copy"></i>
              </div>
              <div class="part-text">
                <h3>{{ $t(1105) }}</h3>
                <p :title="$t('homeTitle_1_2')">{{  $t('homeTitle_1_2') }}</p>
              </div>
            </div>
          </el-col>
          <el-col :xl="8" :lg="8" :md="12">
            <div class="single">
              <div class="part-icon">
               <i class="fa fa-bitcoin-sign"></i>
              </div>
              <div class="part-text">
                <h3>{{ showPreciousMetals ? $t('1111_2') : $t('1111') }}</h3>
                <p :title="$t(1112)">{{  $t(1112) }}</p>
              </div>
            </div>
          </el-col>
          <el-col :xl="8" :lg="8" :md="12">
            <div class="single">
              <div class="part-icon">
               <i class="fa fa-chart-area"></i>
              </div>
              <div class="part-text">
                <h3>{{ $t(1110) }}</h3>
                <p :title="$t(1113)">{{  $t(1113) }}</p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="howworks text-center">
      <div class="v-container">
        <h2>{{ $t(1108) }}</h2>
        <el-row :gutter="20">
          <el-col :xl="8" :lg="8" :md="12" v-for="(item, i) in banners" :key="i">
            <div class="work-item" @click="bannerClick(item)">
               <div>
                 <img :src="item.bannerUri" />
               </div>
              <h2 v-if="item.title">{{ item.title }}</h2>
              <span v-if="item.summary">{{ item.summary }}</span>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="data24">
      <div class="v-container">
        <el-row :gutter="20">
          <el-col :xl="8" :lg="8" >
            <div class="data-item">
              <div class="part-icon">
                <i class="fa fa-dollar"></i>
              </div>
              <div class="part-text">
                <h3>+{{ $t(1114) }}</h3>
                <p>{{$t(1115) }}</p>
              </div>
            </div>
          </el-col>
          <el-col :xl="8" :lg="8" >
            <div class="data-item">
              <div class="part-icon">
                <i class="fa fa-users"></i>
              </div>
              <div class="part-text">
                <h3>+{{ $t(1116) }}</h3>
                <p>{{$t(1117) }}</p>
              </div>
            </div>
          </el-col>
          <el-col :xl="8" :lg="8" >
            <div class="data-item">
              <div class="part-icon">
                <i class="fa fa-fire"></i>
              </div>
              <div class="part-text">
                <h3>&#60;0.10%</h3>
                <p>{{$t(1118) }}</p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="download-area">
      <div class="v-container">
        <h2>{{ $t(1119) }}</h2>
        <div class="flex">
          <div class="flex1">
            <p>{{ $t(85) }}</p>
            <vue-qr :size="180" :text="downloadUrl"></vue-qr>
            <div class="">
              <a href="#" class="d-btn" @click="goToDownload">IOS Download</a>
              <a href="#" class="d-btn" @click="goToDownload">Android Download</a>
              <a href="#" class="d-btn" @click="goToH5">H5 Login</a>
            </div>
          </div>
          <div class="flex1 imgarea">
            <template v-if="isAppSkinModelV2">
              <img src="@/assets/img/bottom-d-right-new.png" alt="" style="width: 90%;">
            </template>
            <template v-else>
              <img src="@/assets/img/bottom-d-right.png" alt="" style="width: 90%;">
            </template>
          </div>
        </div>
      </div>
    </div>
  <BannerModal v-if="showBannerContent" v-model="showBannerContent" :content="curContent"></BannerModal>
  </div>
</template>
<script>
import BannerModal from "@/components/BannerModal";
import { getCarousels } from "@/api";
import utils from "@/utils/misc"
import tradingMixin from '@/mixins/tradingMixin'
import vueQr from 'vue-qr'
export default {
  name: "Home",
  props: ["lang", "isAppSkinModelV2"],
  mixins: [tradingMixin],
  data() {
    return {
      banners: [],
      showBannerContent: false,
      curContent: {}
    };
  },
  components: {
    BannerModal,
    vueQr
  },
  beforeMount() {
    this.getBanners();
  },
  computed: {
    mainCoins() {
      const hotCoins = ['BTC','ETH','FIL','EOS','DOGE']
      const hotPMCoins = ['XAU','XAG']
      if (this.showPreciousMetals) {
        return [...this.spotList.filter(item => hotCoins.includes(item.coin)), ...this.futureList.filter(item => hotPMCoins.includes(item.coin))]
      }
      return this.spotList.filter(item =>hotCoins.includes(item.coin) )
    },
    isTimeX() {
      return utils.getLs('agentIdentify') === 'e4ee504c-670f-4c82-a610-5b8aa2351ad7'
    },
    modle() {
      return window.configObj.officialModel
    },
    downloadUrl() {
      return window.configObj.downloadUrl
    },
    showPreciousMetals() {
      return [
        'c1241e1d-ad79-487b-b6f4-fb9d653ddf22', // test
        '3b042e72-f85f-4376-a138-ba1950da8fee', // prepro
        '7b89bc95-1758-46ef-8b9b-869892ee8f67', // res
        'bcdb9b83-a19f-44c3-b6fa-59b31428ca3f'
      ].includes(utils.getLs('agentIdentify'))
    },
  },
  methods: {
    bannerClick(item) {
      if (!item.extra) return
      this.curContent = item
      this.showBannerContent = true
    },
    goToDownload() {
      window.location.href = window.configObj.downloadUrl;
    },
    goToH5() {
      window.location.href = location.origin + "/h5/";
    },
    getBanners() {
      getCarousels().then((res) => {
        this.banners = res.list || [];
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.data24{
  background: url("~@/assets/img/coinin.jpeg") no-repeat center;
  background-size: cover;
  overflow: hidden;
  position: relative;
  z-index: 2;
  color: #fff;
  &:after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #000050;
    opacity: .85;
  }
  .part-icon{
    font-size: 50px;
    font-weight: 700;
    margin-bottom: 30px;
    width: 150px;
    height: 150px;
    border: 4px solid #0088ff;
    color: #fff;
    line-height: 150px;
    border-radius: 50%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 24px;
  }
  .data-item{
    padding: 40px 0;
    text-align: center;
    h3{
      font-size: 36px;
      margin-bottom: 20px;
    }
    p{
      font-size: 24px;
    }

  }
  padding: 80px 0;
}
.download-area{
  p{
    font-size: 16px;
    margin-bottom: 40px;
  }
  .d-btn{
    display: block;
    margin: 20px auto !important;
  }

}
.our-feature, .download-area{
  background: #fff;
  padding: 60px 0;
  text-align: center;
  h2{
    margin-bottom: 40px;
    color: #000050;
    font-size: 36px;
    font-weight: 700;
  }
  .part-icon{
    font-size: 45px;
    margin-bottom: 20px;
    color: #0088ff;
    .fa{
      font-weight: 900;
    }
  }
  .single {
    text-align: center;
    padding: 40px 30px;
    margin-bottom: 30px;
    transition: all 0.3s ease-in;
    box-shadow: 0px 0px 9px 2px #f5f5f5;
    color: #666;
    font-size: 16px;
    min-height: 300px;
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    h3{
      color: #000050;
      margin-bottom: 16px;
      font-size: 24px;
      font-weight: 600;
    }
    p{
      line-height: 24px;
    }
    &:hover{
      box-shadow: 0px 0px 19px 12px #f5f5f5;
    }
  }
}
.howworks{
  background: #fff;
  padding: 40px 0;
  padding-bottom: 120px;
  h2{
    margin-bottom: 40px;
    color: #000050;
    font-size: 36px;
    font-weight: 700;
  }
  .work-item{
    img{
      width: 100%;
      margin-bottom: 20px;
      cursor: pointer;
      transition: all .4s;
      border-radius: 4px;
      &:hover{
        transform:scale(1.05);
      }
    }
    span{
      font-size: 16px;
      color: #666;
      line-height: 24px
    }
  }
}
.main-coins{
  padding: 60px 0;
  display: flex;
  align-items:center;
  flex-wrap: wrap;
  justify-content: space-around;
}
.timex .tp-banner-container{
  height: auto;
  padding-top: 100px;
  padding-bottom: 40px;
  background: #fff;
}
.tp-banner-container {
  max-height: none;
  .tp-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: none;
    background: url("~@/assets/img/vc3ww.jpeg") no-repeat center;
    background-size: cover;
    overflow: hidden;
    position: relative;
    z-index: 2;
    &:after {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background: #000050;
      opacity: .85;
    }
    &.timex{
      background: none;
        display: flex;
      .tp-caption{
          flex:3;
          padding:0 10%;
          color: #fff;
          .logoBox .text-box h3{
            color: #fff;
          }
      }
        .timexBanner{
          flex:3;
          background-size: cover;
        }
    }
    .tp-caption {
      text-align: center;
      color: #fff;
      width: 100%;
      max-width: 90vw;
      padding: 180px 0;
      .logoBox {
        text-align: center;
        h1 {
          font-size: 40px;
        }
        .text-box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 110px;
          margin-bottom: 50px;
          h3 {
            font-size: 20px;
            font-weight: 300;
            color: #fff;
          }
        }
        .btn {
          // width: 150px;
          cursor: pointer;
          margin-right: 30px;
          img {
            margin-right: 30px;
          }
        }
      }
    }
  }
}
.tp-banner-container-2 {
  max-height: none;
  height: 110vh;
  .tp-banner-2 {
    background: url("~@/assets/img/banner.png") no-repeat;
    background-size: 100% 116%;
    .logoBox-2 {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .left {
        text-align: left;
        width: 50%;
        h1 {
          font-size: 85px;
        }
        .text-box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 110px;
          margin-bottom: 50px;

          h3 {
            font-size: 45px;
            font-weight: 100;
            color: #fff;
          }
          &::before {
            position: absolute;
            content: "";
            transform: translate(-50px, -50px);
            width: 200px;
            height: 2px;
            border-top: 5px solid #fff;
          }
        }
        margin-right: 130px;
      }
      .btn {
        width: 20%;
        cursor: pointer;
        margin-right: 30px;
        img {
          margin: 0 30px 30px 0;
        }
      }
    }
  }
}
.modle2 {
  width: 100%;
  display: flex;
  padding: 60px 0;
  justify-content: center;
  color: #fff;
  background: #1d1d37;
  .logoBox {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .l {
      width: 600px;
    }
    .r {
      width: 300px;
    }
  }
}
.tp-banner-3 {
  background: url("~@/assets/img/banner.png") no-repeat;
  background-size: cover;
}
.d-btn{
  display:inline-block;
  height: 60px;
  width: 220px;
  display: inline-block;
  line-height: 60px;
  background: #fff;
  color: #0088ff;
  border: 1px solid #0088ff;
  transition: all 0.3s ease-in;
  font-weight: 700;
  font-family: "Noto Sans", sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  margin-right: 25px;
  &:hover{
    background: #0088ff;
    color: #fff;
  }
  &:last-child{
    background: #0088ff;
    margin-right: 0;
    color: #fff;
    &:hover{
      background: #fff;
      color: #0088ff;
    }
  }
}
@media screen and (max-width: 1200px) {
  .imgarea{
    display: none;
  }
   .main-coins{
    padding-top:30px;
    padding-bottom: 20px;
    .coin-item{
      width: 80%;
      padding: 10px;
      background: #fff;
      margin-bottom: 10px;
      border-radius: 4px;
    }
  }
  .data24 .data-item h3{
    font-size: 30px;
  }
  .our-feature h2, .howworks h2, .download-area h2{
    font-size: 24px;
  }
  .work-item{
    margin-bottom: 30px;
  }
  .our-feature .single h3{
    font-size: 18px;
  }
  .timex .tp-banner-container{
    height: auto;
    padding-top: 55px;
    padding-bottom: 40px;
    background: #fff;
  }

  .tp-banner-container {
    // padding-top: 100px;
    .tp-banner {
      height: 80vh;
      &.timex{
          background: none;
          display: flex;
          flex-direction: column-reverse;
        .tp-caption{
            min-width: 100vw;
            padding:10px;
            color: #333;
            .logoBox .text-box h3{
              color: #333;
            }
            .logoBox .text-box{
              padding: 20px;
              margin:20px 0;
            }
            .logoBox h1 {
              width: 100%;
              font-size: 30px;
              text-align: center;
            }
        }
          .timexBanner{
            flex:3;
            background-size: cover;
          }

      }
      .tp-caption {
        padding: 0 10px;
        .logoBox {
          h1 {
            display: none;
            font-size: 24px;
            width: 260px;
            //  margin: 0 auto;
          }
          .text-box {
            width: 100%;
            height: 100px;
            flex-direction: column;
            margin-top: 46px;
            h3 {
              font-size: 18px;
            }
          }
          .text {
            bottom: 15%;
            p {
              width: 100vw;
              padding: 0 15px;
            }
          }
          .btn {
            justify-content: center;
            flex-wrap: wrap;
            margin-right: 0;
            a{
              margin-right: 0;
              margin-bottom: 8px;
              font-size: 14px;
              height: 60px;
              width: 200px;
              line-height: 60px;
            }
          }
        }
      }
      &::after {
        height: calc(100vh - 100px);
      }
    }
  }
  .tp-banner-container-2 {
      background: #1d1d37;
      height: 140vh;
            .tp-banner-2{
            background-size: 319%;
            background-position: -84vw -9vh;
            .logoBox-2 {
                flex-wrap: wrap;
                .left {
                    width: 100%;
                    h1 {
                        font-size: 17vw;
                    }
                    .text-box {
                    margin: 50px 0 0 ;
                    padding: 50px 0 0;
                    &::before{
                        transform: translate(0, -90px);
                    }
                    }
                }
               .btn {
                  background: #1d1d37;
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;
                  justify-content: center;
                   margin: 50px 0 0 ;
                   width: 100%;
                   padding: 30px;
                   img {
                       margin: 10px;
                   }
               }
            }
        }
  }
  .modle2 {
    padding: 0 0 60px;
    .logoBox{
        width: 100%;
        flex-wrap: wrap;
        .l, .r {
            width: 100%;
            padding: 30px;
        }
    }
  }
}
</style>
