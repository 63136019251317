
<template>
  <modal :visible="value" @onClosed="doClose" size="xsmall">
    <span slot="head">{{ $t(1080) }}</span>
    <div slot="body">
      <el-form ref="form" :rules="rules" :model="form">
        <div class="item">
          <div class="label mb5">{{ $t(102) }}</div>
          <el-form-item prop="phone">
            <el-input v-model="form.phone"  disabled> </el-input>
          </el-form-item>
        </div>
        <!-- 驗證碼 -->
        <div class="item">
          <div class="label mb5">{{ $t(122) }}</div>
          <el-form-item prop="verifyCode">
            <el-input v-model="form.verifyCode">
              <el-button
                slot="append"
                :disabled="text !== sendText"
                type="text"
                @click="sendVerifyCode"
              >
                <span :class="{ countDown: sendText !== text }">
                  {{sendText}}
                </span>
              </el-button>
            </el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <span slot="foot">
      <el-button @click="doClose">{{ $t(1072) }}</el-button>
      <el-button type="primary" @click="sentMobileCode">{{
        $t(1038)
      }}</el-button>
    </span>
  </modal>
</template>
<script>
import Modal from "@/components/Modal";
import utils from "@/utils/misc";
import { financialMobileCode, financialRecords } from "@/api";
export default {
  name: "FinancialMobileCodeDialog",
  components: { Modal },
  props: {
    value: Boolean,
    orderId: Number,
  },

  data() {
    return {
      sendText: "",
      text: this.$t("123"),
      form: {
        phone: "",
        verifyCode: "",
      },
    };
  },
  computed: {
    rules() {
      return {
        verifyCode: [
          {
            validator: (rule, value, callback) => {
              if (value === "") {
                return callback(new Error(this.$t(1079)));
              }
              callback();
            },
          },
        ],
      };
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  mounted() {
    this.sendText = utils.getLs("sendTextOfFinancial") || this.text;
    this.setTimer()
    this.form.phone = `+${this.userInfo.areaCode} ${this.secretString(this.userInfo.mobile)}`
  },
  methods: {
    secretString(val){
      return '****' + val.substring(4);
    },  
    sendVerifyCode() {
      financialMobileCode().then(() => {
        this.sendText = 60;
        this.setTimer();
        this.$message.success(this.$t(1078));
      });
    },
    sentMobileCode() {
      this.$refs.form.validate(valid => {
        if (valid) {
            financialRecords(this.orderId, this.form.verifyCode).then(() => {
                this.$message.success(this.$t(1062));
                this.$emit('reload')
                this.doClose()
            });
        } 
      })
    },
    setTimer() {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        if (isNaN(this.sendText)) return;
        if (this.sendText <= 1) {
          this.sendText = this.text;
          clearInterval(this.timer);
          utils.removeLs("sendTextOfFinancial");
        } else {
          this.sendText--;
          utils.setLs("sendTextOfFinancial", this.sendText);
        }
      }, 1000);
    },
    doClose() {
      this.$emit("input", false);
    },
  },
};
</script>

<style lang="scss">
 .countDown{
    color: #C0C4CC;
  }
  </style>