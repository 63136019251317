<template>
    <div class="main-coins">
        <div class="coin-item flex align-center" v-for="item in mainCoins" :key="item.coin">
          <img :src="coinMsg[item.coin]?coinMsg[item.coin].logoUri:''" style="width: 30px;margin-right: 10px;">
          <div class="coin-item-text">
            <p>
              <span class="name">{{ item.coin }}/{{ item.medium }}</span>
              <span :style="{color: item.change>=0?riseColor: fallColor, 'margin-top': '10px'}">
                <span class="tip">AVAX</span> 
                {{ item.change>=0?'+': '' }}{{ (item.change).toFixed(1) }}% 
              </span>
            </p>
            <p>
              <span class="tip">Total Supply</span>
              <span :style="{color: item.change>=0?riseColor: fallColor, 'margin-top': '10px'}">{{ item.price }}</span>
            </p>
          </div>
        </div>
    </div>
</template>
<script>
import utils from '@/utils/misc'
export default {
    data() {
        return {
            riseColor: utils.getLs('colorMode') === 'colorMode2' ? '#fa4d56' : '#12b886',
            fallColor: utils.getLs('colorMode') === 'colorMode2' ? '#12b886' : '#fa4d56'
        }
    },
    computed: {
        mainCoins() {
        const hotCoins = ['BTC','ETH','DOGE','EOS','FIL', 'DASH']
        const arr = []
        for (const key in this.$store.state.spotList) {
            arr.push(this.$store.state.spotList[key])
        }
        return arr.filter(item =>hotCoins.includes(item.coin) )
        },
        coinMsg() {
        return this.$store.state.coins
        },
  },
}    
</script>


<style lang="scss" scoped>
@media screen and (max-width: 1000px) {
.main-coins{
    padding-top:30px;
    padding-bottom: 20px;
    .coin-item{
        width: 90%!important;
        margin-bottom: 10px;
        }
    }
 }

.main-coins{
  padding: 30px 0;
  display: flex;
  align-items:center;
  flex-wrap: wrap;
  justify-content: space-around;
  box-sizing: border-box;
  .coin-item {
    width: 30%;
    margin: 10px;
    border: 1px solid #0A3A54;
    display: flex;
    border-radius: 5px;
    // flex-direction: column;
    // justify-content: center;
    padding: 10px;
    box-sizing: border-box;
    .coin-item-text {
      display: flex;
      // flex-direction: column;
      justify-content: space-between;
      // border: 1px solid blue;
      flex: 1;
      p {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        .tip {
          color: #91929E;
          font-size: 8px;
        }
      }
    }
  }
}
</style>