<template>
    <div class="main-coins">
        <div class="coin-item flex align-center" v-for="(item, index) in mainCoins" :key="item.coin">
          <div class="coin-item-text">
            <p style="display: flex; flex-direction: row;">
              <span style="margin-right: 20px;">{{index === 0 ? '#' : index}}</span>
              <img :src="coinMsg[item.coin]?coinMsg[item.coin].logoUri:''" style="width: 30px;margin-right: 20px;">
              <span class="name">{{ item.coin }}{{index > 0 ? '/' : ''}}{{ item.medium }}</span>
            </p>
            <p style="display: flex; flex-direction: row;">
              <span class="price" :style="{color: '#fff', 'text-align': 'left', 'display': 'inline-block'}">{{item.medium}} {{ item.price }}</span>
              <span class="change"  v-if="item.type === 1 && (item.closeStatus === 0 || item.closeStatus === 2)">--</span>
              <span class="change" :style="{color: index > 0 ? (item.change>=0?riseColor: fallColor) : '#fff'}" v-else>
                {{ item.change>=0?'+': '' }}{{ typeof item.change === 'string' ? item.change : (item.change).toFixed(1) }}{{index > 0 ? '%' : ''}}
              </span>
              <template v-if="index > 0">
                <img class='media' :src="require(item.change >= 0 ? './up.png' : './down.png')" alt="" width="98" height="28">
                <button class='media'>{{item.type === 1 && (item.closeStatus === 0 || item.closeStatus === 2) ? $t('1129') : $t('1134')}}</button>
              </template>
              <template v-else>
                <span  class='media' style="display: inline-block; width: 117px;">{{$t('1133')}}</span>
                <span  class='media' style="display: inline-block; width: 98px;">{{$t('1134')}}</span>
              </template>
            </p>
          </div>
        </div>
    </div>
</template>
<script>
import utils from '@/utils/misc'
export default {
    data() {
        return {
            riseColor: utils.getLs('colorMode') === 'colorMode2' ? '#fa4d56' : '#12b886',
            fallColor: utils.getLs('colorMode') === 'colorMode2' ? '#12b886' : '#fa4d56'
        }
    },
    computed: {
        mainCoins() {
          const hotCoins = ['c1241e1d-ad79-487b-b6f4-fb9d653ddf22', '3b042e72-f85f-4376-a138-ba1950da8fee', '7159e738-16d4-4781-b2e2-dc3c5064069b'].includes(utils.getLs('agentIdentify')) ? ['BTC','ETH','DOGE', 'DASH', 'XAG', 'XAU'] : ['BTC','ETH','DOGE','EOS','FIL', 'DASH']
          const arr = []

          if (['c1241e1d-ad79-487b-b6f4-fb9d653ddf22', '3b042e72-f85f-4376-a138-ba1950da8fee', '7159e738-16d4-4781-b2e2-dc3c5064069b'].includes(utils.getLs('agentIdentify'))) {
            for (const key in this.$store.state.futureList) {
              arr.push({
                medium: 'USD',
                price: this.$store.state.futureList[key].price,
                change: this.$store.state.futureList[key].change,
                closeStatus: this.$store.state.futureList[key].closeStatus,
                coin: key.replace('USD', ''),
                type: 1,
              })
            }
          }

          for (const key in this.$store.state.spotList) {
              arr.push(this.$store.state.spotList[key])
          }

          const titles = {coin: this.$t('1130'), price: this.$t('1131'), change: this.$t('1132'), chart: this.$t('1133'), trade: this.$t('1134')}
          return [titles, ...arr.filter(item =>hotCoins.includes(item.coin) )]
        },
        coinMsg() {
          return this.$store.state.coins
        },
  },
}
</script>


<style lang="scss" scoped>
@media screen and (max-width: 1000px) {
.main-coins{
    padding-top:30px;
    padding-bottom: 20px;
    font-size: 14px;
    .coin-item{
        width: 90%!important;
        margin-bottom: 10px;
        .coin-item-text {
          p {
            font-size: 12px!important;
            .price {
              width: auto!important;
            }
            .change {
              margin: 0 5px!important;
            }
          }
        }
        .media {
          display: none!important;
        }
        }
    }
 }

.main-coins{
  padding: 30px 0;
  display: flex;
  align-items:center;
  flex-wrap: wrap;
  justify-content: space-around;
  box-sizing: border-box;
  .coin-item {
    width: 90%;
    // flex: 1;
    // margin: 10px;
    border: 1px solid #B7B7C1;
    display: flex;
    // border-radius: 5px;
    // flex-direction: column;
    // justify-content: center;
    padding: 10px;
    box-sizing: border-box;
    .coin-item-text {
      display: flex;
      // flex-direction: column;
      justify-content: space-between;
      // border: 1px solid blue;
      flex: 1;
      p {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        font-size: 20px;
        .tip {
          color: #91929E;
          font-size: 8px;
        }
        .price {
          width: 160px;
        }
        .change {
          margin: 0 20px;
          min-width: 45px;
        }
        button {
          width:94px;
          height: 40px;
          background: #1C2843;
          border-radius: 12px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          color: #fff;
          border:1px solid blue;
          margin-left: 20px;
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
