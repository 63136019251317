<template>
    <div class="data24">
      <div class="v-container">
        <h2 class="title">{{$t('1135')}}</h2>
        <p class="sub-title">{{$t('1136', {appName})}}</p>
        <el-row :gutter="20">
          <el-col :xl="12" :lg="12" class="col col-1">
            <ul class="ul">
              <li>
                <p>{{$t('1137')}}</p>
                <p>{{$t('1138')}}</p>
              </li>
              <li>
                <p>{{$t('1139')}}</p>
                <p>{{$t('1140')}}</p>
              </li>
              <li>
                <p>{{$t('1141')}}</p>
                <p>{{$t('1142')}}</p>
              </li>
              <li>
                <p>{{$t('1143')}}</p>
                <p>{{$t('1144',{appName})}}</p>
              </li>
            </ul>
          </el-col>
          <el-col :xl="12" :lg="12" class="col col-2" >
            <img src="./comp.png" alt="" width="497" height="347">
          </el-col>
        </el-row>
      </div>
    </div>
</template>

<script>
export default {
  computed: {
    appName() {
      return window.configObj.appName
    }
  }
}

</script>

<style lang="scss" scoped>
.ul {
  height: 100%;
  display: flex;
  flex-direction: column;
  // width: 50%;
  li {
    margin-bottom: 20px;
    font-size: 18px;
    color: #B6B3CD;
    p {
      &:first-child {
        font-size: 24px;
        color: #fff;
        margin-bottom: 10px;
      }
    }
  }
}  
@media screen and (max-width: 1200px) {
  .col-2 {
    display: none!important
  }
    .data24 .data-item {
        // border: 1px solid blue;
        background-size: contain!important;
        margin-top: 20px;
    }
}    

.title {
    text-align: center;
    font-size: 36px;
} 
.sub-title {
    font-size: 24px;
    color: #91929E;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 60px;
}   
.data24{
//   background: url("~@/assets/img/coinin.jpeg") no-repeat center;
//   background-size: cover;
  overflow: hidden;
  position: relative;
  // background: #0E0E22;
  z-index: 2;
  color: #fff;
  &:after {
    // position: absolute;
    // content: '';
    // left: 0;
    // top: 0;
    // width: 100%;
    // height: 100%;
    // z-index: -1;
    // background: #000050;
    // opacity: .85;
  }
  .part-icon{
    visibility: hidden;
    // font-size: 50px;
    // font-weight: 700;
    // margin-bottom: 30px;
    width: 150px;
    height: 150px;
    // border: 4px solid #0088ff;
    // color: #fff;
    // line-height: 150px;
    // border-radius: 50%;
    // position: relative;
    // left: 50%;
    // transform: translateX(-50%);
    // margin-bottom: 24px;
  }
  .data-item{
    padding: 40px 0;
    text-align: center;
    background: url('~@/assets/img/home2/volume.png') no-repeat center center;
    background-size: 100%;
    box-shadow: 10px 10px 5px 5px #0E0E22;
    &.data-item-2 {
        background: url('~@/assets/img/home2/users.png') no-repeat center center;
        background-size: 100%;
    }
    &.data-item-3 {
        background: url('~@/assets/img/home2/fee.png') no-repeat center center;
        background-size: 100%;
    }
    h3{
      font-size: 36px;
      margin-bottom: 20px;
    }
    p{
      font-size: 24px;
      color: #91929E;
    }

  }
  padding: 80px 0;
}
</style>