<template>
  <div class="wraper trans">
    <div
      class="Box row active animated "
      v-animated="{ className: 'fadeInRight' }"
    >
      <div class="col-12">
        <div class="wraper-box" :class="{'wraper-box-2': modle === '2'}">
          <div class="title-box">
            <div class="title-box-text">
              <h1 class="title">{{ $t(57) }}</h1>
              <h1>{{ $t(58) }}</h1>
            </div>
          </div>

          <!-- 內文 -->
          <div class="content-box">
            <div v-if=" modle === '1'" class="content-box-text">
              <div
                class="post"
                v-for="(item, i) in selectedLangMetas.items"
                :key="i"
              >
                <h1 class="title">{{ item.title }}</h1>
                <p>{{ item.des1 }}</p>
                <p v-if="item.des2">{{ item.des2 }}</p>
                <div class="des-area">
                  <div class="des">
                    <img :src="item.des_img" alt="trans-img" />
                  </div>
                </div>
              </div>
            </div>
            <div v-if=" modle === '2'" class="content-box-text">
              <div
                class="post-2"
                :class="{'post-2-reverse': i % 2 > 0 }"
                v-for="(item, i) in selectedLangMetas.items"
                :key="i"
              >
                <div class="l">
                    <img :src="item.modle2title" />
                    <div class="text-box">
                        <h1 class="title">{{ item.modle2_t }}</h1>
                        <p>{{ item.des1 }}</p>
                        <p v-if="item.des2">{{ item.des2 }}</p>
                    </div>
                </div>    
                <div class="r">
                  <div class="r-img">
                    <img :src="item.modle2titleImg" alt="trans-img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Trans",
  props: ["lang"],
  data() {
    return {
      type: "product_1",
    };
  },
  computed: {
    modle() {
      return window.configObj.officialModel
    },    
    selectedLangMetas() {
      const keyMap = {
      'zh-CN': 'cn',
      'en-US': 'en'
    }
      // return this[`trans_${keyMap[this.lang]}`];
      return {
        items: [
            {
                title: this.$t(50),
                des_t: '',
                des1: this.$t(54),
                des_img: require('../assets/img/币币交易-banner2.png'),
                modle2title: require("../assets/img/标题1.png"),
                modle2_t: this.$t(96),
                modle2titleImg: require("../assets/img/币币交易_图1.png"),
            },
            {
                title: this.$t(51),
                des_t: '',
                des1: this.$t(52),
                des_img: require('../assets/img/币币交易-banner3.png'),
                modle2title: require("../assets/img/标题2.png"),
                modle2_t: this.$t(97),
                modle2titleImg: require("../assets/img/币币交易_图2.png"),
            },
            {
                title: this.$t(53),
                des_t: '',
                des1: this.$t(55),
                des_img: require('../assets/img/币币交易-banner4.png'),
                modle2title: require("../assets/img/标题3.png"),
                modle2_t: this.$t(98),
                modle2titleImg: require("../assets/img/币币交易_图3.png"),
            },
        ]
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wraper {
   margin: 0 0 100px;
  .row {
    justify-content: space-around;
  }
  .wraper-box {
    display: flex;
    align-items: center;
    text-align: left;
    // justify-content: center;
    flex-wrap: wrap;
    padding: 0;
    .title-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 72vh;
      background-image: url(../assets/img/币币交易-banner.png);
      background-repeat: no-repeat;
      background-size: cover;
      .title-box-text {
        width: 85%;
        max-width: 1200px;
        .title {
          width: 100%;
          font-size: 95px;
          font-weight: 500;
          margin-bottom: 40px;
        }
        p {
          font-size: 15px;
          margin: 15px 0;
        }
      }
    }
    .content-box {
      width: 100%;
      background: #fff;
      color: #222;
      display: flex;
      justify-content: center;
      padding-top: 119px;
      .content-box-text {
        width: 85%;
        max-width: 1200px;
        padding: 0 15px;
        .post {
          text-align: center;
          display: inline-flex;
          flex-wrap: wrap;
          font-size: 18px;
          .title {
            width: 100%;
            text-align: center;
            font-size: 40px;
            margin-bottom: 30px;
          }
          p {
              width: 100%;
              text-align: center;
          }
          h2 {
            color: rgb(201, 201, 201);
          }
          .des-area {
            margin-top: 30px;
            width: 100%;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            .des {
              display: inline-flex;
              justify-content: center;
              align-items: center;
              flex-wrap: nowrap;
                img {
                  width: 70%;
                }
            }
          }
          * {
            margin: 20px 0;
          }
        }
      }
    }
    &.wraper-box-2 {
        .title-box {
            height: 72vh;
            background-image: url(../assets/img/banner_2.png);
        }
        .content-box {
            background: #020017;
            color: #fff;
        }
        .post-2 {
          width: 100%;  
          text-align: center;
          display: inline-flex;
          font-size: 18px;
          padding: 100px 50px;
          .l {
              width: 50%;
              padding: 0 50px;
              display: flex;
              justify-content: center;
              flex-direction: column;
              img {
                  width: 46%;
              }
              .text-box {
                  margin-left: 120px;
                  text-align: left;  
              }
          }
          .r {
              width: 50%;
              img {
                  width: 80%;
              }
          }
          &.post-2-reverse {
              flex-direction: row-reverse;
          }
        }   
    }
  }
}

@media (max-width: 1200px) {
  .trans {
      margin: 0 0 20px;
    .wraper-box {
      .title-box {
        background-position-x: 80%;
        height: 50vh;
        .title-box-text {
           text-align: left; 
           padding: 0 8px;
          .title {
            font-size: 30px;
            margin: 15px 0;
            color: #fff;
          }
          h1 {
            font-size: 18px;
            font-weight: 300;
            color: rgb(215, 215, 215);
          }
        }
      }
      .content-box {
        padding-top: 26px;
        .content-box-text {
          padding: 0;
          .post {
              font-size: 17px;
              text-align: left;
              .title{
                font-size: 25px;
                margin-bottom: 10px;
              }
            //   p {
            //   text-align: left;
            //   }
              .des-area { 
                margin-top: 10px;
                  .des {
                      flex: 0 0 100%;
                      margin: 10px 0;
                  }
            }
          }
        }
      }
      &.wraper-box-2 .post-2 {
          flex-wrap: wrap;
          padding: 60px 10px;
          .l {
              width: 100%;
              img {
                   width: 100%;
              }
              .text-box{
                  width: 100%;
                  margin-left: 0; 
              }
          }
          .r{
              margin: 10px;
              width: 100%;
          }
      }
    }
  }
}
</style>