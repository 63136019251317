import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/pages/Home'
import Quotes from '@/pages/Quotes'
import Trans from '@/pages/Trans'
import Deal from '@/pages/Deal'
import Financial from '@/pages/Financial'
import About from '@/pages/About'
import ServicePage from '@/pages/ServicePage'
import NotFound from '@/pages/NotFound'
import utils from '@/utils/misc'
Vue.use(VueRouter)
const router = new VueRouter({
    // mode: 'history',
    routes: [
        {
            path: '/', component: Home, name: 'Home'
        },
        {
            path: '/login', component: () => import('@/pages/login/Login.vue'), name: 'Login',
            meta: { hideHeader: true, hideFooter: true }
        },
        {
            path: '/register', component: () => import('@/pages/login/Register.vue'), name: 'Register',
            meta: { hideHeader: true, hideFooter: true }
        },
        {
            path: '/forgot', component: () => import('@/pages/login/ResetLoginPwd.vue'), name: 'ResetLoginPwd',
            meta: { hideHeader: true, hideFooter: true }
        },
        {
            path: '/me', component: () => import('@/pages/me/index.vue'), name: 'Me',
        },
        {
            path: '/update-login-pwd', component: () => import('@/pages/me/UpdatePassword.vue'), name: 'UpdateLoginPwd',
            meta: { hideHeader: true, hideFooter: true }

        },
        {
            path: '/update-funds-pwd', component: () => import('@/pages/me/UpdatePassword.vue'), name: 'UpdateFundsPwd',
            meta: { hideHeader: true, hideFooter: true }

        },
        {
            path: '/update-mobile', component: () => import('@/pages/me/UpdateMobileEmail.vue'), name: 'UpdateMobile',
            meta: { hideHeader: true, hideFooter: true }

        },
        {
            path: '/update-email', component: () => import('@/pages/me/UpdateMobileEmail.vue'), name: 'UpdateEmail',
            meta: { hideHeader: true, hideFooter: true }

        },
        {
            path: '/bank-setting', component: () => import('@/pages/me/BankSetting.vue'), name: 'BankSetting',
            meta: { hideHeader: true, hideFooter: true }

        },
        {
            path: '/auth', component: () => import('@/pages/me/AuthVerify.vue'), name: 'AuthVerify',
            meta: { hideHeader: true, hideFooter: true }

        },
        {
            path: '/auth-step', component: () => import('@/pages/me/AuthVerifyTwo.vue'), name: 'AuthStepTwo',
            meta: { hideHeader: true, hideFooter: true }

        },
        {
            path: '/assets', component: () => import('@/pages/assets/Assets.vue'), name: 'Assets',
            meta: { hideHeader: true }

        },
        {
            path: '/c2c-sale', component: () => import('@/pages/assets/C2cSale.vue'), name: 'C2cSale',
            meta: { hideHeader: true }
        },
        {
            path: '/deposit', component: () => import('@/pages/assets/Deposit.vue'), name: 'Deposit',
            meta: { hideHeader: true, hideFooter: true }

        },
        {
            path: '/withdraw', component: () => import('@/pages/assets/Withdraw.vue'), name: 'Withdraw',
            meta: { hideHeader: true, hideFooter: true }

        },
        {
            path: '/add-wallet', component: () => import('@/pages/assets/AddWallet.vue'), name: 'AddWallet',
            meta: { hideHeader: true, hideFooter: true }

        },
        {
            path: '/transfer', component: () => import('@/pages/assets/Transfer.vue'), name: 'Transfer',
            meta: { hideHeader: true, hideFooter: true }

        },
        {
            path: '/finance', component: () => import('@/pages/assets/FinanceRecord.vue'), name: 'FinanceRecord',
            meta: { hideHeader: true, hideFooter: true }

        },
        {
            path: '/futures', component: () => import('@/pages/trade/Contract.vue'), name: 'Contract',
            meta: { hideHeader: true, hideFooter: true }

        },
        {
            path: '/spot', component: () => import('@/pages/trade/Spot.vue'), name: 'Spot',
            meta: { hideHeader: true, hideFooter: true }

        },
        {
            path: '/quotes', component: Quotes, name: 'Quotes'
        },
        {
            path: '/trans', component: Trans, name: 'Trans'
        },
        {
            path: '/deal', component: Deal, name: 'Deal'
        },
        {
            path: '/financial', component: Financial, name: 'Financial'
        },
        {
            path: '/about', component: About, name: 'About'
        },
        {
            path: '/service', component: ServicePage, name: 'Service'
        },
        {
            path: '*',
            component: NotFound,
            name: '404',
        }
    ]
})
router.beforeEach((to, from ,next) => {
    const pcRoutes = ['Home', 'About']
    const sUserAgent = navigator.userAgent
    if ((sUserAgent.indexOf('Android') > -1 || sUserAgent.indexOf('iPhone') > -1 || sUserAgent.indexOf('iPod') > -1 || sUserAgent.indexOf('Symbian') > -1) && !pcRoutes.includes(to.name)) {
        if (to.name === 'Login') {
            window.location.href = '/h5/#/pages/normal/login/login'
            return
        }
        if (to.name === 'Register') {
            window.location.href = '/h5/#/pages/normal/register/register'
            return
        }
        window.location.href = location.origin + "/h5/";
    } else {
        if (to.name === 'Login' || to.name === 'Register') {
            if (utils.getLs('token')) {
                next('/')
            } else {
                next()
            }
        } else {
            next()
        }
    }
})
export default router
