<template>
  <div  class="wraper about">
    <div
      class="Box row active animated "
      v-animated="{ className: 'fadeInRight' }"
    >
      <div class="col-12">
        <div class="wraper-box" :class="{'wraper-box-2': modle === '2','timex': isTimeX}">
          <div class="title-box " :class="{'timex': isTimeX}">
            <div class="title-box-text">
              <h1 class="title" v-if="!isTimeX">{{ $t('9') }}</h1>
              <!-- <h3>{{ introduce }}</h3> -->
              <div v-html="introduce" style="marign:20px 0; line-height: 18px;"></div>
            </div>
          </div>
          <!-- 內文 -->
          <div class="content-box">
            <!-- <div v-html="introduce" style="marign:20px 0;"></div> -->
            <div class="content-box-item">
                <div class="tab-l">
                    <h3 v-for="(item,index) in tabNav" :key="index">
                        <h1>{{item.tabT}}</h1>
                        <h3 v-for="(littleNav , i) in item.navs" :key="i" 
                         @click="handleTab(littleNav, i)"
                        class="pointer tabNav" 
                        :class="{'active': selectTabName == littleNav.name}">{{littleNav.label}}</h3>
                    </h3>
                </div>

                <div class="tab-r">
                    
                    <div v-if="selectTabName==='legal'">
                      <div class="law" style="line-height:1.5;">
                        <h1>{{ $t('law.1') }}</h1>
                        <h3>{{ $t('law.2') }}</h3>
                        <p>{{ $t('law.3') }}</p>
                        <h3>{{ $t('law.4') }}</h3>
                        <p>{{ $t('law.5') }}</p>
                        <h3>{{ $t('law.6') }}</h3>
                        <p>{{ $t('law.7') }}</p>
                        <h3>{{ $t('law.8') }}</h3>
                        <p>{{ $t('law.9') }}</p>
                      </div>
                    </div>
                    <div v-else-if="selectTabName==='rule'">
                      <div class="rules" style="line-height:1.5;">
                        <h1>{{ $t('rules.1') }}</h1>
                        <h3>{{ $t('rules.2') }}</h3>
                        <p>{{ $t('rules.3') }}</p>
                        <p>{{ $t('rules.4') }}</p>
                        <p>{{ $t('rules.5') }}</p>
                        <p>{{ $t('rules.6') }}</p>
                        <h3>{{ $t('rules.7') }}</h3>
                        <p>{{ $t('rules.8') }}</p>
                        <p>{{ $t('rules.9') }}</p>
                        <p>{{ $t('rules.10') }}</p>
                        <p>{{ $t('rules.11') }}</p>
                        <p>{{ $t('rules.12') }}</p>
                      </div>
                    </div>
                    <div v-else-if="selectTabName==='help'">
                      <div class="help" style="line-height:1.5;">
                        <h1>{{ $t('help.1') }}</h1>
                        <h3>{{ $t('help.2') }}</h3>
                        <p>{{ $t('help.3') }}</p>
                        <h3>{{ $t('help.4') }}</h3>
                        <p>{{ $t('help.5') }}</p>
                        <h3>{{ $t('help.6') }}</h3>
                        <p>{{ $t('help.7') }}</p>
                        <h3>{{ $t('help.8') }}</h3>
                        <p>{{ $t('help.9') }}</p>
                        <h3>{{ $t('help.10') }}</h3>
                        <p>{{ $t('help.11') }}</p>
                        <h3>{{ $t('help.12') }}</h3>
                        <p>{{ $t('help.13') }}</p>
                        <h3>{{ $t('help.14') }}</h3>
                        <p>{{ $t('help.15') }}</p>
                        <h3>{{ $t('help.16') }}</h3>
                        <p>{{ $t('help.17') }}</p>
                      </div>
                    </div>
                    <div v-else-if="selectTabName==='fee'">
                      <div class="fee" style="line-height:1.5;">
                        <h1>{{ $t('fee.1') }}</h1>
                        <p class="mb10">{{ $t('fee.2') }}: {{ spotFee }}</p>
                        <p>{{ $t('fee.3') }}: {{ futureFee }}</p>
                      </div>
                    </div>
                    <div v-else-if="selectTabName==='issue'">
                      <div v-for="(item,index) in issueList" :key="index" style="margin-bottom:20px;">
                        <h3>{{item.title}}</h3>
                        <div v-html="item.content"></div>
                      </div>
                    </div>
                    <div v-else>
                      <!-- <h1 class="title"> {{aboutDatas.content[selectTabIndex].title}} </h1> -->
                      <div v-html="content"></div>
                    </div> 
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getIntro,getIssue } from '../api'
import utils from "@/utils/misc"
export default {
  name: "About",
  props: ["lang"],
  components: {
  },
  data() {
    return {
      selectTabName: 'protocol',
      selectTabIndex: 0,
      protocol:'',
      privacy:'',
      introduce:'',
      issueList:[]
    };
  },
  methods: {},
  computed: {
    isTimeX() {
      return utils.getLs('agentIdentify') === 'e4ee504c-670f-4c82-a610-5b8aa2351ad7'
    },
    modle() {
      return window.configObj.officialModel
    },  
    content() {
      return this[this.selectTabName]
    },
    spotFee() {
      return window.configObj.spotTradingFee + '%'
    },
    futureFee() {
      return window.configObj.futureTradingFee + '%'
    },
    tabNav() {
      return [
        {
          tabT: this.$t('14'),
          navs:[{
              label: this.$t('10'),
              name: 'protocol'
            },
            {
              label: this.$t('11'),
              name: 'privacy'
            },{
              label: this.$t('12'),
              name: 'legal'
            },{
              label: this.$t('13'),
              name: 'rule'
            }]

        },
        {
          tabT: this.$t('15'),
          navs:[{
              label: this.$t('16'),
              name: 'help'
            },
            {
              label: this.$t('17'),
              name: 'issue'
            },{
              label: this.$t('18'),
              name: 'fee'
            }]

        }
      ]
    },
    aboutDatas() {
      return this[`about_${this.lang}`];
    },
  },
  beforeCreate() {
    getIntro().then(res => {
      this.protocol = res.protocol
      this.privacy = res.privacy
      this.introduce = res.introduce
    })
  },
  methods: {
    handleTab(tabName , i) {
      this.selectTabName = tabName.name
      if (this.selectTabName === 'issue') {
        getIssue().then(res => {
          this.issueList = []
          const arr = res.list ||[]
          res.list.map(item=> {
            if (item.items instanceof Array) {
              this.issueList.push(...item.items)
            }
          })
        })
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rules,.law,.help,.fee{
  h1{
    margin-bottom: 20px;
  }
  h3{
    margin:20px 0 10px 0;
  }
}
.wraper {
  margin: 0 0 100px;
  .row {
    justify-content: space-around;
  }
  .wraper-box {
    display: flex;
    align-items: center;
    text-align: left;
    // justify-content: center;
    flex-wrap: wrap;
    padding: 0;
    .title-box {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      .title-box-text {
        width: 85%;
        max-width: 1200px;
        text-align: left;
        .title {
          font-size: 42px;
          font-weight: bold;
          margin-bottom: 40px;
        }
        h3 {
          font-size: 25px;
          font-weight: 300;
          margin: 15px 0;
        }
      }
    }
    .content-box {
      width: 100%;
      display: flex;
      justify-content: center;
      background: #fbfbfb;
      color: #222;
      .content-box-item {
        display: flex;  
        width: 85%;
        max-width: 1200px;
        padding: 120px 15px;
        .tab-l{
            width: 15%;
            text-align: center;
            h1 {
                font-size: 25px;
                font-weight: bold;
            }
            .tabNav{
                margin: 10px 0;
                color: #999;
                padding: 10px;
                &.active {
                    color: #222;
                    font-weight: bold;
                    background-color: #E9EAEF;
                }
            }
        }
        .tab-r{
            width: 80%;
            padding: 0px 20px 20px 40px;
            .title {
                font-size: 24px;
                color: #222;
                font-weight: 600;
            }
        }
      }
    }
  }
}
.about {
  .wraper-box {
    .title-box {
      height: 72vh;
      background-image: url(../assets/img/slide-img-2.jpeg);
      background-size: cover;
    overflow: hidden;
    position: relative;
    z-index: 2;
    &:after {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background: #000050;
      opacity: .85;
    }
      &.timex{
        min-height: 72vh;
        background-image: url(../assets/img/timex/about.png);
        color: #fff;
        background-size: contain;
        background-color: #fff;
        .title-box-text{
          padding-top:400px;
        }
      }
    }
  }
  .wraper-box-2 {
     .title-box {
      background-image: url(../assets/img/banner_4.png);
    }
    .content-box {
        background: #1d1d37;
        color: #fff;
    }
  }
}
@media (max-width: 1200px) {
  .wraper {
    padding:0;
    .wraper-box {
      .title-box {
        min-height: 70vh;
        &.timex{
          background-position-y:50px;
          .title-box-text{
            padding-top:200px;
          }
        }
        .title-box-text {
          padding: 0 8px;
          .title {
            font-size: 24px;
            margin-bottom: 15px;
            color: #fff;
          }
          h1 {
            text-align: left;
            font-size: 18px;
            font-weight: 300;
            color: rgb(215, 215, 215);
          }
        }
      }
      .content-box {
        .content-box-item {
          width: 100%;
        display: flex;  
        flex-wrap: wrap;
        width: 85%;
        max-width: 1200px;
        padding: 30px 0px;
        .tab-l{
            width: 100%;
            text-align: left;
            display: flex;
            flex-wrap: wrap;
            h1 {
                width: 100%;
                font-size: 20px;
                font-weight: bold;
            }
            .tabNav{
                font-size: 14px;
                display: inline-flex;
                margin: 5px 5px 20px;
                border: 1px solid #222;
                border-radius: 10px;
                color: #999;
                padding: 5px;
                &.active {
                    color: #222;
                    font-weight: bold;
                    background-color: #999;
                }
            }
        }
        .tab-r{
            width: 100%;
            padding: 10px ;
            margin-top: 30px;
            .title {
                font-size: 24px;
                color: #222;
                font-weight: 600;
            }
        }
        }
      }
    }
  }
}
</style>