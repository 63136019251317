import SocketAPI from './SocketAPI.js'
import utils from '@/utils/misc.js'
import store from '@/store'
import {
	AGENT_ID,
	USER_ID
} from '@/constants/storeTypes.js'
export default class Socket {
	offLine = false
	url = ''
	limitConnect = 5; // 断线重连次数
	timeConnect = 0;
	reconnectTimer = null
	ws = new SocketAPI()
	static s_instance;
	static get instance() {
		if (Socket.s_instance == null) {
			Socket.s_instance = new Socket();
		}
		return Socket.s_instance;
	}
	send(data) {
		this.ws.sendData(data)
	}
	close() {
		this.ws.close()
	}
	getStatus() {
		return this.ws.m_state
	}
	connectSocket(url) {
		this.url = url
		if (this.ws.m_state !== 3) return
		this.ws.connect(url)
		this.initSocketEvents()
	}
	initSocketEvents() {
		utils.off("ws_open")
		utils.on("ws_open", event => {
			this.offLine = false
			utils.removeLs("LOCK_RECONNECT");
			console.log("[socket]-开始初始化websocket事件", event);
			console.log("[socket]-URL: " + this.url, event);
			// this.initHeartBeat()
			this.initSubscribe()
			this.initSocketDataEvents()
			utils.emit('wsopen')
		});
		utils.off("ws_error")
		utils.on("ws_error", event => {
			console.log("[socket]-连接websocket出错", event);
			this.offLine = true
			utils.removeLs("LOCK_RECONNECT");
			clearInterval(this.heartBeatTimer);
			this.handleReconnect()
			// uni.hideLoading()
		});
		utils.off("ws_close")
		// 断开链接
		utils.on("ws_close", event => {
			console.log("[socket]-websocket链接断开", event);
			// store.dispatch('SubmitError', JSON.stringify({
			// 	code: event.code,
			// 	reason: event.reason,
			// 	wasClean: event.wasClean,
			// 	url: event.target.url
			// }))
			this.offLine = true
			utils.removeLs("LOCK_RECONNECT");
			// utils.on()
			clearInterval(this.heartBeatTimer);
			
			this.handleReconnect()
			// uni.hideLoading()
		});
	}
	initSubscribe() {
		this.ws.sendData({
			action: 10001,
			data: {
				platform: utils.getLs(AGENT_ID),
				userId: utils.getLs(USER_ID),
			}
		})
		this.ws.sendData({
			action: 10006,
			data: {
				platform: utils.getLs(AGENT_ID),
				userId: utils.getLs(USER_ID),
			}
		})
	}
	initSocketDataEvents() {
		// 20009-心跳
		utils.on("REC_HEART_BEAT", data => {
			console.log(data);
		});
		// 20001-服务端推送现货行情数据
		utils.on("REC_SPOT_TICKERS", data => {
			this.updatePrice(store.state.spotList, data.data.tickerInfos)
			// this.updatePrice(store.getters.vxTopList, data.data.tickerInfos)
			// this.updatePrice(store.getters.vxRecom, data.data.tickerInfos)
		});
		// 20006-服务端推送期货行情数据
		utils.on("REC_FUTURE_TICKERS", data => {
			this.updatePrice(store.state.futureList, data.data.tickerInfos)
		});
	}
	updatePrice(source, tickers) {
		if (!tickers.length) return
		tickers.map(item => {
			source[item.symbol] = source[item.symbol] || {}
			source[item.symbol].price = item.price
			source[item.symbol].symbol = item.symbol
			source[item.symbol].change = item.change
			source[item.symbol].high = item.high
			source[item.symbol].low = item.low
			source[item.symbol].volume = item.volume
		})
	}
	handleReconnect(count) {
		if (count) {
			this.limitConnect = count
		}
		if (utils.getLs('DO_NOT_CONNECT') === 1) return
		if (this.ws.m_state !== 3) return
		// LOCK_RECONNECT加锁，防止onclose、onerror两次重连
		if (this.limitConnect > 0) {
			if (!utils.getLs('LOCK_RECONNECT')) {
				utils.setLs("LOCK_RECONNECT", 1);
				this.limitConnect--;
				this.timeConnect++;
				console.log("第" + this.timeConnect + "次重连");
				// console.log("链接地址：" + this.url);
				clearTimeout(this.reconnectTimer)
				// 进行重连
				this.reconnectTimer = setTimeout(() => {
					this.connectSocket(this.url)
				}, 3000);
			}
		} else {
			console.log("WS连接已超时..................");
		}
	}
	// initHeartBeat() {
	// 	this.heartBeatTimer = setInterval(() => {
	// 		this.ws.sendData({
	// 			action: 10009,
	// 			data: {
	// 				time: parseInt(new Date().getTime() / 1000)
	// 			}
	// 		})
	// 	}, 10000)
	// }
}
