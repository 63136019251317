<template>
  <div class="trading-platform">
    <div class="title-box">
      <h1>{{$t('1126')}}</h1>
      <h3>{{$t('11261')}}</h3>
    </div>
    <div class="platform">
      <ul>
        <li class="item-one">
           <div class="number">{{$t('1198')}}</div>
           <div class="text">{{$t('1197')}}</div>
           <div class="line"></div>
        </li>
        <li class="item-two">
           <div class="number">{{$t('1199')}}</div>
           <div class="text">{{$t('1196')}}</div>
           <div class="line"></div>
        </li>
        <li class="item-three">
           <div class="number">0.10%</div>
           <div class="text">{{$t('1195')}}</div>
           <div class="line"></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {

  }
}    
</script>

<style lang="scss" scoped>
  @media screen and (max-width: 1000px) {
  .platform{
    ul{
      li{
        width: 100% !important;
        margin-top: 20px !important;
      }
    }
  }
}

.trading-platform {
  margin-top: 100px;
  .title-box {
    text-align: center;

    h1 {
      font-size: 50px;
    }

    h3 {
      margin-top: 20px;
      color: #91929E;
    }
  }

  .platform {
    margin:150px 0;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        width: 31%;
        height: 520px;
        background: #0F3043;
        position: relative;
        .line{
          width: 40%;
          height: 4px;
          background: #81F1E0;
          position: absolute;
          left: 30%;
          bottom: 50px;
        }
        .number{
          font-size: 30px;
          font-weight: bold;
          position: absolute;
          width: 100%;
          text-align: center;
          bottom: 120px;
        }
        .text{
          font-size: 16px;
          position: absolute;
          width: 100%;
          text-align: center;
          bottom: 80px;
          color: #91929E;
        }
      }
      .item-one{
        background: url("~@/assets/img/home8/icon_7.png") no-repeat center;
        background-size: cover;
      }
      .item-two{
        background: url("~@/assets/img/home8/icon_8.png") no-repeat center;
        background-size: cover;
      }
      .item-three{
        background: url("~@/assets/img/home8/icon_9.png") no-repeat center;
        background-size: cover;
      }
    }
  }
}
</style>