import enLocale from 'element-ui/lib/locale/lang/en'
export default {
    ...enLocale,
    1127: 'muat turun',
    1128: 'Log Masuk',
    1129: 'Closed Market',
    1130: 'Mata Wang',
    1131: 'harga',
    1132: 'Perubahan',
    1133: 'Graf',
    1134: 'Butiran',
    1135: 'Buat portfolio mata wang kripto anda sekarang',
    1136: '{appName} mempunyai berbilang ciri yang menjadikannya tempat terbaik untuk memulakan dagangan',
    1137: 'Urus portfolio anda',
    1138: 'Beli dan jual mata wang digital popular dan jejaki kesemuanya di satu tempat',
    1139: 'Pembelian berulang',
    1140: 'Melabur dalam mata wang kripto perlahan-lahan dengan mengatur pembelian harian, mingguan atau bulanan',
    1141: 'Perlindungan Bilik Kebal',
    1142: 'Untuk keselamatan tambahan, simpan dana anda dalam peti besi dan tangguhkan pengeluaran',
    1143: 'Apl mudah alih',
    1144: 'Kekal berada di puncak pasaran dengan apl {appName} untuk Android atau iOS',
    1145: 'Walaupun leveraj dagangan pada pasangan eksotik adalah hebat, ia akan menjadi lebih baik untuk mempunyai pelayan Discord yang penuh dengan meme dan alfa',
    1146: 'Hubungi kami',

    11081: 'Sehatra varotra Elite: Tohizo ny varotra haingana, milamina ary mahomby',
    11031: 'Manohana PC maro sy rafitra fandidiana finday',
    1126: 'Sehatra varotra',
    11261: 'Sehatra varotra Forex malaza indrindra',
    11191: 'Misokatra ny fifanakalozana amin\'ny fotoana rehetra, na aiza na aiza',
    nav0: 'Laman Utama',
    nav1: 'petikan',
    nav2: 'transaksi duit syiling',
    nav3: 'Transaksi kontrak',
    nav3_2: 'Transaksi kontrak',
    nav3_3: 'Akaun kontrak',
    nav4: 'pengurusan kewangan',
    nav5: 'Penerangan Syarat',
    nav6: 'Perjanjian Pengguna',
    nav7: 'Dasar Privasi',
    nav8: 'Pernyataan Undang-Undang',
    nav9: 'Spesifikasi Perdagangan',
    nav10: 'Bantuan Pemula',
    nav11: 'Soalan Lazim',
    nav12: 'Kadar yuran transaksi',
    nav13: 'Tentang kami',
    nav14: 'Perkhidmatan',
    operate: 'Operasi',
    homeTitle_1_1: 'Platform perdagangan aset digital terkemuka di dunia',
    homeTitle_1_2: 'Platform perdagangan aset digital yang paling popular, akses ke analisis teknikal dan alat perdagangan yang paling kuat dan mudah di pasaran. ',
    register: 'Daftar sekarang',
    nowtrans: 'Transaksi segera',
    logIn: 'Masuk',
    whats: 'WhatsApp',
    service: 'Perkhidmatan Pelanggan',
    home_1_2: 'Carrod Securities adalah pertukaran wang asing anda yang dipercayai',
    home_1_3: 'Logam berharga, platform CFD',
    home_2_1: 'Dana pelanggan disimpan dengan selamat dalam dana amanah berasingan bank peringkat AA global',
    home_2_2: 'Spread yang lebih rendah dan pelayan yang lebih stabil',
    footer1: 'Amaran risiko: Produk kewangan yang disediakan oleh syarikat merangkumi kontrak perbezaan (\'CFD\') dan produk kewangan lain yang kompleks. Berdagang CFD mempunyai risiko yang tinggi, kerana leverage boleh menjadi baik dan buruk bagi anda pada masa yang sama. Oleh itu, CFD mungkin tidak sesuai untuk semua pelabur, kerana kehilangan semua prinsipal pelaburan anda. Anda tidak boleh melaburkan wang yang anda tidak mampu kehilangan. Sebelum memperdagangkan produk kewangan kompleks yang disediakan, pastikan untuk memahami risiko yang terlibat. Anda diberi hak terhad dan tidak boleh dipindah milik untuk menggunakan IP yang disediakan di laman web ini hanya untuk tujuan peribadi dan bukan komersial yang berkaitan dengan perkhidmatan yang disediakan di laman web. ',
    footer2: 'Harap maklum bahawa maklumat di laman web ini tidak ditujukan kepada penduduk negara atau wilayah yang berbeza dari undang-undang dan peraturan yang dijelaskan di laman web. Carrod Securities Co Ltd bukan penasihat kewangan dan hanya nasihat umum yang diberikan kepada anda Tidak akan mengeluarkan pendapat atau cadangan mengenai pemerolehan, pemegangan atau penjualan perdagangan margin. ',
    1: 'Nama Produk',
    2: 'Kadar pulangan harian yang dijangkakan',
    3: 'Tarikh akhir produk',
    4: 'Memulakan jumlah pelaburan',
    5: 'Operasi',
    6: 'Pembelian',
    7: 'Pengurusan mata wang yang masih ada',
    8: 'Pengurusan kewangan yang fleksibel, peroleh setiap hari',
    9: 'Mengenai kami',
    10: 'Perjanjian Pengguna',
    11: 'Dasar Privasi',
    12: 'Pernyataan Undang-Undang',
    13: 'Peraturan Perdagangan',
    14: 'Penerangan Syarat',
    15: 'Perkhidmatan',
    16: 'Bantuan Pemula',
    17: 'Soalan Lazim',
    18: 'Kadar yuran transaksi',
    19: 'Sistem perdagangan kontrak kedua mata wang digital',
    20: 'Cepat, senang, tanpa pembahagian, risiko terkawal',
    21: 'Pengenalan sistem',
    22: 'Dengan perkembangan pesat mata wang digital berjangka, majoriti pengguna memiliki lebih banyak aset, dan kaedah perdagangan mata wang maya juga menjadi beragam. Pengguna semakin menggunakan kaedah penyelesaian transaksi yang cepat dan cekap. Oleh kerana itu, kontrak kedua juga sangat dipuji. Kontrak kedua yang dilancarkan oleh Mutual Cloud adalah sistem perdagangan untuk penyelesaian kontrak mata wang maya dalam unit sen. Sistem ini menyokong perdagangan harga pasaran dan menghadkan perdagangan harga; pengguna dapat membeli pilihan panggilan dan put, dengan pelaburan modal kecil, ambang rendah, dan kitaran penyelesaian pendek, dibagi menjadi 1 minit, 2 minit, 3 minit, dll., Dan sistem dapat dikonfigurasikan secara bebas. Sistem ini mempunyai sistem kawalan risiko yang ketat, dengan turun naik tertinggi dan terendah yang ditetapkan, jika had terlampaui, kemungkinan akan diset semula secara automatik ke sifar. ',
    23: 'Sumber garis K',
    24: 'Perdagangan terutamanya bergantung pada sebut harga pasaran pertukaran luaran untuk perdagangan',
    25: 'Mata wang sokongan',
    251: 'Kaedah Deposit',
    252: 'Deposit syiling',
    26: 'Isi semula melalui alamat dompet',
    27: 'Pembelian melalui transaksi C2C',
    28: 'Memenuhi keperluan platform untuk mengeluarkan duit syiling',
    281: 'Pembelian melalui transaksi OTC,',
    282: 'Platform tidak perlu menanggung risiko mata wang mengambang',
    29: 'Reka bentuk model kontrak',
    30: 'Kaedah Perdagangan',
    31: 'Penetapan waktu penyelesaian',
    32: 'Arah Perdagangan',
    33: 'Kawalan Transaksi',
    34: 'Kawalan risiko',
    35: 'Had beli',
    36: 'Mencegah kerugian besar dari pelanggan',
    38: 'Tetapkan tarikh akhir untuk membuat pesanan',
    39: 'Pastikan masa pembubaran mencukupi untuk undian',
    40: 'Siapkan dan turun garis',
    41: 'Mencetuskan kemungkinan menjadi sifar untuk mengelakkan transaksi sepihak',
    42: 'Elakkan kerugian berskala besar',
    43: 'Butang tutup satu klik',
    44: 'Pelanggan boleh mengklik butang untuk menutup kedudukan dalam situasi yang melampau',
    46: 'Pemantauan Perkhidmatan',
    47: 'Pemantauan perkhidmatan yang komprehensif untuk memastikan tindak balas tepat pada kegagalan perkhidmatan dan memulakan pemberitahuan e-mel kegagalan tepat pada waktunya',
    48: 'Sokongan Perkhidmatan',
    49: 'Kami mendekati pelanggan dengan hati, kami bersemangat, penuh perhatian, sabar, dan berhati-hati. Kami mempunyai pasukan R&D perisian profesional, pemantauan dan pengurusan platform yang ketat, dan pasukan perkhidmatan sehenti profesional. ',
    50: 'Satu, pilih pasangan perdagangan',
    51: 'Dua, periksa harga',
    52: 'Lihat harga transaksi pasar dan pilih harga yang tepat untuk membuat pesanan. ',
    53: 'Tiga, beli setelah mengisi pesanan',
    54: 'Pilih pasangan transaksi yang perlu dibeli (transaksi mata wang ke mata wang merujuk kepada menggunakan satu aset digital untuk menukar aset digital yang lain)',
    55: 'Isi harga pembelian dan jumlah pembelian, dan tunggu transaksi pasaran. ',
    57: 'Fahami urus niaga mata wang dalam beberapa saat',
    58: 'Urus niaga yang selamat dan boleh dipercayai, cepat',
    59: 'Petikan Dagangan',
    60: 'Dalam stok',
    601: 'Menyediakan aset digital canggih, CFD dan platform perdagangan komoditi kepada pelabur global',
    61: 'Apa tempatnya? ',
    62: 'Spot adalah untuk niaga hadapan. Spot dan futures adalah serupa, perbezaan utama terletak pada perbezaan leverage dan margin, waktu penghantaran transaksi, dll. ',
    63: 'Masa Depan',
    64: 'Apa itu niaga hadapan?',
    65: 'Kontrak niaga hadapan, disebut sebagai niaga hadapan, adalah kaedah perdagangan yang merangkumi masa. Dengan menandatangani kontrak, pembeli dan penjual bersetuju untuk memberikan jumlah tempat yang ditentukan mengikut masa, harga dan syarat perdagangan yang ditentukan. ',
    66: 'Pengurusan kewangan amanah',
    67: 'Apa itu pembiayaan amanah?',
    68: "Kewangan terdesentralisasi adalah sejenis kewangan berasaskan blockchain. Ia tidak bergantung pada institusi kewangan seperti broker, pertukaran atau bank untuk menyediakan alat kewangan, tetapi menggunakan kontrak pintar pada blockchain untuk menjalankan aktiviti kewangan",
    69: 'C2C',
    70: 'Apa itu C2C',
    71: 'C2C, juga dikenal sebagai "swasta-ke-swasta" adalah bentuk transaksi, ini merujuk kepada bentuk transaksi individu ke individu',
    72: 'Perkhidmatan perdagangan sehenti',
    73: 'Nama',
    74: 'Harga',
    75: 'naik turun 24H',
    76: 'Volume 24H',
    77: 'Petikan',
    78: 'Gabungan yang stabil dan selamat',
    79: 'Perlindungan keselamatan bertaraf kewangan',
    80: 'Teknologi privasi dan kerahsiaan bukti sifar tanpa pengetahuan untuk melindungi privasi pengguna, pelbagai tandatangan, seni bina berlapis dan reka bentuk keselamatan lain. ',
    81: 'Mesin pencocokan prestasi tinggi',
    82: 'Mengadopsi kaedah pembangunan kluster dan perkhidmatan mikro terdistribusi maju. Menyokong lebih daripada 3 juta transaksi sesaat serentak. ',
    83: '24 jam perkhidmatan tanpa gangguan',
    84: 'Perkhidmatan pelanggan berada dalam talian 24 jam sehari, tujuh hari seminggu, untuk menjawab pelbagai soalan untuk anda. ',
    85: 'Buka transaksi bila-bila masa, di mana sahaja',
    86: 'Semakan baru untuk memenuhi keperluan perdagangan pelbagai senario pada bila-bila masa',
    87: '24 * 7 jam sepanjang cuaca, mod operasi sepenuh masa, perkhidmatan pelanggan akan menjawab pertanyaan anda secepat mungkin. ',
    88: 'Pasangan perdagangan',
    89: 'Harga terkini',
    90: 'Bangkit dan jatuh',
    91: 'Harga tertinggi',
    92: 'Harga terendah',
    93: 'Isipadu',
    94: 'Masuk',
    95: 'Daftar',
    fee: {
        1: 'Kadar yuran transaksi',
        2: 'Bayaran transaksi mata wang',
        3: 'Bayaran transaksi kontrak'
    },
    law: {
        1: 'Pernyataan Undang-Undang',
        2: 'Artikel Pertama',
        3: 'Platform ini berkomitmen untuk mewujudkan persekitaran perdagangan aset digital yang patuh, selamat dan boleh dipercayai untuk pengguna, dan melarang pengguna menggunakan laman web ini untuk terlibat dalam pengubahan wang haram, penyeludupan, rasuah komersial, manipulasi pasaran jahat dan aktiviti perdagangan haram lain atau haram aktiviti. Untuk tujuan ini, kami melakukan yang terbaik untuk merumuskan program anti pengubahan wang haram dan anti-keganasan dan mengambil langkah-langkah kawalan risiko keselamatan yang berkesan untuk memaksimumkan perlindungan hak dan kepentingan pengguna yang sah, dan untuk mengekalkan reputasi jenama yang baik di platform sendiri ',
        4: 'Artikel Kedua',
        5: 'Apabila pihak berkuasa yang berwenang menghasilkan dokumen penyiasatan yang sesuai dan meminta laman web ini untuk bekerjasama dalam penyiasatan pengguna yang ditentukan, atau ketika tindakan seperti menutup, membekukan atau memindahkan akaun pengguna diambil, laman web ini akan mengesahkan kesahihan dan keaslian dokumen kehakiman Selepas hubungan seks, sesuai dengan kehendak pihak berkuasa yang ditentukan oleh platform untuk membantu memberikan data pengguna yang sesuai atau melakukan operasi yang sesuai, kebocoran privasi pengguna, ketidakoperasian akaun, dan kerugian lain yang disebabkan oleh pengguna, dll., Disebabkan oleh ini Kami tidak memikul tanggungjawab. ',
        6: 'Perkara 3',
        7: 'Semua pengguna yang menggunakan perkhidmatan laman web ini harus mengetahui sebelum berdagang: aset digital tidak disokong oleh pemerintah atau bank pusat mana pun, dan harga aset digital berubah-ubah, memegang atau menggunakan aset digital mempunyai risiko yang lebih tinggi. Sebelum membuat keputusan perdagangan, anda harus bersikap rasional dan berhati-hati dengan keadaan kewangan anda sendiri untuk menentukan sama ada ia sesuai untuk anda ',
        8: 'Artikel 4',
        9: 'Sesiapa yang melayari laman web ini dengan cara apa pun atau secara langsung atau tidak langsung menggunakan perkhidmatan laman web ini akan dianggap secara sukarela menerima kekangan penyataan laman web ini. '
    },
    rules: {
        1: 'Peraturan perdagangan',
        2: 'Setelah membuat pesanan, pembeli harus:',
        3: '1, selesaikan pembayaran dalam masa pembayaran yang ditentukan dalam pesanan, dan kemudian klik "Saya telah membayar";',
        4: '2, gunakan kaedah pembayaran nama sebenarnya, pembayaran pesanan mengikut jumlah pesanan dan akaun pembayaran yang ditentukan oleh pesanan untuk menyelesaikan pembayaran pesanan dalam masa nyata;',
        5: '3. Sekiranya anda memilih kaedah pembayaran bukan masa nyata untuk membayar pesanan, anda mesti mendapatkan persetujuan penjual terlebih dahulu',
        6: '4. Perintah pembayaran tidak boleh dicatat "mata wang digital, BTC, Bitcoin" dan maklumat lain;',
        7: 'Prinsip yang harus dipatuhi oleh penjual',
        8: 'Setelah pesanan dibuat, penjual harus',
        9: '1. Sediakan akaun penerimaan yang disahkan oleh platform;',
        10: '2, setelah menerima pembayaran penuh pembeli, selesaikan pelepasan dalam masa yang ditentukan dalam pesanan;',
        11: '3. Tanpa persetujuan pembeli, pembeli / khidmat pelanggan tidak akan diminta untuk membatalkan pesanan;',
        12: '4. Tanpa persetujuan pembeli, tidak ada bantahan terhadap harga pesanan yang telah dibuat;'
    },
    help: {
        1: 'Bantuan Pemula',
        2: 'Perintah Had',
        3: 'Pesanan untuk membeli / menjual pada harga yang ditentukan atau lebih baik. ',
        4: 'Pesanan pasaran',
        5: 'Pesanan untuk membeli atau menjual dengan segera pada harga pasaran semasa. ',
        6: 'Perintah Limit Stop Loss',
        7: 'Perintah untuk menjual atau menutup posisi pada harga yang ditetapkan setelah pasaran mencapai harga tertentu untuk mengawal kerugian. ',
        8: 'Had Hadkan Pesanan Keuntungan',
        9: 'Mirip dengan pesanan stop-limit, ini juga perintah untuk menjual atau menutup posisi pada harga yang ditentukan setelah pasar mencapai harga tertentu, yang digunakan untuk mengendalikan keuntungan. ',
        10: 'Pesanan stop loss pasaran',
        11: 'Perintah untuk menjual atau menutup posisi pada harga pasaran setelah pasaran mencapai harga tertentu untuk mengawal kerugian. ',
        12: 'Order Market Take Profit Order',
        13: 'Mirip dengan perintah stop loss pasar, ini juga perintah untuk menjual atau menutup posisi pada harga pasar setelah pasar mencapai harga tertentu, yang digunakan untuk mengendalikan keuntungan. ',
        14: 'Apakah margin untuk membuka posisi? ',
        15: 'Perdagangan margin bermaksud anda hanya perlu membayar peratusan margin tertentu untuk membeli dan menjual kontrak. Menurut leveraj yang dipilih, nisbah margin adalah gandaan yang lebih kecil dari nilai kontrak. Ini dapat meningkatkan keuntungan anda, tetapi mungkin juga mendedahkan anda kepada risiko tambahan. ',
        16: 'Apa itu pembubaran dan apakah tusukan? ',
        17: 'Apabila baki dana dalam akaun anda kurang dari keuntungan dan kerugian terapung yang diperlukan oleh kontrak perdagangan, kedudukan anda akan ditutup secara paksa oleh bursa, juga dikenal sebagai likuidasi. Oleh kerana harga ketika posisi ditutup ditentukan sesuai dengan harga pasar, ketika harga transaksi sangat tidak menguntungkan, Anda mungkin tidak memiliki cukup dana untuk menampung kerugian transaksi, sehingga menghasilkan nilai negatif dalam akaun Anda. Situasi ini adalah dipanggil pemendekan kedudukan. '
    },
    96: 'Pilih pasangan dagangan',
    97: 'Semak harga',
    98: 'Beli selepas mengisi pesanan',
    99: 'Log masuk akaun',
    100: 'Selamat datang kembali! Log masuk dengan e-mel atau nombor telefon anda',
    101: 'Peti Surat',
    102: 'Mudah Alih',
    103: 'Terlupa kata laluan anda? ',
    104: 'Daftar sekarang',
    105: 'Kata Laluan',
    106: 'Sila masukkan nombor telefon anda',
    107: 'Ralat format nombor telefon mudah alih',
    108: 'Sila masukkan e-mel',
    109: 'Ralat format peti mel',
    110: 'Sila masukkan kata laluan',
    111: 'Kata laluan sekurang-kurangnya 6 aksara',
    112: 'Kata laluan sehingga 32 bit',
    113: 'Bahasa dan Wilayah',
    114: 'Buat akaun {appName}',
    115: 'Gunakan e-mel atau nombor telefon mudah alih anda untuk mendaftar',
    116: 'Kod Syor',
    117: 'Setuju',
    118: 'dan',
    119: 'Perjanjian Pengguna',
    120: 'Dasar Privasi',
    121: 'Berdaftar? ',
    122: 'Kod Pengesahan',
    123: 'Hantar',
    124: 'Sila masukkan kod pengesahan',
    125: 'Kod pengesahan mestilah 6 digit',
    126: 'Tetapkan semula kata laluan',
    127: 'OK',
    128: 'Gunakan e-mel atau telefon mudah alih anda untuk menetapkan semula kata laluan log masuk',
    129: 'Pengesahan nama sebenar',
    130: 'Melengkapkan pengesahan nama sebenar membantu melindungi keselamatan akaun, meningkatkan had pengeluaran dan kebenaran transaksi',
    131: 'Pergi ke pengesahan',
    132: 'Pengesahan',
    133: 'Digunakan untuk log masuk, mengeluarkan syiling, mendapatkan kata laluan, mengubah suai tetapan keselamatan',
    134: 'Disahkan',
    135: 'Pengurusan Kata Laluan',
    136: 'Kata laluan log masuk',
    137: 'Kata Laluan Dana',
    138: 'Digunakan untuk melindungi keselamatan akaun',
    139: 'Digunakan untuk melindungi keselamatan dana',
    140: 'Tetapan Peribadi',
    141: 'Pilihan warna',
    142: 'Tetapan',
    143: 'Ubah suai',
    144: 'Tetapkan carta kandil, beli dan beli warna',
    145: 'Kaedah Harga',
    146: 'Unit penilaian aset akaun,',
    147: 'lalai',
    148: 'Hijau naik dan merah jatuh',
    149: 'Merah ke atas dan hijau ke bawah',
    150: 'Ubah suai kata laluan log masuk',
    151: 'Ubah Suai Kata Laluan Dana',
    152: 'Kata laluan lama',
    153: 'Kata laluan baharu',
    154: 'Sila masukkan kata laluan lama',
    155: 'Nombor telefon baharu',
    156: 'Peti mel baharu',
    157: 'Ikat nombor telefon mudah alih',
    158: 'ikat peti mel',
    159: 'Ubah suai nombor telefon mudah alih',
    160: 'Ubah suai peti mel',
    161: 'Disahkan',
    162: 'Tetapkan nama panggilan',
    163: 'Sila masukkan nama panggilan anda',
    164: 'Batal',
    165: 'Negara',
    166: 'Nama depan',
    167: 'Nama keluarga',
    168: 'Jenis Sijil',
    169: 'Nombor Sijil',
    170: 'Sila pilih negara',
    171: 'Sila masukkan nama pertama',
    172: 'Sila masukkan nama keluarga',
    173: 'Sila masukkan nombor ID',
    174: 'Sila muat naik foto hadapan ID',
    175: 'Sila muat naik foto belakang ID anda',
    176: 'Sila muat naik foto ID genggam anda',
    177: 'Serah',
    178: 'Mengakui',
    179: 'ID',
    180: 'Pasport',
    181: 'Seterusnya',
    182: 'Muat naik foto ID dan pengesahan lengkap',
    183: 'Muat naik gagal',
    184: 'Ralat format imej',
    185: 'Gambar terlalu besar',
    186: 'Memuatkan',
    187: 'Ikhtisar Aset',
    188: 'Pemindahan',
    189: 'Pengeluaran',
    190: 'Deposit syiling',
    191: 'Rekod kewangan',
    192: 'Bekukan',
    193: 'Tersedia',
    194: 'Jumlah',
    195: 'Sembunyikan mata wang kecil',
    196: 'Mata Wang',
    197: 'Penilaian USDT',
    198: 'Cari',
    199: 'Recharge Network',
    200: 'Alamat deposit',
    201: 'alamat keluar duit syiling',
    202: 'Jumlah cas semula',
    203: 'Tangkapan skrin mendepositkan syiling',
    204: 'Catatan',
    205: 'Salin alamat',
    206: 'Menyalin berjaya',
    207: 'Kembali',
    208: 'Pilihan',
    209: 'Sila masukkan bilangan syiling untuk dicas semula',
    210: 'Sila nombor yang sah',
    211: 'Sila masukkan alamat mata wang',
    212: 'Sila muat naik tangkapan skrin deposit syiling',
    213: 'Alamat pengeluaran',
    214: 'Jumlah pengeluaran',
    215: 'Baki yang tersedia',
    216: 'Semua',
    217: 'Yuran pengendalian',
    218: 'Sila masukkan jumlah syiling yang dikeluarkan',
    219: 'Sila masukkan kata laluan dana',
    220: 'Kata laluan dana mestilah 6 digit',
    221: 'Kata laluan lalai',
    222: 'Anda belum mengikat dompet yang sepadan lagi! ',
    223: 'Pergi untuk menambah',
    224: 'Penyerahan berjaya',
    225: 'Tambah dompet koleksi',
    226: 'Alamat',
    227: 'Sila masukkan rangkaian',
    228: 'Sila masukkan alamat',
    229: 'Rangkaian',
    230: 'Rangkaian pengeluaran',
    231: 'Panjang alamat ialah 32 bit terendah',
    232: 'Pemindahan dana',
    233: 'Jumlah pemindahan',
    234: 'Sila masukkan amaun pindahan',
    235: 'Baki tidak mencukupi',
    236: 'Boleh dipindahkan',
    237: 'Arah leret',
    238: 'Pemindahan',
    239: 'Dari',
    240: 'ke',
    241: 'Rekod kewangan',
    242: 'rekod deposit syiling',
    243: 'Rekod pengeluaran',
    244: 'Rekod pemindahan',
    245: 'Kuantiti',
    246: 'Status',
    247: 'Masa yang dicipta',
    248: 'Sahkan masa',
    249: 'Tiada lagi',
    250: 'Harga',
    253: 'Pasangan dagangan',
    254: 'Ubah',
    255: 'Tiada data lagi',
    256: 'Kumulatif',
    257: 'Tinggi',
    258: 'Rendah',
    259: 'Jumlah dagangan',
    260: 'Buka',
    261: 'Terima',
    262: 'Jumlah',
    263: 'Perdagangan niaga hadapan',
    264: 'bullish',
    265: 'Merosot',
    266: 'Nama produk',
    267: 'Lihat kontrak',
    268: 'Jumlah beli',
    269: 'Sila masukkan jumlah pembelian',
    270: 'Yuran transaksi',
    271: 'Pembelian minimum',
    272: 'Status',
    273: 'Serahan belum selesai',
    274: 'Diserahkan',
    275: 'Pesanan berjaya dibuat',
    276: 'Nama Produk',
    277: 'Untung dan rugi',
    278: 'Harga Beli',
    279: 'Harga Penghantaran',
    280: 'Teruskan perdagangan',
    283: 'Keuntungan',
    284: 'Sahkan pesanan',
    285: 'Perintah sejarah',
    286: 'Perintah ditahan',
    287: 'Masa yang dicipta',
    288: 'Beli',
    289: 'Jual',
    290: 'Bilangan transaksi',
    291: 'Jumlah urus niaga',
    292: 'Kontrak',
    293: 'Masa Penghantaran',
    294: 'Harga penghantaran',
    295: 'saat',
    296: 'Minit',
    297: 'Jam',
    298: 'Hari',
    299: 'Masa yang tinggal',
    300: 'Lihat lagi',
    301: 'Transaksi mata wang',
    302: 'Jumlah jualan',
    303: 'Sila masukkan jumlah jualan',
    304: 'Transaksi mata wang Fiat',
    305: 'Aset',
    306: 'Keluar',
    307: 'Berjaya didaftarkan',
    308: 'Sila masukkan pengesyoran',
    309: 'Kejayaan',
    310: 'Gagal',
    311: 'Mengesahkan',
    312: 'Kaedah pembayaran',
    313: 'Alamat pengeluaran',
    314: 'Padam',
    315: 'Operasi',
    316: 'Dompet anda',
    317: 'Adakah anda pasti mahu memadamkan? ',
    318: 'Berjaya padam',
    319: 'Pengesahan gagal',
    320: 'Pensijilan Semula',
    330: 'Dompet pengeluaran aset digital',
    331: 'Ikat kad bank, keluarkan tunai kepada mata wang fiat',
    332: 'Sila masukkan alamat yang sah',
    1000: 'hari',
    1001: 'Sedang berjalan',
    1002: 'Tertutup',
    1003: 'Peraturan',
    1004: 'Kelebihan produk',
    1005: 'Simpan seperti yang anda ambil',
    1006: 'Dividen tamat tempoh',
    1007: 'Bonus harian',
    1008: 'Faedah deposit langsung',
    1009: 'Jaminan modal 100%',
    1010: 'Pendapatan biasa semasa cuti',
    1011: 'Rehat keesokan harinya',
    1012: 'Kelebihan produk',
    1013: 'Ahli mendepositkan 200,000 USDT pada platform, pilih produk pengurusan kekayaan dengan tempoh 14 hari dan pendapatan harian 0.5%, dan pendapatan harian adalah seperti berikut: 200,000*0.5%=1,000USDT Iaitu, selepas 14 hari, anda boleh mendapat 14,000 USDT dalam pendapatan, dan pendapatan dikeluarkan setiap hari. Depositkan prinsipal dan ia akan dikembalikan secara automatik ke akaun anda apabila matang.',
    1014: 'Perintah Jagaan',
    1015: 'Penghosan',
    1016: 'Selesai',
    1017: 'Ditebus',
    1018: 'Tiada data lagi',
    1019: 'Jumlah pembelian',
    1020: 'Kadar pulangan harian',
    1021: 'AMANAH.6',
    1022: 'Masa pembayaran',
    1023: 'Tarikh Penebusan',
    1024: 'Tamat tempoh',
    1025: 'Baru-baru ini (pendapatan harian)',
    1026: 'Masa dividen',
    1027: 'Setiap hari',
    1028: 'Jumlah jagaan',
    1029: 'Pulangan semasa tamat tempoh',
    1030: 'Baki yang tersedia',
    1031: 'Anggaran jumlah pendapatan',
    1032: 'Pemindahan dana',
    1033: 'Semua',
    1034: 'Had tunggal',
    1035: 'Yuran pengendalian',
    1036: 'Saya telah membaca dan bersetuju',
    1037: 'Platform perjanjian perkhidmatan pengurusan kewangan',
    1038: 'OK',
    1039: 'Beli',
    1040: 'Beli',
    1041: 'Jual',
    1042: 'Pemindahan wayar',
    1043: 'Sila masukkan jumlah',
    1044: 'Kategori mata wang Fiat',
    1045: 'Kuantiti terjual',
    1046: 'Maksimum tersedia untuk dijual',
    1047: 'Semua',
    1048: 'Harga unit rujukan',
    1049: 'Kaedah pembayaran anda',
    1050: 'Nama Bank',
    1051: 'Nombor akaun bank',
    1052: 'Nama',
    1053: 'Anda tidak mengikat kaedah pembayaran yang sepadan dan tidak boleh dijual. ',
    1054: 'Rekod pesanan',
    1055: 'Penapis',
    1056: 'Jenis',
    1057: 'Status',
    1058: 'Memproses',
    1059: 'Lulus',
    1060: 'Gagal',
    1061: 'Kuantiti',
    1062: 'Kejayaan',
    1063: 'Melaksanakan tugas',
    1064: `Kira keputusan hari ini`,
    1065: 'Pendapatan kumulatif',
    1066: 'Perintah dalam tahanan',
    1067: 'Akaun amanah tidak dibuka',
    1068: 'Buka akaun amanah komunikasi, nikmati pendapatan pasif yang stabil, dan jadikan penghargaan aset lebih mudah. ',
    1069: 'Memohon pengaktifan bermakna anda telah membaca dan bersetuju dengannya',
    1070: '"Perjanjian Perkhidmatan Pengurusan Kewangan Platform"',
    1071: 'Mohon untuk pengaktifan',
    1072: 'Batal',
    1073: 'Melaksanakan...',
    1074: 'Penebusan',
    1075: 'Adakah anda pasti mahu menebusnya? ',
    1076: 'Penebusan awal memerlukan pembayaran ganti rugi tercair, yang akan ditolak kali ini mengikut jenis pengurusan kewangan anda',
    1077: ', Adakah penebusan disahkan?',
    1078: 'Dihantar',
    1079: 'Sila masukkan kod pengesahan',
    1080: 'Segera',
    1081: 'Keuntungan',
    1082: 'Masa',
    1083: 'Tambah kaedah pembayaran mata wang yang sah',
    1084: 'Status Pesanan',
    1085: 'Mata Wang',
    1086: 'Bilangan mata wang yang sah',
    1087: 'Nombor akaun bank',
    1088: 'Kaedah kutipan wang gemuk',
    1089: 'Tambah kaedah pengumpulan',
    1090: 'Kaedah pembayaran sedia ada',
    1091: 'Mata wang Fiat',
    1092: 'Kod Bank',
    1093: 'Adakah anda pasti mahu memadamkan kaedah pembayaran? ',
    1094: 'Sila masukkan nombor akaun bank',
    1095: 'Sila masukkan nama bank',
    1096: 'Sila masukkan mata wang yang sah',
    1097: 'Sila pilih',
    1098: 'Sila masukkan nama anda',
    1099: 'Cawangan',
    1100: 'Tidak terhad',
    1101: 'Anda masih belum mengesahkan nama sebenar anda',
    1102: 'Kertas Putih',
    1103: 'Ciri-ciri Kami',
    1104: 'Kami Selamat',
    1105: 'Kami berkuasa',
    1106: 'Sokongan Terbaik',
    1107: 'Kami Global',
    1108: 'Bagaimana ia Berfungsi',
    1109: 'Pengguna di lebih 20 negara dan wilayah menggunakan platform kami dan diiktiraf secara meluas',
    1110: 'Penyulitan Data Lanjutan',
    1111: 'Kepelbagaian mata wang kripto yang kaya',
    '1111_2': 'Pelbagai jenis logam berharga dan mata wang kripto',
    1112: '90+ Mata Wang Kripto disenaraikan',
    1113: 'Data transaksi anda dilindungi melalui penyulitan hujung-ke-hujung, memastikan bahawa hanya anda mempunyai akses kepada maklumat peribadi anda.',
    1114: '43 bilion',
    1115: 'Jumlah dagangan 24j',
    1116: '30 juta',
    1117: 'Pengguna berdaftar yang mempercayai kami',
    1118: 'Yuran transaksi terendah',
    1119: 'Muat turun',
    1120: 'Nama institusi kewangan',
    1121: 'nama cawangan',
    1122: 'nombor pejabat cawangan',
    1123: 'Nombor akaun',
    1124: 'Nama penerima',
    1125: 'Kinjo',

    1148: 'Platform Cryptocurrency Paling Dipercayai',
    1149: 'Berikut adalah sebab mengapa anda perlu memilih kami',
    1150: 'Kami Selamat',
    1151: 'Teknologi privasi dan kerahsiaan bukti sifar pengetahuan yang tidak berat sebelah untuk melindungi privasi pengguna, pelbagai tandatangan, seni bina berlapis dan reka bentuk keselamatan lain',
    1152: 'Sokongan Terbaik',
    1153: '24*7 jam semua cuaca, mod operasi sepenuh masa, perkhidmatan pelanggan akan menjawab pertanyaan berkaitan anda secepat mungkin',
    1154: 'Kami Global',
    1155: 'Pengguna di lebih daripada 20 negara dan wilayah menggunakan platform kami dan diiktiraf secara meluas',
    1156: 'Kami berkuasa',
    1157: 'Platform dagangan aset digital yang paling popular, akses kepada analisis teknikal dan alat dagangan yang paling berkuasa dan mudah di pasaran',
    1158: 'Pelbagai jenis mata wang kripto',
    1159: ' 90+ mata wang kripto disenaraikan',
    1160: 'Penyulitan Data Lanjutan',
    1161: 'Data urus niaga anda dilindungi melalui penyulitan hujung ke hujung, memastikan anda hanya mempunyai akses kepada maklumat peribadi anda. ',
    1162: 'Kami menyokong pelbagai mata wang digital yang paling popular',
    1163: 'Banyak negara telah mewujudkan transaksi setempat',
    1164: 'Gunakan mata wang yang sah untuk membeli aset digital dan segera memasuki dunia aset digital',
    1165: 'Berdagang untuk pergi ke mana-mana pada bila-bila masa',
    1166: 'Imbas saya untuk Muat Turun',
    1167: 'Kadar pertukaran',
    1168: 'Beli dan jual 250+ mata wang kripto dengan muat naik kadar pertukaran baru-baru ini',
    1169: 'Будьте в курсе с нашим приложением и настольным клиентом',
    1170:'Nama',
    1171:'harga',
    1172:'Ubah',
    1173:'Carta',
    1174:'Berdagang',
    1175:'Perincian',
    1176:'kami',
    1177:'selamat',
    1178:'Kami ada',
    1179:'Global',
    1180:'Berkuasa',
    1181:'Pelbagai Pelbagai',
    1182:'Cryptocurrency',
    1183:'Data Lanjutan',
    1184:'Penyulitan',
    1185:'bersedia untuk bermula',
    1186:'Kami ialah platform dagangan aset digital terkemuka dunia.',
    1187:'Kami ialah platform dagangan aset digital terkemuka di dunia. Kami sentiasa berusaha untuk meningkatkan ciri keselamatan untuk memastikan keselamatan aset digital pelanggan kami. Model kami adalah untuk membawa projek pelanggan dengan nilai yang mampan.',

    1188: 'Jumlah Bekalan',
    1189: '24*7 mod operasi sepenuh masa sepanjang cuaca, perkhidmatan pelanggan akan menjawab pertanyaan yang berkaitan untuk anda secepat mungkin',
    1190: 'Kami global',
    1191: 'Kita kuat',
    1192: 'Platform dagangan aset digital yang paling popular, dapatkan analisis teknikal dan alat dagangan yang paling berkuasa dan mudah di pasaran.',
    1193: 'Pelbagai jenis mata wang kripto',
    1194: 'Data transaksi anda dilindungi dengan penyulitan hujung-ke-hujung, memastikan bahawa anda sahaja yang boleh mengakses maklumat peribadi anda.',
    1195: 'Yuran transaksi minimum',
    1196: 'Percayai pengguna berdaftar kami',
    1197: 'Jumlah dagangan 24 jam',
    1198: '+42 bilion',
    1199: '+30 juta',
    1200: 'Beberapa negara telah menyediakan pusat perkhidmatan transaksi setempat untuk mewujudkan ekosistem blockchain yang mengintegrasikan pelbagai bentuk perniagaan',
    1201: 'Gunakan mata wang fiat untuk membeli aset digital dan segera memasuki dunia aset digital',
    1202: 'Muat turun APP',
    1203: 'kelebihan kita',
    1204: 'Maklumat mata wang dan mata wang yang kaya, menyediakan mata wang, kontrak, urus niaga mata wang sah dan kaedah urus niaga lain, pasaran profesional dan kedalaman.'
}
