import Vue from 'vue'
import Vuex from 'vuex'
import utils from '@/utils/misc.js'
import { getCountries, getUserInfo, getCurrencies, getSpotList, getFutureList, getAccountNames, getCoins, getUserAssets } from '@/api'
import {findIndex} from '@/utils/misc.js'
Vue.use(Vuex)

export default new Vuex.Store({
  getters: {
    assetsMap: state => {
      const obj = {
        total: 0,
        toUTotal: 0,
        toUFreezen: 0,
        toUAvailabel: 0,
        assets: {
        }
      }
      const accounts = {
      'C2C': state.configs.moduleIsOpenC2c,
      'FUTURE': state.configs.moduleIsOpenFuture,
      'WEALTH': state.configs.moduleIsOpenPledge,
      'SPOT': '1'
      }
      const o = state.assets.assets
      const a = state.accountCates.filter(item => accounts[item.category] === '1')
      for (const key of Object.keys(o)) {
        const i = findIndex(a, item => item.category === key)
        if (~i) {
          obj.assets[key] = {
            ...o[key]
          }
          obj.assets[key].categoryName = a[i].name
          obj.assets[key].toUTotal = 0
          obj.assets[key].toUFreezen = 0
          obj.assets[key].toUAvailabel = 0
          o[key].coins.map(coin => {
            // 找到虚拟币对usdt价格
            const _key = `${coin.coin}USDT`
            const priceObj = state.spotList[_key] || {
              price: 1
            }
            const price = priceObj.price
            const toUTotal = (+coin.totalAmount * price).toFixed(4)
            const toUFreezen = (+coin.freezenAmount * price).toFixed(4)
            const toUAvailabel = (+coin.availableAmount * price).toFixed(4)
            obj.assets[key].toUTotal += +toUTotal
            obj.assets[key].toUFreezen += +toUFreezen
            obj.assets[key].toUAvailabel += +toUAvailabel
            let coinInfo = state.coins[coin.coin]
            if (coinInfo) {
              coin.availableAmount = (+coin.availableAmount).toFixed(coinInfo.decimal)
              coin.freezenAmount = (+coin .freezenAmount).toFixed(coinInfo.decimal)
              coin.totalAmount = (+coin.totalAmount).toFixed(coinInfo.decimal)
              coin.toUTotal = toUTotal
            }
          })
          obj.toUTotal += obj.assets[key].toUTotal
          obj.toUFreezen += obj.assets[key].toUFreezen
          obj.toUAvailabel += obj.assets[key].toUAvailabel
        }
      }
      const _obj = {...obj, assets: {}}
      for (const item of ['SPOT', 'FUTURE', 'C2C', 'WEALTH']) {
        if (obj.assets[item]) {
          _obj.assets[item] = obj.assets[item]
        }
      }
      return _obj
    }
  },
  state: {
    configs: {},
    quotes: [],
    countryList: [],
    userInfo: {},
    currencies: [],
    assets: {
      assets: {}
    },
    coinToUsdtMap: {},
    coins: {},
    curCoinList:[],
    accountCates: [],
    curCoinName: 'BTC',
    spotList: utils.getLs('SPOT_LIST') || {},
    futureList: utils.getLs('FUTURE_LIST') || {},
    financialBuy:{},
    showServiceDialog: false,
    urlServiceDialog: '',
  },

  mutations: {
    SET_COUNTRIES(state, data) {
      state.countryList = data
    },
    SET_USER_INFO(state, data) {
      state.userInfo = data
    },
    SET_CURRENCIES(state, data) {
      state.currencies = data
    },
    SET_SPOT_LIST(state, data) {
      state.spotList = data
    },
    SET_FUTURE_LIST(state, data) {
      state.futureList = data
    },
    SET_COINS(state, data) {
      state.coins = data
    },
    SET_SERVICE(state, data) {
      state.showServiceDialog = data.status
      state.urlServiceDialog = data.url
    },
    SET_ACCOUNT_CATES(state, data) {
      state.accountCates = data
    },
    SET_USER_ASSETS(state, data) {
      state.assets = data
    },
    SET_FINANCiAL_BUY(state, data) {
       state.financialBuy = data
    }
  },

  actions: {
    // 获取所有币种信息
		getCoins({commit}) {
			return new Promise((resolve, reject) => {
				getCoins().then(res => {
					let obj = {}
					res.coins.map(item => {
            const key = item.coin.toUpperCase()
						obj[key] = item
					})
					commit('SET_COINS', obj)
					utils.setLs('COINS', obj)
					resolve(obj)
				}).catch(e => {
					reject(e)
				})
			})
		},
    getCountries({commit}) {
      return new Promise((resolve, reject) => {
        getCountries().then(res => {
          commit('SET_COUNTRIES', res.list)
          resolve(res)
        }).catch(e => {
          reject(e)
        })
      })
    },
    getUserInfo({commit}) {
      return new Promise((resolve, reject) => {
        getUserInfo().then(res => {
          commit('SET_USER_INFO', res)
          resolve(res)
        }).catch(e => {
          reject(e)
        })
      })
    },
    getCurrencies({commit}) {
      return new Promise((resolve, reject) => {
        getCurrencies().then(res => {
          const list = res.list || []
          const obj = {}
          list.map(item => {
            obj[item.currency] = item
          })
          commit('SET_CURRENCIES', obj)
          resolve(res)
        }).catch(e => {
          reject(e)
        })
      })
    },
    getAccountNames({commit}) {
      return new Promise((resolve, reject) => {
        getAccountNames().then(res => {
          commit('SET_ACCOUNT_CATES', res.accounts || [])
          resolve(res)
        }).catch(e => {
          reject(e)
        })
      })
    },
    getUserAssets({commit}) {
      return new Promise((resolve, reject) => {
        getUserAssets().then(data => {
					let temp = {
						total: 0,
						toUTotal:0,
						toUFreezen:0,
						toUAvailabel:0,
						assets: {}
					}
					data.accounts.map(item => {
						if (temp.assets[item.category]) {
							temp.assets[item.category].coins.push(item)
						} else {
							temp.assets[item.category] = {
								...item,
								category: item.category,
								amount: '',
								totalUSDT: 0,
								accountName: item.category,
								coins: [item],
							}
						}
					})
					temp.total = data.total
					commit('SET_USER_ASSETS', temp)
					resolve(data)
				}).catch(error => {
					reject(error)
				})
      })
    },
    // 获取现货行情
		getSpotList({commit}) {
			return new Promise((resolve, reject) => {
				getSpotList().then(res => {
					let obj = {}
					if (res.coins) {
						res.coins.map(item => {
							let key = item.coin + item.medium
							obj[key] = {
								...item,
								high: '--',
								low: '--',
								symbol: key,
							}
						})
						commit('SET_SPOT_LIST', obj)
						utils.setLs('SPOT_LIST', obj)
					}
					resolve(obj)
				}).catch(e => {
					reject(e)
				})
			})
		},
    // 获取期货行情
		getFutureList({commit}) {
			return new Promise((resolve, reject) => {
				getFutureList().then(res => {
					let obj = {}
					if (res.coins) {
						res.coins.map(item => {
							let key = item.coin + item.medium
							obj[key] = {
								...item,
								high: '--',
								low: '--',
								symbol: key,
							}
						})
						commit('SET_FUTURE_LIST', obj)
						utils.setLs('FUTURE_LIST', obj)
					}
					resolve(obj)
				}).catch(e => {
					reject(e)
				})
			})
		},
  }
})
