import protoRoot from "@/proto/proto";
import protobuf from "protobufjs";
import ProtobufMessageMap from "./ProtobufMessageMap";
const BaseMessage = protoRoot.lookup("BaseMessage");

export function encodeToBuffer(data) {
	// return JSON.stringify(data)
	// 第二次转码,使用基础数据结构
	const PBObject = protoRoot.lookup(ProtobufMessageMap[data.action]);
	const PBData = PBObject.create(data.data);
	const encodeChildren = PBObject.encode(PBData).finish();

	// 第二次转码数据
	const parentData = BaseMessage.create({
		action: data.action,
		data: encodeChildren
	});
	const resultData = BaseMessage.encode(parentData).finish();
	return resultData;
}

export function decodeToObject(data) {
	// 将ArrayBuffer转为Uint8Array
	const uint8View = new Uint8Array(data);
	// 使用基础数据格式第一次转码

	const baseData = BaseMessage.decode(uint8View);

	// 使用对应协议数据第二次转码
	const PBObject = protoRoot.lookup(ProtobufMessageMap[baseData.action]);
	const buf = protobuf.util.newBuffer(baseData.data);
	// decode响应体
	const resData = PBObject.decode(buf);

	return {
		action: baseData.action,
		data: resData
	};
}
