<template>
 <div class="download-area">
      <div class="v-container">
        <h2>{{ $t(1119) }}</h2>
        <p class="sub-title">{{ $t(11191) }}</p>
        <div class="flex">
          <div class="flex1 flex flex-box">
            <div>
                <p>{{ $t(85) }}</p>
                <vue-qr :size="180" :text="downloadUrl" style="width: 216px; height: 216px;"></vue-qr>
            </div>
            <div class="" style="height: 245px; margin-left: 20px; padding-top: 40px;">
                <a href="#" class="d-btn" @click="goToDownload">
                    <img src="@/assets/img/home2/down_ios_active.png" alt="" />
                </a>
                <a href="#" class="d-btn" @click="goToDownload">
                    <img src="@/assets/img/home2/down_android.png" alt="" />
                </a>
                <a href="#" class="d-btn" @click="goToH5">
                    <img src="@/assets/img/home2/down_h5.png" alt="" />
                </a>
              <!-- <a href="#" class="d-btn" @click="goToDownload">IOS Download</a>
              <a href="#" class="d-btn" @click="goToDownload">Android Download</a>
              <a href="#" class="d-btn" @click="goToH5">H5 Login</a> -->
            </div>
          </div>
          <div class="flex1 imgarea">
            <img src="@/assets/img/home2/bottom-d-right.png" alt="">
          </div>
        </div>
      </div>
    </div>   
</template>

<script>
import vueQr from 'vue-qr'
export default {
    components: {
        vueQr 
    },
    computed: {
        downloadUrl() {
            return window.configObj.downloadUrl
        }
    },
    methods: {
        goToDownload() {
            window.location.href = window.configObj.downloadUrl;
        },
        goToH5() {
            window.location.href = location.origin + "/h5/";
        },
    }
}    
</script>

<style lang="scss" scoped>
@media screen  and (max-width: 1200px) {
    .imgarea {
        display: none;
    }
    .flex-box {
        flex-direction: column;
    }
}    
.items-center {
    align-items: center;
}    
    
.download-area{
    background: #0E0E22;
  padding: 60px 0;
  text-align: center;
  h2{
    margin-bottom: 40px;
    color: #fff;
    font-size: 36px;
    font-weight: 700;
  }
  .sub-title {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .part-icon{
    font-size: 45px;
    margin-bottom: 20px;
    color: #0088ff;
    .fa{
      font-weight: 900;
    }
  }
  .single {
    text-align: center;
    padding: 40px 30px;
    margin-bottom: 30px;
    transition: all 0.3s ease-in;
    box-shadow: 0px 0px 9px 2px #f5f5f5;
    color: #666;
    font-size: 16px;
    min-height: 300px;
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    h3{
      color: #000050;
      margin-bottom: 16px;
      font-size: 24px;
      font-weight: 600;
    }
    p{
      line-height: 24px;
    }
    &:hover{
      box-shadow: 0px 0px 19px 12px #f5f5f5;
    }
  }
    p{
        font-size: 16px;
        margin-bottom: 40px;
    }
    .d-btn{
        display: block;
        // width: 214px;
        // height: 58px;
        margin: 20px auto !important;
    }
}
</style>