import trLocale from 'element-ui/lib/locale/lang/tr-TR'
export default {
    ...trLocale,
    1127: 'indir',
    1128: 'Giriş',
    1129: 'Pazarı Çıktır',
    1130: 'Para Birimi',
    1131: 'fiyat',
    1132: 'Değiştir',
    1133: 'Grafik',
    1134: 'Ayrıntılar',
    1135: 'Şu anda kripto para portföyünüzü oluşturun',
    1136: '{appName}, onu ticarete başlamak için en iyi yer haline getiren birçok özelliğe sahiptir',
    1137: 'Portföyünüzü yönetin',
    1138: 'Popüler dijital para birimlerini alıp sat ve hepsini tek bir yerden takip et',
    1139: 'Yinelenen satın almalar',
    1140: 'Günlük, haftalık veya aylık alımları düzenleyerek yavaş yavaş kripto paralara yatırım yapın',
    1141: 'Kasa Koruması',
    1142: 'Ek güvenlik için paranızı bir kasada tutun ve para çekme işlemlerini erteleyin',
    1143: 'Mobil uygulama',
    1144: 'Android veya iOS için {appName} uygulamasıyla pazarın zirvesinde kalın',
    1145: 'Egzotik çiftlerde kaldıraçlı ticaret harika olsa da, memler ve alfalarla dolu bir Discord sunucusuna sahip olmak daha da iyi olurdu',
    1146: 'Bize ulaşın',

    11081: 'Elit İşlem Platformu: Hızlı, İstikrarlı ve Verimli İşlem Yapmaya Devam Edin',
    11031: 'Birden çok PC ve mobil işletim sistemini destekler',
    1126: 'Ticaret Platformu',
    11261: 'En Popüler Forex İşlem Platformu',
    11191: 'Her zaman, her yerde açık işlemler',
    nav0: 'Ev',
    nav1: 'Alıntılar',
    nav2: 'Nokta',
    nav3: 'Vadeli İşlemler',
    nav3_2: 'Sözleşme işlemi',
    nav3_3: 'Sözleşme hesabı',
    nav4: 'Güven',
    nav5: 'Terim Açıklaması',
    nav6: 'Kullanıcı Sözleşmesi',
    nav7: 'Gizlilik Politikası',
    nav8: 'Yasal Açıklama',
    nav9: 'Ticaret Spesifikasyonu',
    nav10: 'Acemi Yardımı',
    nav11: 'SSS',
    nav12: 'İşlem ücreti oranı',
    nav13: 'Hakkımızda',
    nav14: 'Hizmet',
    operate: 'Operasyon',
    homeTitle_1_1: 'Dünyanın önde gelen dijital varlık ticaret platformu',
    homeTitle_1_2: 'En popüler dijital varlık alım satım platformu, piyasadaki en güçlü ve kullanışlı teknik analiz ve alım satım araçlarına erişim.',
    register: 'Şimdi üye Ol',
    nowtrans: 'Hemen işlem yap',
    logIn: 'Giriş yapmak',
    whats: 'WhatsApp',
    service: 'Hizmet',
    footer1: `Risk uyarısı: Şirket tarafından sağlanan finansal ürünler, fark sözleşmeleri ('CFD'ler') ve diğer karmaşık finansal ürünleri içerir. CFD ticareti yüksek düzeyde risk taşır, çünkü kaldıraç aynı anda sizin için hem iyi hem de kötü olabilir. Bu nedenle, CFD'ler tüm yatırım anaparanızı kaybedebileceğinden tüm yatırımcılar için uygun olmayabilir. Kaybetmeyi göze alamayacağınız parayı asla yatırmamalısınız. Sağlanan karmaşık finansal ürünlerin ticaretini yapmadan önce lütfen ilgili riskleri anladığınızdan emin olun. Size bu web sitesinde sağlanan IP'yi yalnızca web sitesinde sağlanan hizmetlerle ilgili kişisel ve ticari olmayan amaçlarla kullanmak için münhasır olmayan ve devredilemez sınırlı bir hak verilmiştir.`,
    footer2: 'Lütfen bu web sitesindeki bilgilerin, web sitesinde açıklanan yasa ve yönetmeliklerden farklı herhangi bir ülke veya yargı bölgesinde ikamet edenlere yönelik olmadığını unutmayın. Carrod Securities Co Ltd bir mali müşavir değildir ve size yalnızca genel tavsiye verilmektedir. Marj ticaretinin satın alınması, elde tutulması veya satılması hakkında herhangi bir görüş veya öneride bulunmayacaktır.',
    1: 'Para Birimi Adı',
    2: 'Günlük ücret',
    3: 'Ürün son tarihi',
    4: 'Başlangıç ​​yatırım tutarı',
    5: 'İşlem',
    6: 'Satın alma',
    7: 'Kalan para birimi yönetimi',
    8: 'Esnek Kazan, her gün kar et',
    9: 'Hakkımızda',
    10: 'Kullanıcı Sözleşmesi',
    11: 'Gizlilik Politikası',
    12: 'Yasal Açıklama',
    13: 'İşlem Kuralları',
    14: 'Koşul Açıklaması',
    15: 'Hizmet',
    16: 'Acemi Yardımı',
    17: 'SSS',
    18: 'İşlem ücreti oranı',
    19: 'Dijital para birimi ikinci sözleşme ticaret sistemi',
    20: 'Hızlı, kullanışlı, paylaştırma yok, risk kontrol edilebilir',
    21: 'Sistemin tanıtımı',
    22: 'Dijital para vadeli işlemlerinin hızlı gelişimi ile, kullanıcıların çoğunluğu giderek daha fazla varlığa sahip ve sanal para biriminin ticaret yöntemleri de çeşitlendi. Kullanıcılar giderek daha hızlı ve verimli işlem uzlaştırma yöntemlerini takip ediyor. Bu nedenle ikinci sözleşme de büyük beğeni topladı. Mutual Cloud tarafından başlatılan ikinci sözleşme, sanal para birimi sözleşmelerinin sent birimlerinde ödenmesi için bir ticaret sistemidir. Sistem, piyasa fiyatı ticaretini ve limit fiyat ticaretini destekler; kullanıcılar, 1 dakika, 2 dakika, 3 dakika vb. bölümlere ayrılmış küçük sermaye yatırımı, düşük eşik ve kısa uzlaşma döngüsü ile alım ve satım opsiyonları satın alabilir ve sistem bağımsız olarak yapılandırılabilir. Sistem, en yüksek ve en düşük dalgalanmaların ayarlandığı sıkı bir risk kontrol sistemine sahiptir, limit aşılırsa oranlar otomatik olarak sıfırlanır. ',
    23: 'Kline kaynağı',
    24: 'Ticaret, alım satım için esas olarak dış borsaların piyasa kotasyonlarına bağlıdır',
    25: 'Destek para birimi',
    251: 'Para Yatırma Yöntemi',
    252: 'Depozito',
    26: 'Cüzdan adresi üzerinden şarj edin',
    27: 'C2C işlemleri ile satın alma',
    28: 'Coin basmak için platformun ihtiyaçlarını karşılayın',
    281: 'OTC işlemi ile satın alma',
    282: 'Platformun dalgalanma riskini taşımasına gerek yok',
    29: 'Sözleşme model tasarımı',
    30: 'İşlem Yöntemi',
    31: 'Uzlaşma zamanı ayarı',
    32: 'Ticaret Yönü',
    33: 'İşlem Kontrolü',
    34: 'Risk kontrolü',
    35: 'Satın alma limiti',
    36: 'Müşterilerden kaynaklanan büyük ölçekli kayıpları önleyin',
    38: 'Sipariş vermek için son tarihi belirleyin',
    39: 'Çekiliş için yeterli tasfiye süresi sağlayın',
    40: 'Kurulum ve iniş hatları',
    41: 'Tek taraflı işlemleri önlemek için oranları sıfırlayın',
    42: 'Büyük ölçekli kayıplardan kaçının',
    43: 'Tek tıkla kapat düğmesi',
    44: 'Müşteriler aşırı durumlarda bir pozisyonu kapatmak için butona tıklayabilir',
    46: 'Hizmet İzleme',
    47: 'Hizmet arızalarına zamanında yanıt verilmesini sağlamak ve arıza e-posta bildirimlerini zamanında başlatmak için hizmetlerin kapsamlı izlenmesi',
    48: 'Hizmet Desteği',
    49: 'Müşterilere kalbimizle yaklaşırız, coşkulu, özenli, sabırlı ve dikkatliyiz. Profesyonel bir yazılım Ar-Ge ekibimiz, titiz bir platform izleme ve yönetimimiz ve profesyonel bir tek noktadan hizmet ekibimiz var. ',
    50: 'Bir, alım satım çiftini seçin',
    51: 'İki, fiyatı kontrol et',
    52: 'Piyasa işlem fiyatına bakın ve sipariş vermek için doğru fiyatı seçin. ',
    53: 'Üç, siparişi doldurduktan sonra satın alın',
    54: 'Satın alınması gereken işlem çiftini seçin (para biriminden dövize işlem, bir dijital varlığın başka bir dijital varlıkla takas edilmesi için kullanılması anlamına gelir)',
    55: 'Satın alma fiyatını ve satın alma hacmini girin ve piyasa işlemini bekleyin. ',
    57: 'Saniye cinsinden döviz işlemlerini anlayın',
    58: 'Güvenli ve güvenilir, hızlı işlem',
    59: 'Ticari Fiyatlar',
    60: 'Stokta',
    601: 'Küresel yatırımcılara gelişmiş dijital varlıklar, CFD\'ler ve emtia ticaret platformları sağlayın',
    61: 'Yer neresi? ',
    62: 'Spot gelecekler içindir. Spot ve vadeli işlemler benzerdir, temel fark kaldıraç ve marj farkı, işlem teslim süresi vb.',
    63: 'Vadeli İşlemler',
    64: 'Gelecek nedir?',
    65: 'Vadeli işlem olarak adlandırılan vadeli işlem sözleşmesi, zamana yayılan bir ticaret yöntemidir. Alıcı ve satıcı, bir sözleşme imzalayarak, belirlenen süre, fiyat ve diğer ticaret koşullarına göre belirli miktarda spot teslim etmeyi kabul eder. ',
    66: 'Güven Kazandır',
    67: 'Güven finansmanı nedir?',
    68: 'Merkezi olmayan finans, bir tür blok zinciri tabanlı finanstır. Finansal araçlar sağlamak için komisyoncular, borsalar veya bankalar gibi finansal kurumlara güvenmez, ancak finansal faaliyetleri yürütmek için blok zincirindeki akıllı sözleşmeleri kullanır',
    69: 'C2C',
    70: 'C2C Nedir',
    71: 'C2C, "özelden özele" olarak da bilinen bir işlem şeklidir, kişiden kişiye işlem şeklini ifade eder',
    72: 'Tek elden ticaret hizmeti',
    73: 'Ad',
    74: 'Giriş Fiyatı',
    75: '24H Değişim',
    76: '24H Hacim',
    77: 'Alıntılar',
    78: 'Kararlı ve güvenli toplama',
    79: 'Finansal düzeyde güvenlik koruması',
    80: 'Kullanıcı gizliliğini, çoklu imzaları, katmanlı mimariyi ve diğer güvenlik tasarımlarını korumak için tarafsız sıfır bilgi kanıtlı gizlilik ve gizlilik teknolojisi. ',
    81: 'Yüksek performanslı eşleştirme motoru',
    82: 'Gelişmiş dağıtılmış küme mimarisini ve mikro hizmet geliştirme yöntemlerini benimseyin. Saniyede eşzamanlı olarak 3 milyondan fazla işlemi destekleyin. ',
    83: '24 saat kesintisiz hizmet',
    84: '7/24 MÜŞTERİ DESTEĞİ Sorularınızı mümkün olduğunca çabuk yanıtlamaya çalışıyoruz. Lütfen yanıt vermemiz için bize 24 saat süre tanıyın.',
    85: 'Her zaman, her yerde açık işlemler',
    86: 'Her zaman çeşitli senaryoların alım satım ihtiyaçlarını karşılamak için yepyeni revizyon',
    87: '24*7 saat tüm hava, tam zamanlı çalışma modu, müşteri hizmetleri ilgili sorularınızı en kısa sürede yanıtlayacaktır. ',
    88: 'İşlem çifti',
    89: 'Son fiyat',
    90: 'Değişim',
    91: 'En yüksek fiyat',
    92: 'En düşük fiyat',
    93: 'Ses',
    94: 'Giriş',
    95: 'Kaydol',
    fee: {
        1: 'İşlem ücreti oranı',
        2: 'Döviz işlem ücreti',
        3: 'Sözleşme işlem ücreti'
    },
    law: {
        1: 'Yasal Açıklama',
        2: 'İlk Makale',
        3: 'Bu platform, kullanıcılar için uyumlu, güvenli ve güvenilir bir dijital varlık alım satım ortamı yaratmayı taahhüt eder ve kullanıcıların bu web sitesini kara para aklama, kaçakçılık, ticari rüşvet, kötü niyetli piyasa manipülasyonu ve diğer yasa dışı ticaret faaliyetleri veya Yasa dışı aktiviteler. Bu amaçla, sıkı kara para aklama ve terörle mücadele programları oluşturmak için elimizden gelenin en iyisini yapıyoruz ve kullanıcıların meşru hak ve çıkarlarının korunmasını en üst düzeye çıkarmak ve platformun kendisinin iyi bir marka itibarını sürdürmesini sağlamak için etkili güvenlik riski kontrol önlemleri alıyoruz. ',
        4: 'İkinci Makale',
        5: 'Yetkili makamlar ilgili soruşturma belgelerini hazırladığında ve bu web sitesinin belirlenen kullanıcıların soruşturmasında işbirliği yapmasını veya kullanıcı hesaplarının kapatılması, dondurulması veya aktarılması gibi önlemler alınmasını talep ettiğinde, bu web sitesi adli belgelerin geçerliliğini ve gerçekliğini doğrulayacaktır Seks sonrası , ilgili kullanıcı verilerinin sağlanmasına veya ilgili işlemlerin yapılmasına yardımcı olmak üzere platform tarafından belirlenen yetkili makamın gereksinimlerine uygun olarak, kullanıcının gizlilik sızıntısı, hesap çalışamazlığı ve kullanıcılardan kaynaklanan diğer kayıplar, vb. bu web sitesinden kaynaklanır. . Herhangi bir sorumluluk kabul etmiyoruz. ',
        6: 'Madde 3',
        7: 'Bu web sitesinin hizmetlerini kullanan tüm kullanıcılar alım satım yapmadan önce bilmelidir: dijital varlıklar herhangi bir hükümet veya merkez bankası tarafından desteklenmez ve dijital varlıkların fiyatı büyük ölçüde dalgalanır, dijital varlıkları tutmak veya kullanmak daha yüksek risk taşır. Bir ticaret kararı vermeden önce, sizin için uygun olup olmadığını belirlemek için kendi mali durumunuzla rasyonel ve ihtiyatlı olmalısınız',
        8: 'Madde 4',
        9: 'Bu web sitesine herhangi bir şekilde giriş yapan veya bu web sitesinin hizmetlerini doğrudan veya dolaylı olarak kullanan herkes, bu web sitesi beyanının kısıtlamalarını gönüllü olarak kabul etmiş sayılacaktır. '
    },
    rules: {
        1: 'Ticaret kuralları',
        2: 'Sipariş oluşturduktan sonra alıcı şunları yapmalıdır:',
        3: '1, siparişte belirtilen ödeme süresi içinde ödemeyi tamamlayın ve ardından "Ödedim" seçeneğine tıklayın;',
        4: '2, sipariş ödemesini gerçek zamanlı olarak tamamlamak için gerçek adım ödeme yöntemimi, sipariş tutarına göre sipariş ödemesini ve sipariş tarafından belirlenen ödeme hesabını kullanın;',
        5: '3. Sipariş için ödeme yapmak için gerçek zamanlı olmayan bir ödeme yöntemi seçerseniz, satıcının önceden onayını almalısınız',
        6: '4. Ödeme emri "dijital para birimi, BTC, Bitcoin" ve diğer bilgileri not etmeyecektir;',
        7: 'Satıcının uyması gereken ilkeler',
        8: 'Sipariş oluşturulduktan sonra satıcı gerekir',
        9: '1. Platform tarafından onaylanmış bir alıcı hesabı sağlayın;',
        10: '2, alıcının ödemesinin tamamını aldıktan sonra, siparişte belirtilen süre içinde serbest bırakma işlemini tamamlayın;',
        11: '3. Alıcının onayı olmadan alıcı/müşteri hizmetinin siparişi iptal etmesi gerekmeyecektir;',
        12: '4. Alıcının onayı olmadan oluşturulan sipariş fiyatına itiraz edilemez;'
    },
    help: {
        1: 'Acemi Yardımı',
        2: 'Limit Emri',
        3: 'Belirtilen bir fiyattan veya daha iyi bir fiyattan alım/satım emri. ',
        4: 'Piyasa emri',

        5: 'Mevcut piyasa fiyatından hemen alım veya satım emri. ',
        6: 'Limit Stop Loss Emri',
        7: 'Piyasa belirli bir fiyata ulaştıktan sonra kayıpları kontrol etmek için belirli bir fiyattan bir pozisyonu satma veya kapatma emri. ',
        8: 'Limit Fiyat Kar Al Emri',
        9: 'Stop-limit emrine benzer şekilde, aynı zamanda, kârı kontrol etmek için kullanılan, piyasa belirli bir fiyata ulaştıktan sonra belirli bir fiyattan bir pozisyonu satma veya kapatma emridir. ',
        10: 'Piyasa zararı durdur emri',
        11: 'Piyasa belirli bir fiyata ulaştıktan sonra kayıpları kontrol etmek için piyasa fiyatından bir pozisyonu satma veya kapatma emri. ',
        12: 'Piyasa Fiyatı Kar Al Emri',
        13: 'Piyasa stop loss emrine benzer şekilde, piyasa belirli bir fiyata ulaştıktan sonra piyasa fiyatından bir pozisyonu satma veya kapatma emridir, bu da karı kontrol etmek için kullanılır. ',
        14: 'Pozisyon açma marjı nedir? ',
        15: 'Marjin ticareti, sözleşmeyi satın almak ve satmak için yalnızca marjın belirli bir yüzdesini ödemeniz gerektiği anlamına gelir. Seçilen kaldıraca göre marj oranı, sözleşme değerinin daha küçük bir katıdır. Kârınızı artırabilir, ancak sizi ek risklere de maruz bırakabilir. ',
        16: 'Tasfiye nedir ve delinme nedir? ',
        17: 'Hesabınızda kalan para, alım satım sözleşmesinin gerektirdiği değişken kâr ve zarardan az olduğunda, pozisyonunuz tasfiye olarak da bilinen borsa tarafından zorla kapatılır. Bir pozisyon kapatırken fiyat piyasa fiyatına göre belirlendiğinden, işlem fiyatı son derece olumsuz olduğunda, işlemde oluşan zararı karşılayacak kadar paranız olmayabilir ve bu da hesabınızda negatif değer oluşmasına neden olabilir. Bu duruma kısa devre denir. '
    },
    96: 'Ticaret çiftini seçin',
    97: 'Fiyatı kontrol et',
    98: 'Siparişi doldurduktan sonra satın alın',
    99: 'Hesap girişi',
    100: 'Tekrar hoş geldiniz! E-posta veya telefon numaranız ile giriş yapın',
    101: 'Posta Kutusu',
    102: 'Mobil',
    103: 'Şifrenizi mi unuttunuz? ',
    104: 'Şimdi kaydolun',
    105: 'Şifre',
    106: 'Lütfen telefon numaranızı giriniz',
    107: 'Cep telefonu numarası biçimlendirme hatası',
    108: 'Lütfen e-posta girin',
    109: 'Posta kutusu biçim hatası',
    110: 'Lütfen bir şifre girin',
    111: 'Şifre en az 6 karakterdir',
    112: 'Parola 32 bit\'e kadar',
    113: 'Dil ve Bölge',
    114: '{appName} hesabı oluştur',
    115: 'Kayıt olmak için e-posta adresinizi veya cep telefonu numaranızı kullanın',
    116: 'Davet kodu',
    117: 'Katılıyorum',
    118: 've',
    119: 'Kullanıcı Sözleşmesi',
    120: 'Gizlilik Politikası',
    121: 'Zaten bir hesabınız var mı? ',
    122: 'Doğrulama Kodu',
    123: 'Gönder',
    124: 'Lütfen doğrulama kodunu girin',
    125: 'Doğrulama kodu 6 haneli olmalıdır',
    126: 'Şifreyi sıfırla',
    127: 'Tamam',
    128: 'Giriş şifresini sıfırlamak için e-postanızı veya cep telefonunuzu kullanın',
    129: 'KYC Doğrulaması',
    130: 'Gerçek adla kimlik doğrulamanın tamamlanması, hesap güvenliğinin korunmasına, para çekme limitlerinin ve işlem izinlerinin artırılmasına yardımcı olur',
    131: 'Doğrulamaya Git',
    132: 'Doğrulama',
    133: 'Giriş yapmak, bozuk para çekmek, şifreleri almak, güvenlik ayarlarını değiştirmek için kullanılır',
    134: 'Doğrulandı',
    135: 'Şifre Yönetimi',
    136: 'Giriş şifresi',
    137: 'Para Şifresi',
    138: 'Hesap güvenliğini korumak için kullanılır',
    139: 'Fonların güvenliğini korumak için kullanılır',
    140: 'Kişisel Ayarlar',
    141: 'Renk tercihi',
    142: 'Ayarlar',
    143: 'Değiştir',
    144: 'Şam grafiğini ayarlayın, yukarı ve aşağı renkleri satın alın',
    145: 'Fiyatlandırma Yöntemi',
    146: 'Hesap varlık değerleme birimi',
    147: 'varsayılan',
    148: 'Yeşiller yükselir ve kırmızılar düşer',
    149: 'Kırmızı yukarı ve yeşil aşağı',
    150: 'Giriş şifresini değiştir',
    151: 'Para Parolasını Değiştir',
    152: 'Eski şifre',
    153: 'Yeni şifre',
    154: 'Lütfen eski şifreyi girin',
    155: 'Yeni telefon numarası',
    156: 'Yeni posta kutusu',
    157: 'Cep telefonu numarasını bağla',
    158: 'posta kutusunu bağla',
    159: 'Cep telefonu numarasını değiştir',
    160: 'Posta kutusunu değiştir',
    161: 'Doğrulandı',
    162: 'Bir takma ad belirleyin',
    163: 'Lütfen takma adınızı girin',
    164: 'İptal',
    165: 'Ülke',
    166: 'Ad',
    167: 'Soyadı',
    168: 'Sertifika Türü',
    169: 'Sertifika Numarası',
    170: 'Lütfen bir ülke seçin',
    171: 'Lütfen adınızı girin',
    172: 'Lütfen soyadını girin',
    173: 'Lütfen kimlik numarasını girin',
    174: 'Lütfen kimliğin ön fotoğrafını yükleyin',
    175: 'Lütfen kimliğinizin arka yüzünün bir fotoğrafını yükleyin',
    176: 'Lütfen elde taşınan kimliğinizin bir fotoğrafını yükleyin',
    177: 'Onayla',
    178: 'Onaylayıcı',
    179: 'kimlik',
    180: 'Pasaport',
    181: 'Sonraki',
    182: 'Kimlik fotoğrafları yükleyin ve kimlik doğrulamayı tamamlayın',
    183: 'Yükleme başarısız',
    184: 'Görüntü biçimi hatası',
    185: 'Resim çok büyük',
    186: 'Yükleniyor',
    187: 'Toplam Bakiye',
    188: 'Transfer',
    189: 'Çekilme',
    190: 'Depozito',
    191: 'Mali kayıtlar',
    192: 'Dondur',
    193: 'Mevcut',
    194: 'Toplam',
    195: 'Düşük değerli para birimini gizle',
    196: 'Para',
    197: 'USDT değerlemesi',
    198: 'Ara',
    199: 'Şebekeyi Şarj Et',
    200: 'Para yatırma adresi',
    201: 'Para verme adresi',
    202: 'Şarj miktarı',
    203: 'Para yatırmanın ekran görüntüsü',
    204: 'Açıklamalar',
    205: 'Adresi kopyala',
    206: 'Kopyalama başarılı',
    207: 'Dönüş',
    208: 'Opsiyonel',
    209: 'Lütfen doldurulacak jeton sayısını girin',
    210: 'Lütfen geçerli bir numara',
    211: 'Lütfen madeni para adresini girin',
    212: 'Lütfen para yatırma işleminin ekran görüntüsünü yükleyin',
    213: 'Para çekme adresi',
    214: 'Çekim miktarı',
    215: 'Kullanılabilir bakiye',
    216: 'Tümü',
    217: 'İşlem ücreti',
    218: 'Lütfen çekilen para miktarını girin',
    219: 'Lütfen para şifresini girin',
    220: 'Para şifresi 6 haneli olmalıdır',
    221: 'Varsayılan şifre',
    222: 'İlgili cüzdanı henüz bağlamadınız! ',
    223: 'Eklemeye git',
    224: 'Gönderim başarılı',
    225: 'Koleksiyon cüzdanı ekle',
    226: 'Adres',
    227: 'Lütfen ağa girin',
    228: 'Lütfen adresi girin',
    229: 'Ağ',
    230: 'Para çekme ağı',
    231: 'Adres uzunluğu en düşük 32 bit',
    232: 'Para transferi',
    233: 'Transfer tutarı',
    234: 'Lütfen transfer tutarını giriniz',
    235: 'Yetersiz bakiye',
    236: 'Kullanılabilir Bakiye',
    237: 'Transfer Yönü',
    238: 'Aktarımı onayla',
    239: 'Kimden',
    240: 'için',
    241: 'Mali kayıtlar',
    242: 'Para yatırma kayıtları',
    243: 'Çekilme kayıtları',
    244: 'Transfer kayıtları',
    245: 'Miktar',
    246: 'Durum',
    247: 'Yaratılan zaman',
    248: 'Zamanı onayla',
    249: 'Artık yok',
    250: 'Fiyat',
    253: 'Ad',
    254: 'Değiştir',
    255: 'Henüz veri yok',
    256: 'Topla',
    257: 'Yüksek',
    258: 'Düşük',
    259: 'Vol',
    260: 'Açık',
    261: 'Kapat',
    262: 'Miktar',
    263: 'Vadeli işlemler',
    264: 'Satın alın',
    265: 'Satın al',
    266: 'Para Birimi Adı',
    267: 'Sözleşmeyi görüntüle',
    268: 'Satın alma tutarı',
    269: 'Lütfen satın alma tutarını girin',
    270: 'İşlem ücreti',
    271: 'Minimum miktar:',
    272: 'Durum',
    273: 'Teslimat bekleniyor',
    274: 'Teslim edildi',
    275: 'Sipariş başarıyla verildi',
    276: 'Para Birimi Adı',
    277: 'Kar ve zarar',
    278: 'Satın Alma Fiyatı',
    279: 'Teslimat Bedeli',
    280: 'İşlemlere devam',
    283: 'Kar',
    284: 'Siparişi onayla',
    285: 'Geçmiş siparişler',
    286: 'Siparişler devam ediyor',
    287: 'Yaratılma zamanı',
    288: 'Satın al',
    289: 'Sat',
    290: 'Miktar',
    291: 'Toplam',
    292: 'Sözleşme',
    293: 'Teslim Süresi',
    294: 'Teslimat fiyatı',
    295: 'saniye',
    296: 'Dakika',
    297: 'Saat',
    298: 'Gün',
    299: 'Kalan süre',
    300: 'Daha fazlasını görüntüle',
    301: 'Döviz işlemi',
    302: 'Satış tutarı',
    303: 'Satış tutarını giriniz',
    304: 'Fiat para birimi işlemi',
    305: 'Varlıklar',
    306: 'Çıkış',
    307: 'Başarıyla kaydedildi',
    308: 'Lütfen bir öneri girin',
    309: 'Başarı',
    310: 'Başarısız',
    311: 'Onaylanıyor',
    312: 'Ödeme yöntemi',
    313: 'Para çekme adresi',
    314: 'Sil',
    315: 'İşlem',
    316: 'Cüzdanınız',
    317: 'Silmek istediğinizden emin misiniz? ',
    318: 'Başarıyla Sil',
    319: 'Kimlik doğrulama başarısız',
    320: 'Yeniden Sertifikalandırma',
    330: 'Dijital varlık çekme cüzdanı',
    331: 'Banka kartını bağla, fiat para birimine nakit çek',
    1000: 'gün',
    1001: 'Devam ediyor',
    1002: 'Kapalı',
    1003: 'Kurallar',
    1004: 'Ürün avantajı',
    1005: 'Alırken kaydet',
    1006: 'Temettü süresi doldu',
    1007: 'Günlük bonus',
    1008: 'Canlı mevduat faizi',
    1009: '%100 sermaye garantisi',
    1010: 'Tatillerde normal gelir',
    1011: 'Ertesi gün dinlenin',
    1012: 'Ürün avantajı',
    1013: 'Üyeler platforma 200.000 USDT yatırıyorlar, 14 gün vadeli ve günlük geliri %0,5 olan bir servet yönetimi ürünü seçiyorlar ve günlük gelir şu şekilde: 200.000*%0.5=1.000USDT Yani 14 gün sonra siz 14.000 USDT gelir elde edebilir ve gelir günlük olarak verilir.Anaparayı yatırın ve vade sonunda otomatik olarak hesabınıza iade edilecektir. ',
    1014: 'Saklama Emri',
    1015: 'Barındırma',
    1016: 'Tamamlandı',
    1017: 'Kurtarıldı',
    1018: 'Henüz veri yok',
    1019: 'Satın alma tutarı',
    1020: 'Günlük getiri oranı',
    1021: 'GÜVEN.6',
    1022: 'Ödeme zamanı',
    1023: 'Kullanım Tarihi',
    1024: 'Süresi doldu',
    1025: 'Son zamanlarda (günlük gelir)',
    1026: 'Temettü zamanı',
    1027: 'Her gün',
    1028: 'Emanet tutarı',
    1029: 'Süre sonunda iade',
    1030: 'Kullanılabilir bakiye',
    1031: 'Tahmini toplam gelir',
    1032: 'Para transferi',
    1033: 'Tümü',
    1034: 'Tek limit',
    1035: 'İşlem ücreti',
    1036: 'Okudum ve kabul ettim',
    1037: 'Platform finansal yönetim hizmet sözleşmesi',
    1038: 'Tamam',
    1039: 'Satın al',
    1040: 'Satın al',
    1041: 'Sat',
    1042: 'Havale',
    1043: 'Lütfen miktarı girin',
    1044: 'Fiat para birimi kategorisi',
    1045: 'Satılan miktar',
    1046: 'Satışa uygun maksimum',
    1047: 'Tümü',
    1048: 'Referans birim fiyatı',
    1049: 'Ödeme yönteminiz',
    1050: 'Banka Adı',
    1051: 'Banka hesap numarası',
    1052: 'Ad',
    1053: 'İlgili ödeme yöntemini bağlamadınız ve satılamaz. ',
    1054: 'Sipariş kaydı',
    1055: 'Filtre',
    1056: 'Tür',
    1057: 'Durum',
    1058: 'İşleniyor',
    1059: 'Geçti',
    1060: 'Başarısız',
    1061: 'Miktar',
    1062: 'Başarı',
    1063: 'Görevi gerçekleştirme',
    1064: `Bugünün sonuçlarını hesapla`,
    1065: 'Kümülatif gelir',
    1066: 'Gözaltına alma emri',
    1067: 'Güven hesabı açılmadı',
    1068: 'Bir iletişim güven hesabı açın, istikrarlı pasif gelirin keyfini çıkarın ve varlık takdirini kolaylaştırın. ',
    1069: 'Aktivasyon için başvur, bunu okuyup kabul ettiğiniz anlamına gelir',
    1070: '"Platform Mali Yönetim Hizmet Sözleşmesi"',
    1071: 'Aktivasyon için başvurun',
    1072: 'İptal',
    1073: 'Yürütülüyor...',
    1074: 'Kefaret',
    1075: 'Kullanmak istediğinizden emin misiniz? ',
    1076: 'Erken itfa, mali yönetim türünüze göre bu sefer mahsup edilecek olan tasfiye edilmiş zararların ödenmesini gerektirir',
    1077: ', İtfa onaylandı mı?',
    1078: 'Gönderildi',
    1079: 'Lütfen doğrulama kodunu girin',
    1080: 'Sorgu',
    1081: 'Kar',
    1082: 'Zaman',
    1083: 'Yasal para birimi ödeme yöntemi ekle',
    1084: 'Sipariş Durumu',
    1085: 'Para Birimi',
    1086: 'Yasal para birimi sayısı',
    1087: 'Banka hesap numarası',
    1088: 'Şişman para toplama yöntemi',
    1089: 'Toplama yöntemi ekle',
    1090: 'Mevcut ödeme yöntemi',
    1091: 'Fiat para birimi',
    1092: 'Banka Kodu',
    1093: 'Ödeme yöntemini silmek istediğinizden emin misiniz? ',
    1094: 'Lütfen banka hesap numarasını girin',
    1095: 'Lütfen banka adını girin',
    1096: 'Lütfen yasal para birimini girin',
    1097: 'Lütfen seçin',
    1098: 'Lütfen adınızı girin',
    1099: 'Şube',
    1100: 'Sınırsız',
    1101: 'Gerçek adınızı henüz doğrulamadınız',
    1102: 'Beyaz Kağıt',
    1103: 'Özelliklerimiz',
    1104: 'Güvendeyiz',
    1105: 'Biz güçlüyüz',
    1106: 'En İyi Destek',
    1107: 'Biz Küreseliz',
    1108: 'Nasıl Çalışır',
    1109: '20\'den fazla ülke ve bölgedeki kullanıcılar platformumuzu kullanıyor ve geniş çapta tanınıyor',
    1110: 'Gelişmiş Veri Şifreleme',
    1111: 'Zengin kripto para birimi çeşitliliği',
    '1111_2': 'Amplia gama de metales preciosos y criptomonedas',
    1112: '90+ Kripto para birimi listeleniyor',
    1113: 'İşlem verileriniz, kişisel bilgilerinize yalnızca sizin erişiminizin sağlanması için uçtan uca şifreleme ile güvence altına alınır.',
    1114: '43 milyar',
    1115: '24 saatlik işlem hacmi',
    1116: '30 milyon',
    1117: 'Bize güvenen kayıtlı kullanıcılar',
    1118: 'En düşük işlem ücretleri',
    1119: 'İndir',
    1120: 'Finans kuruluşunun adı',
    1121: 'şube adı',
    1122: 'şube numarası',
    1123: 'Hesap numarası',
    1124: 'Alıcı adı',
    1125: 'Kinjo',

    1148: 'En Güvenilir Kripto Para Birimi Platformu',
    1149: 'İşte bu yüzden bizi seçmelisiniz',
    1150: 'biz gizliyiz',
    1151: 'Kullanıcı gizliliğini, çoklu imzaları, katmanlı mimariyi ve diğer güvenlik tasarımlarını korumak için tarafsız gizlilik ve güvenlik teknolojisi',
    1152: 'En İyi Destek',
    1153: '24*7 saat tüm hava koşullarında, tam zamanlı çalışma modu, müşteri hizmetleri ilgili sorularınızı en kısa sürede yanıtlayacaktır.',
    1154: 'Biz Globaliz',
    1155: '20 den fazla ülke ve bölgedeki kullanıcılar platformumuzu kullanıyor ve geniş çapta tanınmaktadır',
    1156: 'biz güçlüyüz',
    1157: 'En popüler dijital varlık alım satım platformu, piyasadaki en güçlü ve kullanışlı teknik analiz ve alım satım araçlarına erişim',
    1158: 'Zengin kripto para birimi çeşitliliği',
    1159: 'Listelenen 90+ Kripto Para Birimi',
    1160: 'Gelişmiş Veri Şifreleme',
    1161: 'İşlem verileriniz uçtan uca şifreleme ile güvence altına alınır ve kişisel bilgilerinize yalnızca sizin erişiminiz sağlanır.',
    1162: 'Çeşitli popüler dijital para birimini destekliyoruz',
    1163: 'Birçok ülke yerelleştirilmiş işlem kurdu',
    1164: 'Dijital varlıkları satın almak ve dijital varlıkların dünyasına hızla girmek için yasal para birimini kullanın',
    1165: 'Her zaman her yere gitmek için ticaret yapın',
    1166: 'İndirmek için beni tarayın',
    1167: 'Döviz Kurları',
    1168: 'Yakın zamanda yüklenen döviz kurları ile 250 den fazla kripto para birimi satın alın ve satın',
    1169: 'Uygulamamız ve masaüstü istemcimiz ile gelişmelerden haberdar olun',
    1170: 'İsim',
    1171: 'Fiyat',
    1172: 'Değiştirmek',
    1173: 'Çizelge',
    1174: 'Ticaret',
    1175: 'Detay',
    1176: 'biz',
    1177: 'Güvenli',
    1178: 'Sahibiz',
    1179: 'küresel',
    1180: 'Güçlü',
    1181: 'Zengin Çeşit',
    1182: 'Kripto para',
    1183: 'Gelişmiş Veri',
    1184: 'şifreleme',
    1185: 'başlamaya hazır mısın',
    1186: 'Dünyanın önde gelen dijital varlık alım satım platformuyuz. ',
    1187: 'Dünyanın önde gelen dijital varlık alım satım platformuyuz. Müşterilerimizin dijital varlıklarının güvenliğini sağlamak için güvenlik özelliklerini sürekli olarak geliştirmeye çalışıyoruz. Modelimiz, müşterilere sürdürülebilir değere sahip projeler getirmektir.',

    1188: 'Toplam Arz',
    1189: '24*7 tüm hava koşullarında, tam zamanlı çalışma modu, müşteri hizmetleri sizin için ilgili soruları mümkün olan en kısa sürede yanıtlayacaktır.',
    1190: 'biz küreseliz',
    1191: 'Biz güçlüyüz',
    1192: 'En popüler dijital varlık alım satım platformu, piyasadaki en güçlü ve kullanışlı teknik analiz ve alım satım araçlarına sahip olun.',
    1193: 'Çok çeşitli kripto para birimleri',
    1194: 'İşlem verileriniz uçtan uca şifreleme ile korunur ve kişisel bilgilerinize yalnızca sizin erişmenizi sağlar.',
    1195: 'Asgari işlem ücreti',
    1196: 'Kayıtlı kullanıcılarımıza güvenin',
    1197: '24 saat işlem hacmi',
    1198: '+42 milyar',
    1199: '+30 milyon',
    1200: 'Birkaç ülke, birden fazla iş formunu entegre eden bir blok zinciri ekosistemi oluşturmak için yerelleştirilmiş işlem hizmet merkezleri kurdu.',
    1201: 'Dijital varlıklar satın almak ve dijital varlıklar dünyasına hızla girmek için itibari para birimini kullanın',
    1202: 'UYGULAMA indir',
    1203: 'Bizim avantajımız',
    1204: 'Zengin para birimi ve para birimi bilgileri, para birimi, sözleşmeler, yasal para birimi işlemleri ve diğer işlem yöntemleri, profesyonel piyasa ve derinlik sağlar。'
}
