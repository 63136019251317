/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/light");

var $root = ($protobuf.roots["default"] || ($protobuf.roots["default"] = new $protobuf.Root()))
.setOptions({
  go_package: "./;pb"
})
.addJSON({
  pb: {
    nested: {
      BaseMessage: {
        fields: {
          action: {
            type: "int64",
            id: 1
          },
          data: {
            type: "bytes",
            id: 2
          },
          time: {
            type: "int64",
            id: 3
          }
        }
      },
      SendSubscribeTicker: {
        fields: {
          platform: {
            type: "string",
            id: 1
          },
          userId: {
            type: "int64",
            id: 2
          }
        }
      },
      TickerInfo: {
        fields: {
          symbol: {
            type: "string",
            id: 1
          },
          price: {
            type: "double",
            id: 2
          },
          change: {
            type: "double",
            id: 3
          },
          high: {
            type: "double",
            id: 4
          },
          low: {
            type: "double",
            id: 5
          },
          volume: {
            type: "double",
            id: 6
          },
          diff: {
            type: "double",
            id: 7
          }
        }
      },
      RecTickerData: {
        fields: {
          time: {
            type: "int64",
            id: 1
          },
          tickerInfos: {
            rule: "repeated",
            type: "TickerInfo",
            id: 2
          }
        }
      },
      SendSubscribeInfo: {
        fields: {
          symbol: {
            type: "string",
            id: 1
          },
          period: {
            type: "string",
            id: 2
          },
          platform: {
            type: "string",
            id: 3
          },
          userId: {
            type: "int64",
            id: 4
          }
        }
      },
      KlineInfo: {
        fields: {
          open: {
            type: "double",
            id: 1
          },
          close: {
            type: "double",
            id: 2
          },
          high: {
            type: "double",
            id: 3
          },
          low: {
            type: "double",
            id: 4
          },
          volume: {
            type: "double",
            id: 5
          },
          startTime: {
            type: "int64",
            id: 6
          },
          endTime: {
            type: "int64",
            id: 7
          }
        }
      },
      RecKlineData: {
        fields: {
          time: {
            type: "int64",
            id: 1
          },
          klineInfos: {
            rule: "repeated",
            type: "KlineInfo",
            id: 2
          }
        }
      },
      SendSubscribeRealTime: {
        fields: {
          symbol: {
            type: "string",
            id: 1
          },
          platform: {
            type: "string",
            id: 2
          },
          userId: {
            type: "int64",
            id: 3
          },
          limit: {
            type: "int64",
            id: 4
          }
        }
      },
      TimeData: {
        fields: {
          time: {
            type: "int64",
            id: 1
          },
          price: {
            type: "double",
            id: 2
          }
        }
      },
      RecTimeData: {
        fields: {
          time: {
            type: "int64",
            id: 1
          },
          lists: {
            rule: "repeated",
            type: "TimeData",
            id: 2
          }
        }
      },
      HeartBeat: {
        fields: {
          time: {
            type: "int64",
            id: 1
          }
        }
      },
      CancelSubscribe: {
        fields: {
          type: {
            type: "int64",
            id: 1
          },
          symbol: {
            type: "string",
            id: 2
          }
        }
      },
      SendSubscribeDepth: {
        fields: {
          symbol: {
            type: "string",
            id: 1
          },
          platform: {
            type: "string",
            id: 2
          },
          userId: {
            type: "int64",
            id: 3
          }
        }
      },
      DepthInfo: {
        fields: {
          volume: {
            type: "double",
            id: 1
          },
          price: {
            type: "double",
            id: 2
          }
        }
      },
      RecDepthInfo: {
        fields: {
          buy: {
            rule: "repeated",
            type: "DepthInfo",
            id: 1
          },
          sell: {
            rule: "repeated",
            type: "DepthInfo",
            id: 2
          }
        }
      }
    }
  }
});

module.exports = $root;
