<template>
  <div class="main-coins">
    <div class="coins-list">
      <ul>
        <li v-for="(item, index) in mainCoins" :key="index">
          <div class="currency-img">
            <img :src="coinMsg[item.coin]?coinMsg[item.coin].logoUri:''" />
          </div>
          <div class="currency-name">
            <p class="name">{{ item.coin }}/{{ item.medium }}</p>
            <p class="percentage">AVAX <span class="color-red">{{ item.change>=0?'+': '' }}{{ (item.change).toFixed(1) }}%</span></p>
          </div>
          <div class="currency-price">
            <p>{{$t('1188')}}</p>
            <p class="price">${{ item.price }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import utils from '@/utils/misc'
export default {
  data() {
    return {
    }
  },
  computed: {
    mainCoins() {
      const hotCoins = ['BTC', 'ETH', 'DOGE', 'EOS', 'FIL']
      const arr = []
      for (const key in this.$store.state.spotList) {
        arr.push(this.$store.state.spotList[key])
      }
      return arr.filter(item => hotCoins.includes(item.coin))
    },
    coinMsg() {
      return this.$store.state.coins
    }
  },
}    
</script>


<style lang="scss" scoped>
@media screen and (max-width: 1000px) {
  .main-coins {
    .coins-list {
      ul {
        li {
          width: 100% !important;
        }
      }
    }
  }
}

.main-coins {
  margin-top: 150px;

  .coins-list {
    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        width: 33.3%;
        height: 150px;
        background: #347A7B;
        margin-top: 20px;
        display: flex;
        align-items: center;

        img {
          width: 55px;
          height: 55px;
        }

        .currency-img {
          flex: 2;
          display: flex;
          justify-content: flex-end;
        }

        .currency-name {
          flex: 3;
          padding-left: 16px;

          .name {
            font-size: 16px;
          }

          .percentage {
            margin-top: 10px;

            span {
              margin-left: 5px;
            }
          }
        }

        .currency-price {
          flex: 3;

          .price {
            font-size: 16px;
            margin-top: 10px;
          }
        }
      }

      li:nth-child(even) {
        background: #195059;
      }
    }
  }

  .color-red {
    color: #FD0013;
  }

  .color-green {
    color: #19DFB3;
  }
}
</style>
