<template>
 <div class="download-area">
      <div class="v-container">
        <h2>{{'APP'+ $t(1119) }}</h2>
        <p class="sub-title">{{ $t(11191) }}</p>
        <div class="flex" style="align-items:center">
          <div class="flex1 flex flex-box">
            <div>
                <p>{{ $t(1127) }}</p>
                <vue-qr :size="180" :text="downloadUrl" style="width: 224px; height: 224px;"></vue-qr>
            </div>
          </div>
          <div class="flex1 imgarea">
            <img style="width:867px;height:693px" src="@/assets/img/home5/download.png" alt="">
          </div>
        </div>
      </div>
    </div>   
</template>

<script>
import vueQr from 'vue-qr'
export default {
    components: {
        vueQr 
    },
    computed: {
        downloadUrl() {
            return window.configObj.downloadUrl
        }
    },
    methods: {
        goToDownload() {
            window.location.href = window.configObj.downloadUrl;
        },
        goToH5() {
            window.location.href = location.origin + "/h5/";
        },
    }
}    
</script>

<style lang="scss" scoped>
    
.items-center {
    align-items: center;
}    
    
.download-area{
  padding: 60px 0;
  text-align: center;
  h2{
    margin-bottom: 40px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 72px;
    color:  #5D2146;
  }
  .sub-title {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    margin-top: 14px;
    color:  #91929E;
    margin-bottom: 40px;
  }
  .flex-box p{
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    text-transform: uppercase;
    color: #5D2146;
  }
  .part-icon{
    font-size: 45px;
    margin-bottom: 20px;
    color: #0088ff;
    .fa{
      font-weight: 900;
    }
  }
  .single {
    text-align: center;
    padding: 40px 30px;
    margin-bottom: 30px;
    transition: all 0.3s ease-in;
    box-shadow: 0px 0px 9px 2px #f5f5f5;
    color: #666;
    font-size: 16px;
    min-height: 300px;
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    h3{
      color: #000050;
      margin-bottom: 16px;
      font-size: 24px;
      font-weight: 600;
    }
    p{
      line-height: 24px;
    }
    &:hover{
      box-shadow: 0px 0px 19px 12px #f5f5f5;
    }
  }
    p{
        font-size: 16px;
        margin-bottom: 40px;
    }
    .d-btn{
        display: block;
        // width: 214px;
        // height: 58px;
        margin: 20px auto !important;
    }
}
@media screen  and (max-width: 1200px) {
    .imgarea {
        display: none;
    }
    .flex-box {
        flex-direction: column;
    }
    .download-area{
      padding-top: 0;
    }
    .download-area .v-container h2{
       font-size: 36px;
       margin-bottom: 15px;
    }
}
</style>