import EventMap from "./EventMap.js";
import utils from '@/utils/misc.js'
import {
	encodeToBuffer,
	decodeToObject
} from "./ProtobufUtil";

/**
 * webSocket的基础封装
 * 本类定位于解决webSocket的各种问题，由于是基础封装，所以本类仅包含以下功能
 * 1、与服务器的链接、断开、异常捕获和接收及发送数据
 * 2、链接状态维护
 */
export default class SocketAPI {
	static s_instance;

	m_webSocket;
	/**
	 * WebSocket连接状态
	 */
	m_state = WebSocketStateEnum.CLOSED;

	static get instance() {
		if (SocketAPI.s_instance == null) {
			SocketAPI.s_instance = new SocketAPI();
		}
		return SocketAPI.s_instance;
	}

	onConnected = (event) => {
		this.m_state = WebSocketStateEnum.CONNECTED;
		utils.emit('ws_open', event)
		// console.log("<===与WebSocket服务器链接成功===>", event);
	};

	onSocketData = (event) => {
		// console.log("<===收到服务端数据(buffer)===>", event.data);
		const resData = decodeToObject(event.data);
		// console.log("<===收到服务端数据(object)===>", resData);
		utils.emit(EventMap[resData.action], resData)
	};

	onIOError = (event) => {
		// console.log("<===与WebSocket服务器链接失败===>", event);
		this.m_state = WebSocketStateEnum.CLOSED;
		utils.emit('ws_error', event)
	};

	onClosed = (event) => {
		// console.log("<===与WebSocket服务器断开链接===>", event);
		this.m_state = WebSocketStateEnum.CLOSED;
		utils.emit('ws_close', event)
	};
	/**
	 * 发送数据
	 * @param  {egret.ByteArray} data
	 */
	sendData(data) {
		// console.log("<===发送数据(object)===>", data, 'time: ', new Date().getTime());
		const wsData = encodeToBuffer(data);
		this.m_webSocket.send(wsData)
	}

	/**
	 * 连接服务器
	 */
	connect(url) {
		this.m_state = WebSocketStateEnum.CONNECTING;
		let webSocket = new WebSocket(url);
		// let webSocket = new WebSocket('ws://192.168.31.103:8082/ws/kline');
		webSocket.binaryType = WebSocketTypeEnum.TYPE_BINARY;
		webSocket.addEventListener("open", this.onConnected);
		webSocket.addEventListener("message", this.onSocketData);
		webSocket.addEventListener("error", this.onIOError);
		webSocket.addEventListener("close", this.onClosed);
		this.m_webSocket = webSocket;

	}
	/**
	 * 断开与服务器的连接
	 */
	close() {
		this.m_state = WebSocketStateEnum.CLOSING;
		this.m_webSocket.close();
		// this.removeAllListener();
	}
	/**
	 * 获取当前链接状态
	 */
	get state() {
		return this.m_state;
	}
}

/**
 * CONNECTING   正在尝试连接服务器
 * CONNECTED    已成功连接服务器
 * CLOSING      正在断开服务器连接
 * CLOSED       已断开与服务器连接
 */
export const WebSocketStateEnum = {
	CONNECTING: 0,
	CONNECTED: 1,
	CLOSING: 2,
	CLOSED: 3
}

/**
 * TYPE_STRING 以字符串格式发送和接收数据
 * TYPE_BINARY 以二进制格式发送和接收数据
 */
export const WebSocketTypeEnum = {
	TYPE_STRING: "string",
	TYPE_BINARY: "arraybuffer"
}
