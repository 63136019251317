export default {
	20001: 'REC_SPOT_TICKERS', // 20001-服务端推送现货行情数据
	20002: 'REC_SPOT_KLINE', // 20002-服务端推送现货K线数据
	20003: 'REC_FUTURE_KLINE', // 20003-服务端推送期货K线数据
	20006: 'REC_FUTURE_TICKERS', // 20001-服务端推送期货行情数据
	20010: 'REC_DEPTH_INFO', // 20009-服务端推送20档挂单数据(期货)
	20007: 'REC_DEPTH_INFO', // 20007-服务端推送20档挂单数据
	10002: 'REC_SPOT_KLINE', // 20002-服务端推送现货K线数据
	10003: 'REC_FUTURE_KLINE', // 20003-服务端推送期货K线数据
	10004: 'REC_SPOT_REALTIME', // 20004-服务端推送现货分时数据
	10008: 'REC_FUTURE_REALTIME', // 20008-服务端推送期货分时数据
	20004: 'REC_SPOT_REALTIME', // 20004-服务端推送现货分时数据
	20008: 'REC_FUTURE_REALTIME', // 20008-服务端推送期货分时数据
}