<template>
<div>
  <Dialog @onClosed="onClosed">
    <div slot="head">{{$t(1039)}} {{ dialogData.name }}</div>
    <div slot="body">
      <div v-loading="isLoading" class="content-nobg">
        <div class="financial-buy-area">
          <div class="buy-product">
            <div class="buy-row">
              <div>
                <span>{{$t("nav4")}} {{ dialogData.time }}</span
                ><br />
                <span>{{ dialogData.time }} {{$t(1024)}}</span>
              </div>
              <div>
                <span>{{$t(1025)}}</span><br />
                <span>{{ dialogData.price }}</span>
              </div>
            </div>
            <div class="buy-row green">
              <div>
                <span>{{$t(1026)}}</span><br />
                <span>{{$t(1027)}}</span>
              </div>
              <div>
                <span>{{$t(1028)}}</span><br />
                <span>{{$t(1029)}}</span>
              </div>
            </div>
          </div>

          <div class="user-info">
            <div class="item">
              <p>{{$t(1030)}}({{dialogData.coin}})</p>
              <p>{{ userAssetsAvailableAmount }}</p>
            </div>
            <div class="item">
              <p>{{$t(1031)}}({{dialogData.coin}})</p>
              <p>{{ totalRevenue }}</p>
            </div>
          </div>

          <div class="user-control">
            <div class="rowText">
              <span>{{$t(1028)}}</span>
              <span class="pointer el-icon-sort" @click="handletransfer">{{$t(1032)}}</span>
            </div>
            <div class="count">
              <el-input v-model="enterNum" type="number" clearable />
              <span
                >{{dialogData.coin}} |
                <span @click="all" class="pointer">{{$t(1033)}}</span>
              </span>
            </div>
            <div class="rowText color666">
              <span
                >{{$t(1034)}}({{dialogData.coin}})：{{ dialogData.minAmount }} -
                {{ dialogData.maxAmount }}</span
              >
              <span>{{$t(1035)}}：{{ productDetail.feeRatio }}%</span>
            </div>
            <div class="rowText">
              <el-checkbox v-model="checkRules"
                >{{$t(1036)}}
                <span @click="goToAbout" class="pointer"
                  >《{{$t(1037)}}》</span
                >
              </el-checkbox>
            </div>

            <div class="sentBtn-area">
              <el-button :loading="isLoading" :class="['un-sentBtn', { sentBtn: allSure }]" @click="sent">
                {{$t(1038)}}
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="foot"></div>
  </Dialog>
  <AboutDialog  v-model="aboutDialog" />
  </div>
</template>

<script>
import {
  getUserAssetsByCoin,
  financialApply,
  financialBuyProducts,
  getProductDetail,
} from "@/api";
import AboutDialog from "./AboutDialog"
import Dialog from "../components/Dialog";
export default {
  name: "Financial",
  props: ["dialogData"],
  components: { Dialog,AboutDialog },
  data() {
    return {
      isLoading: false,
      aboutDialog: false,
      checkRules: true,
      enterNum: '',
      hadSwich: 0,
      cateList: [],
      products: [],
      wealthStatus: this.$store.state.userInfo.wealthStauts,
      productDetail: {}
    };
  },
  computed: {  
    userAssetsAvailableAmount() {
      return this.$store.state.assets.assets.WEALTH.coins.find(t => t.coin == this.dialogData.coin).availableAmount || ""
    },  
    totalRevenue() {
      let profit = 0
      const amount = this.enterNum
      const { ratio, circleDays } = this.dialogData
      const { feeRatio, coin } = this.productDetail || {}

      if (!coin) {
        return
      }
      profit = amount * ((ratio / 1000) * (1 - feeRatio / 100)) * circleDays
      if (coin === 'USDT') {
        // USDT 展示两位小数
        profit = Math.floor(profit * 100) / 100
        profit = profit.toFixed(2)
      } else {
        profit = Math.floor(profit * 10000) / 10000
        // 其他展示4位小数
        profit = profit.toFixed(4)
      }

      return profit

      // if (this.enterNum) {
      //   //预计收益=购买金额X收益率X托管天数X(1-手续费)  
      //   console.log('this.productDetail', this.productDetail)
      //   return (this.enterNum * (this.dialogData.ratio/10)/100 * this.dialogData.circleDays * (1-0.005)).toFixed(2)
      // } else {
      //     return 0
      // }
    },
    allSure() {
      if (this.checkRules && this.enterNum >= this.dialogData.minAmount &&
        this.enterNum <= this.dialogData.maxAmount) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    if (window.configObj.modulePledgeNeedApply === '1' && this.wealthStatus !== 1) this.showMessage()
    // 合併暫存資料
    if ( this.dialogData.enterNum) {
      this.enterNum = this.dialogData.enterNum
      this.checkRules = this.dialogData.checkRules
    }
  },
  beforeMount(){
    // 拉取产品详情
    this.getProductDetail()
  },
  methods: {
    getProductDetail() {
      this.isLoading = true
      getProductDetail(this.dialogData.id).then(res => {
        this.productDetail = res
      }).finally(() => {
        this.isLoading = false
      })
    },
    handletransfer() {
      const payload = {
        dialogData: { 
        ...this.dialogData,
        enterNum: this.enterNum,
        checkRules: this.checkRules,
        }
      };
      this.$emit("save", payload);
      this.onClosed();
      this.$router.push({name: 'Transfer', query: {to: 'WEALTH', from: 'SPOT'}});
    },
    all() {
      this.enterNum = this.userAssetsAvailableAmount;
    },
    goToAbout() {
      this.aboutDialog = true
    },
    getAvailableAmountData() {
      this.isLoading = true;
      const queryData = {
        category: "WEALTH",
        coin: this.dialogData.coin,
      };
      getUserAssetsByCoin(queryData).then((res) => {
        this.userAssetsByCoinData = res;
        this.isLoading = false;
      });
    },
    onClosed() {
      this.$emit("onClosed", this.isShown);
    },
    sent() {
      if (!this.allSure) return;
      const queryData = {
        amount: Number(this.enterNum),
      };
      this.isLoading = true;
      financialBuyProducts(this.dialogData.id, queryData)
        .then((res) => {
          this.$message.success(this.$t(1062));
          this.$emit("onClosed", this.isShown);
          this.$store.dispatch('getUserAssets')
          this.isLoading = false;
        })
        .finally(() => {
          this.isLoading = false;                 
        })
    },
    showMessage() {
      const h = this.$createElement;
      this.$msgbox({
        title: this.$t(1067),
        customClass: "financial-confirmWarning",
        message: h("p", null, [
          h(
            "h4",
            null,
            this.$t(1068)
          ),
          h(
            "a",
            {
              on: {
                click: () => {
                  this.goToAbout();
                },
              },
            },
            [
              h("span", null, this.$t(1069)),
              h(
                "span",
                { style: { color: "$primaryColor" } },
                 this.$t(1070)
              ),
            ]
          ),
        ]),
        showCancelButton: true,
        showClose: false,
        closeOnClickModal: false,
        confirmButtonText: this.$t(1071),
        cancelButtonText: this.$t(1072),
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonDisabled = true;
            instance.confirmButtonText = this.$t(1073);
            financialApply()
              .then(() => {
                this.$message.success(this.$t(1062));
                this.$msgbox.close();
                this.onClosed();
              })
              .catch(() => {
                this.$msgbox.close();
                this.onClosed();
              })
              .finally(() => {
                instance.confirmButtonLoading = false;
                instance.confirmButtonDisabled = false;
                done();
              });
          } else {
            this.$msgbox.close();  
            this.onClosed();
            done();
          }
        },
      });
    },
  },
};
</script>

<style lang="scss">
.financial-buy-area {
  padding-top: 20px;
  font-size: 14px;
  .buy-product {
    margin: 0 20px;
    border: 1px solid $borderColor;
    border-radius: 20px;
    .buy-row {
      border-radius: 0 0 20px 20px;
      display: flex;
      justify-content: space-between;
      padding: 20px;
      &.green {
        background: #f6f6f6;
      }
    }
  }
  .user-info {
    padding: 20px 50px;
    display: flex;
    justify-content: space-between;
    .item {
      text-align: center;
    }
  }
  .user-control {
    background: #f6f6f6;
    display: flex;
    flex-wrap: wrap;
    .rowText,
    .count {
      padding: 10px 20px;
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
    }
    .count {
      background: #fff;
      .el-input {
        width: 630px;
        font-size: 25px;
        .el-input__inner {
          border: 1px solid $borderColor;
          &:hover{
            border-color: $primaryColor;
          }
        }
      }
      .num {
        font-size: 25px;
        font-weight: 800;
        color: #999;
      }
    }
    .sentBtn-area {
      padding: 10px 20px;
      width: 100%;
      display: inline-flex;
      justify-content: center;
      .un-sentBtn {
        max-width: 400px;
        text-align: center;
        color: #fff;
        border-radius: 10px;
        padding: 10px 0;
        background: rgba(26, 187, 151, 0.3);
        width: 100%;
        margin: 20px 0;
      }
      .sentBtn {
        background: $primaryColor;
      }
    }
  }
  .el-checkbox__label {
    font-size: 12px;
  }
}
.financial-confirmWarning {
  .el-message-box__title {
    text-align: center;
  }
  .el-message-box__btns {
    display: flex;
    // flex-wrap: wrap;
    flex-direction: column-reverse;
    button {
      margin: 0 0 5px 0;
      margin-left: 0 !important;
    }
  }
}
// @media (max-width: 1200px) {

// }
</style>