import twLocale from 'element-ui/lib/locale/lang/zh-TW'
export default {
    ...twLocale,
    1127: '下載',
    1128: '登陸',
    1129: '休市',
    1130: '幣種',
    1131: '價格',
    1132: '改變',
    1133: '圖表',
    1134: '詳情',
    1135: '立即創建您的加密貨幣投資組合',
    1136: '{appName}具有多種功能，使其成為開始交易的最佳場所',
    1137: '管理您的投資組合',
    1138: '買賣流行的數字貨幣，在一個地方跟踪它們',
    1139: '經常性購買',
    1140: '通過每天、每週或每月安排購買來慢慢投資加密貨幣',
    1141: '保險庫保護',
    1142: '為了增加安全性，請將您的資金存放在保險庫中，並延遲提款',
    1143: '移動應用',
    1144: '使用適用於 Android 或 iOS 的 {appName} 應用程序保持市場領先地位',
    1145: '雖然在異國貨幣對上進行槓桿交易很棒，但擁有一個充滿 meme 和 alpha 的 Discord 服務器會更好',
    1146: '聯繫我們',

    11081: '精英交易平台：保持交易快速、穩定、高效',
    11031: '支持多種PC和移動操作系統',
    1126: '交易平台',
    11261: '最受歡迎的外匯交易平台',
    11191: '隨時隨地開啟交易',
    nav0: '首頁',
    nav1: '行情',
    nav2: '幣幣交易',
    nav3: '合約交易',
    nav3_2: '合約交易',
    nav3_3: '合約帳戶',
    nav4: '理財',
    nav5: '條款說明',
    nav6: '用戶協議',
    nav7: '隱私條款',
    nav8: '法律申明',
    nav9: '交易規範',
    nav10: '新手幫助',
    nav11: '常見問題',
    nav12: '交易費率',
    nav13: '關於我們',
    nav14: '服務',
    operate: '操作',
    homeTitle_1_1: '全球領先的數字資產交易平台',
    homeTitle_1_2: '最受歡迎的數字資產交易平台，獲得市場上最強大，最便捷的技術分析和交易工具。 ',
    register: '立即註冊',
    nowtrans: '立即交易',
    logIn: '登錄',
    whats: 'Whats',
    service: '在線客服',
    home_1_2: 'Carrod Securities是您值得信賴的外匯',
    home_1_3: '貴金屬，期貨產品平台',
    home_2_1: '客戶資金安全的存放在全球AA級銀行隔離信託基金',
    home_2_2: '更低的點差更穩定的服務器',
    footer1: `風險提示: 公司提供的金融產品包括期貨產品和其他復雜的金融產品。交易期貨產品具有很高的風險，因為槓桿可以同時對您有利和不利。因此，期貨產品可能並不適合所有投資者，因為有可能會損失您所有的投資本金。您永遠不應投資承受不起損失的資金。在交易提供的複雜金融產品之前，請確保了解所涉及的風險。您被授予有限的非排他性非轉讓權，僅出於與網站上提供的服務有關的個人和非商業目的使用本網站上提供的IP。`,
    footer2: '請注意此網站上的信息不針對任何與網站上敘述的法律和法規有出入的國家或司法管轄區的居民.Carrod Securities Co Ltd並非財務顧問，向您提供的僅為一般性建議，不會就保證金交易的獲取，持有或拋售出具任何意見或建議.',
    1: '產品名稱',
    2: '預期日化收益率',
    3: '產品期限',
    4: '起投金額',
    5: '操作',
    6: '註冊&購買',
    7: '餘幣理財',
    8: '靈活理財，天天有收益',
    9: '關於我們',
    10: '用戶協議',
    11: '隱私條款',
    12: '法律申明',
    13: '交易規則',
    14: '條款說明',
    15: '服務',
    16: '新手幫助',
    17: '常見問題',
    18: '交易費率',
    19: '數字貨幣秒合約交易系統',
    20: '快速、便捷、無分攤、風險可控',
    21: '系統介紹',
    22: '隨著數字貨幣期貨的快速發展,廣大用戶持有資產越來越多，虛擬貨幣的交易方式也變的多種多樣。用戶也越來越追求快速，高效的交易結算方式，為此，秒合約也受到了 極大的推崇。互融雲推出的秒合約是一款用於虛擬貨幣合約交易的以分為單位進行結算的交易系統。系統支持市價交易和限價交易；用戶可買入看漲、看跌期權，資金投入量小、門檻低，結算週期短，分為1分鐘、2分鐘、3分鐘等，系統可自主配置。系統具有嚴密的風控體系，設置最高、最低漲跌幅，超出限制，賠率自動歸零。',
    23: 'K線來源',
    24: '交易主要依靠外部交易所大盤行情進行交易',
    25: '支持幣種',
    251: '入金方式',
    252: '充幣',
    26: '通過錢包地址進行充值',
    27: '通過C2C交易進行購買',
    28: '滿足平台發放幣的需求',
    281: '通過OTC交易進行購買，',
    282: '平台無需承擔貨幣浮動風險',
    29: '合約模型設計',
    30: '交易方式',
    31: '結算時間設置',
    32: '交易方向',
    33: '交易控制',
    34: '風險控制',
    35: '買入額度限制',
    36: '防止客户大規模虧損',
    38: '設置截止下單時間',
    39: '保證足夠的清算時間進行開獎',
    40: '設置漲幅線、跌幅線',
    41: '觸發賠率清零，防止單邊交易',
    42: '避免大規模虧損',
    43: '一键平仓按钮',
    44: '客户可在极端情况下一键平仓按钮',
    46: '服務監控',
    47: '服務全面監控，保障服務故障及時響應，及時發起故障郵件通知',
    48: '服務支持',
    49: '我們用心走近客戶，我們熱心、專心、耐心、細心，我們擁有專業的軟件研發團隊、嚴謹的平台監控管理、專業的一站式服務團隊。',
    50: '一, 選擇交易對',
    51: '二, 查看價格',
    52: '看市場成交價格，選擇合適價格下單。',
    53: '三, 填寫訂單後買入',
    54: '選擇需要購買的交易對（幣兌幣交易就是指用一種數字資產去兌換另一種數字資產）',
    55: '填寫買入價和買入量，等待市場成交。',
    57: '秒懂幣幣交易',
    58: '安全可靠 交易迅速',
    59: '交易行情',
    60: '现货',
    601: '向全球投资者提供先进的数字资产、差价合约和商品交易平台',
    61: '什么是现货？',
    62: '现货是对于期货而言。现货和期货大同小异，主要区别在于杠杆及保证金、交易交割时间等差异',
    63: '期货',
    64: '什么是期货?',
    65: '期货合约，简称期货，是一种跨越时间的交易方式。买卖双方透过签订合约，同意按指定的时间、价格与其他交易条件，交收指定数量的现货。',
    66: '信托理财',
    67: '什么是信托理财?',
    68: '去中心化金融是一种基于区块链的金融，它不依赖券商、交易所或银行等金融机构提供金融工具，而是利用区块链上的智能合约进行金融活动',
    69: 'C2C',
    70: '什么是C2C',
    71: 'C2C，也称“私对私”是一种交易的方式，它是指个人对个人的交易形式',
    72: '一站式交易服務',
    73: '名稱',
    74: '價格',
    75: '24H漲跌',
    76: '24H成交量',
    77: '行情',
    78: '穩定 安全 聚合',
    79: '金融級安全防護',
    80: '無偏性零知識證明隱私保密技術保護用戶隱私，多種簽名、分層架構等安全設計。',
    81: '高性能撮合引擎',
    82: '採用先進的分佈式集群架構及微服務開發方式 。支持超過300萬/秒交易並發。',
    83: '24小時不間斷服務',
    84: '客服七天24小時全天在線，為你解答各種問題。',
    85: '隨時隨地 開啟交易',
    86: '全新改版，隨時滿足各種場景的交易需求',
    87: '24*7小时全天候、全日制运营模式，客服第一时间为您解答相关咨询。',
    88: '交易對',
    89: '最新價',
    90: '漲跌幅',
    91: '最高價',
    92: '最低價',
    93: '成交量',
    94: '登錄',
    95: '註冊',
    fee: {
        1: '交易費率',
        2: '幣幣交易手續費',
        3: '合約交易手續費'
    },
    law: {
        1: '法律申明',
        2: '第一條',
        3: '本平台致力於為用戶創建一個合規、安全、可靠的數字資產交易環境，禁止用戶使用本網站從事洗錢、走私、商業賄賂、惡意操縱市場等一切非法交易活動或違法行為。為此我們盡最大努力製定嚴格的反洗錢反恐怖方案及採取有效的安全風險控制措施，以最大限度地保障用戶的合法權益，並使平台自身維持著一個良好的品牌聲譽',
        4: '第二條',
        5: '當有權機關出示相應的調查文件要求本網站配合對指定用戶進行調查時， 或對用戶賬戶採取查封、凍結或者劃轉等措施時，本網站將在核查司法文件的有效性和真實性後，按照經平台判定的有權機關的要求協助提供相應的用戶數據，或進行相應的操作，因此造成的用戶隱私洩露、賬戶不能操作及由此給用戶所造成的其他損失等，本網站不承擔任何責任。 ',
        6: '第三條',
        7: '凡使用本網站服務的用戶，在交易前均應知悉：數字資產不以任何政府或中央銀行為後盾支持，且數字資產價格波動大，持有或使用數字資產具有較高的風險。在作出交易決策前，您應理性審慎自身的財務狀況以判斷是否適合您',
        8: '第四條',
        9: '凡以任何方式登錄本網站或直接、間接使用本網站服務者，視為自願接受本網站聲明的約束。 '
    },
    rules: {
        1: '交易規則',
        2: '創建訂單後，買方應當：',
        3: '1、 在訂單規定的付款時間內完成支付，再點擊“我已付款”；',
        4: '2、 使用本人實名付款方式、按訂單金額及訂單指定的收款賬戶以實時到賬方式完成訂單支付；',
        5: '3、 選擇非實時到賬方式支付訂單的，必須事先獲得賣方同意',
        6: '4、 支付訂單不得備註“數字貨幣、BTC、比特幣”等信息；',
        7: '賣方應遵循的原則',
        8: '生成訂單後，賣方應當',
        9: '1、 提供經平台認證的收款賬戶；',
        10: '2、 收到買方全額付款後，在訂單規定時間內完成放行；',
        11: '3、 非經買方同意，不得要求買家/客服取消訂單；',
        12: '4、 非經買方同意，不得對已創建的訂單價格提出異議；'
    },
    help: {
        1: '新手幫助',
        2: '限價單',
        3: '一種以指定價格或更優價格買入/賣出的訂單。 ',
        4: '市價單',
        5: '一種以當前市價立即執行買入或賣出的訂單。 ',
        6: '限價止損單',
        7: '一種在市場達到特定價格後，以設定價格賣出或平倉的訂單，用於控制損失。 ',
        8: '限價止盈單',
        9: '類似於限價止損單，同樣在市場達到特定價格後，以設定價格賣出或平倉的訂單，用於控制盈利。 ',
        10: '市價止損單',
        11: '一種在市場達到特定價格後，以市價賣出或平倉的訂單，用於控制損失。 ',
        12: '市價止盈單',
        13: '類似於市價止損單，同樣在市場達到特定價格後，以市價賣出或平倉的訂單，用於控制盈利。 ',
        14: '什麼是開倉保證金？ ',
        15: '保證金交易即你只需要支付一定比例的保證金，就可以買賣合約，根據選擇的槓桿倍數，保證金比例為合約價值的更少倍數比例。他能放大您的收益，同時也可能使您遭受額外的風險。 ',
        16: '什麼是爆倉什麼是穿倉？ ',
        17: '當您賬戶的剩餘資金小於交易合約所需要的浮動盈虧時，您的倉位會被交易所強行平倉，又稱爆倉。由於平倉時的價格，是根據市場價所決定的，當成交價格極為不利時，可能您的資金不足以支付交易產生虧損，導致您的賬戶為負值，這種情況稱為穿倉。 '
    },
    96: '選擇交易對',
    97: '查看價格',
    98: '填寫訂單後購買',
    99: '賬戶登錄',
    100: '歡迎回來！使用您的電子郵箱或電話號碼登錄',
    101: '郵箱',
    102: '手機',
    103: '忘記密碼？ ',
    104: '立即註冊',
    105: '密碼',
    106: '請輸入手機號碼',
    107: '手機號格式錯誤',
    108: '請輸入郵箱',
    109: '郵箱格式錯誤',
    110: '請輸入密碼',
    111: '密碼最少6位',
    112: '密碼最多32位',
    113: '語言和地區',
    114: '創建{appName}賬戶',
    115: '使用您的電子郵箱或手機號進行註冊',
    116: '推薦碼',
    117: '同意',
    118: '和',
    119: '用戶協議',
    120: '隱私政策',
    121: '註冊過？ ',
    122: '驗證碼',
    123: '發送',
    124: '請輸入驗證碼',
    125: '驗證碼必須為6位數字',
    126: '重置密碼',
    127: '確定',
    128: '使用您的電子郵箱或手機重置登錄密碼',
    129: '實名認證',
    130: '完成實名認證有助於保護賬戶安全，提高提現額度及交易權限',
    131: '去認證',
    132: '身份驗證',
    133: '用於登錄、提幣、找回密碼、修改安全設置',
    134: '已驗證',
    135: '密碼管理',
    136: '登錄密碼',
    137: '資金密碼',
    138: '用於保護賬戶安全',
    139: '用於保護資金安全',
    140: '個人設置',
    141: '顏色偏好',
    142: '設置',
    143: '修改',
    144: '設定K線圖、買漲、買跌的顏色',
    145: '計價方式',
    146: '賬戶資產的計價單位，',
    147: '默認',
    148: '綠漲紅跌',
    149: '紅漲綠跌',
    150: '修改登錄密碼',
    151: '修改資金密碼',
    152: '舊密碼',
    153: '新密碼',
    154: '請輸入舊密碼',
    155: '新手機號',
    156: '新郵箱',
    157: '綁定手機號',
    158: '綁定郵箱',
    159: '修改手機號',
    160: '修改郵箱',
    161: '已認證',
    162: '設置暱稱',
    163: '請輸入您的暱稱',
    164: '取消',
    165: '國家',
    166: '名',
    167: '姓',
    168: '證件類型',
    169: '證件號碼',
    170: '請選擇國家',
    171: '請輸入名',
    172: '請輸入姓',
    173: '請輸入證件號碼',
    174: '請上傳證件正面照片',
    175: '請上傳證件反面照片',
    176: '請上傳手持證件照片',
    177: '提交',
    178: '認證中',
    179: '身份證',
    180: '護照',
    181: '下一步',
    182: '上傳證件照片，完成認證',
    183: '上傳失敗',
    184: '圖片格式錯誤',
    185: '圖片過大',
    186: '加載中',
    187: '資產總覽',
    188: '劃轉',
    189: '提幣',
    190: '充幣',
    191: '財務記錄',
    192: '凍結',
    193: '可用',
    194: '總額',
    195: '隱藏小額幣種',
    196: '幣種',
    197: 'USDT估值',
    198: '搜索',
    199: '充值網絡',
    200: '充幣地址',
    201: '出幣地址',
    202: '充幣數量',
    203: '充幣截圖',
    204: '備註',
    205: '複製地址',
    206: '複製成功',
    207: '返回',
    208: '選填',
    209: '請輸入充幣數量',
    210: '請有效的數字',
    211: '請輸入出幣地址',
    212: '請上傳充幣截圖',
    213: '提幣地址',
    214: '提幣數量',
    215: '可用餘額',
    216: '全部',
    217: '手續費',
    218: '請輸入提幣數量',
    219: '請輸入資金密碼',
    220: '資金密碼必須是6位數字',
    221: '默認密碼',
    222: '您還未綁定對應錢包！ ',
    223: '去添加',
    224: '提交成功',
    225: '添加收款錢包',
    226: '地址',
    227: '請輸入網絡',
    228: '請輸入地址',
    229: '網絡',
    230: '提幣網絡',
    231: '地址長度最低32位',
    232: '資金劃轉',
    233: '劃轉數量',
    234: '請輸入劃轉數量',
    235: '餘額不足',
    236: '可劃轉',
    237: '劃轉方向',
    238: '劃轉',
    239: '從',
    240: '至',
    241: '財務記錄',
    242: '充幣記錄',
    243: '提幣記錄',
    244: '劃轉記錄',
    245: '數量',
    246: '狀態',
    247: '創建時間',
    248: '確認時間',
    249: '沒有更多了',
    250: '價格',
    253: '交易對',
    254: '漲跌幅',
    255: '暫無數據',
    256: '累計',
    257: '高',
    258: '低',
    259: '額',
    260: '開',
    261: '收',
    262: '量',
    263: '期貨交易',
    264: '看漲',
    265: '看跌',
    266: '產品名稱',
    267: '查看合約',
    268: '買入金額',
    269: '請輸入買入金額',
    270: '交易手續費',
    271: '最少買入',
    272: '狀態',
    273: '待交割',
    274: '已交割',
    275: '下單成功',
    276: '產品名稱',
    277: '盈虧',
    278: '買入價格',
    279: '交割價格',
    280: '繼續交易',
    283: '收益',
    284: '確認下單',
    285: '歷史訂單',
    286: '在持訂單',
    287: '創建時間',
    288: '買入',
    289: '賣出',
    290: '成交數量',
    291: '成交金額',
    292: '合約',
    293: '交割時間',
    294: '交割價格',
    295: '秒',
    296: '分',
    297: '時',
    298: '天',
    299: '剩餘時間',
    300: '查看更多',
    301: '幣幣交易',
    302: '賣出金額',
    303: '請輸入賣出金額',
    304: '法幣交易',
    305: '資產',
    306: '退出登錄',
    307: '註冊成功',
    308: '請輸入推薦嗎',
    309: '成功',
    310: '失敗',
    311: '確認中',
    312: '收款方式',
    313: '提幣地址',
    314: '刪除',
    315: '操作',
    316: '您的錢包',
    317: '確定刪除？ ',
    318: '刪除成功',
    319: '認證未通過',
    320: '重新認證',
    330: '數字資產提現錢包',
    331: '綁定銀行卡，提現到法幣',
    332: '請輸入有效的地址',
    1000: '天',
    1001: '進行中',
    1002: '已關閉',
    1003: '規則',
    1004: '產品優勢',
    1005: '隨存隨取',
    1006: '派息過期',
    1007: '天天分紅',
    1008: '活存利息',
    1009: '100%資金保證',
    1010: '節假日正常收益',
    1011: '次日起息',
    1012: '產品優勢',
    1013: '會員在平台存入了200000USDT，選擇週期為14天，日收益為0.5%的理財產品，則每天收益如下：200000*0.5%=1000USDT 即：14天后可以獲得14000USDT的收益，收益每日下發，存入本金，到期滿後，自動返還至您的帳戶。',
    1014: '託管訂單',
    1015: '託管中',
    1016: '已完成',
    1017: '已贖回',
    1018: '暫無數據',
    1019: '購買金額',
    1020: '日收益率',
    1021: 'TRUST.6',
    1022: '支付時間',
    1023: '贖回日期',
    1024: '過期',
    1025: '近日(日收益)',
    1026: '派息時間',
    1027: '每天',
    1028: '託管金額',
    1029: '到期返還',
    1030: '可用餘額',
    1031: '預計總收益',
    1032: '資金劃轉',
    1033: '全部',
    1034: '單筆限額',
    1035: '手續費',
    1036: '我已閱讀並同意',
    1037: '平台理財服務協議',
    1038: '確定',
    1039: '申購',
    1040: '購買',
    1041: '出售',
    1042: '電匯轉帳',
    1043: '請輸入金額',
    1044: '法幣類別',
    1045: '出售數量',
    1046: '最大可售',
    1047: '全部',
    1048: '參考單價',
    1049: '您的收款方式',
    1050: '銀行名稱',
    1051: '銀行帳號',
    1052: '姓名',
    1053: '您還沒有綁定相應的收款方式，無法出售。',
    1054: '訂單記錄',
    1055: '篩選',
    1056: '類型',
    1057: '狀態',
    1058: '處理中',
    1059: '通過',
    1060: '失敗',
    1061: '數量',
    1062: '成功',
    1063: '正在託管訂單',
    1064: '計今日收益',
    1065: '累計收益',
    1066: '託管中訂單',
    1067: '信託賬戶未開通',
    1068: '開通信託賬戶，享受穩定被動收益，讓資產增值更加簡單。',
    1069: '申請開通即表示您已閱讀並同意',
    1070: '《平台理財服務協議》',
    1071: '申請開通',
    1072: '取消',
    1073: '執行中...',
    1074: '贖回',
    1075: '確定要贖回嗎？',
    1076: '提前贖回需要支付違約金，根據您的理財類型，本次將扣除',
    1077: '，確認贖回嗎？',
    1078: '已發送',
    1079: '請輸入驗證碼',
    1080: '提示',
    1081: '收益',
    1082: '時間',
    1083: '添加法幣收款方式',
    1084: '訂單狀態',
    1085: '幣種',
    1086: '法幣數量',
    1087: '銀行賬號',
    1088: '法幣收款方式',
    1089: '添加收款方式',
    1090: '現有收款方式',
    1091: '法幣',
    1092: '銀行編碼',
    1093: '確定刪除收款方式？',
    1094: '請輸入銀行帳號',
    1095: '請輸入銀行名稱',
    1096: '請輸入法幣',
    1097: '請選擇',
    1098: '請輸入姓名',
    1099: '分行名',
    1100: '無限制',
    1101: '您尚未實名認證',
    1102: '白皮書',
    1103: '我們的特點',
    1104: '我們很安全',
    1105: '我們很強大',
    1106: '最佳支持',
    1107: '我們是全球性的',
    1108: '它是如何工作的',
    1109: '20多個國家和地區的用戶在使用我們的平台並得到廣泛認可',
    1110: '高級數據加密',
    1111: '種類繁多的加密貨幣',
    '1111_2': '種類繁多的貴金屬與加密貨幣',
    1112: '列出了 90 多種加密貨幣',
    1113: '您的交易數據通過端到端加密得到保護，確保只有您可以訪問您的個人信息。',
    1114: '430 億',
    1115: '24 小時交易量',
    1116: '三千萬',
    1117: '信任我們的註冊用戶',
    1118: '最低交易費用',
    1119: '下載',
    1120: '金融機構名稱',
    1121: '分支名稱',
    1122: '分公司號碼',
    1123: '帳號',
    1124: '收件人姓名',
    1125: '金城',

    1148: '最值得信賴的加密貨幣平台',
    1149: '以下是您應該選擇我們的理由',
    1150: '我們很安全',
    1151: '無偏的零知識證明隱私保密技術，保護用戶隱私，多重簽名，分層架構等安全設計 ',
    1152: '最佳支持',
    1153: '24*7小時全天候、全時運營模式，客服會第一時間回复您的相關諮詢',
    1154: '我們是全球性的',
    1155: '20多個國家和地區的用戶在使用我們的平台並得到廣泛認可',
    1156: '我們很強大',
    1157: '最受歡迎的數字資產交易平台，接入市面上最強大便捷的技術分析和交易工具',
    1158: '豐富多樣的加密貨幣',
    1159: '列出了 90 多種加密貨幣',
    1160: '高級數據加密',
    1161: '您的交易數據通過端到端加密得到保護，確保只有您可以訪問您的個人信息。',
    1162: '我們支持多種最流行的數字貨幣',
    1163: '許多國家建立了本地化交易',
    1164: '使用法幣購買數字資產，快速進入數字資產世界',
    1165: '隨時隨地進行交易',
    1166: '掃我下載',
    1167: '匯率',
    1168: '通過最近上傳的匯率買賣 250 多種加密貨幣',
    1169: '使用我們的應用程序和桌面客戶端隨時了解情況',
    // 1147:'我們是 lorem ipsum dolor amet, consectetur adipiscing elit'
    1170: '姓名',
    1171: '價格',
    1172: '改變',
    1173: '圖表',
    1174: '貿易',
    1175: '細節',
    1176: '我們是',
    1177: '安全的',
    1178: '我們有',
    1179: '全球的',
    1180: '強大的',
    1181: '豐富的品種',
    1182: '加密貨幣',
    1183: '高級數據',
    1184: '加密',
    1185: '準備開始',
    1186: '我們是全球領先的數字資產交易平台。',
    1187: '我們是全球領先的數字資產交易平台。 我們不斷努力改進安全功能，以確保客戶數字資產的安全。 我們的模式是為客戶帶來具有可持續價值的項目。',
    1188: '總供應量',
    1189: '24*7全天候、全時運營模式，客服第一時間為您解答相關諮詢',
    1190: '我們是全球性的',
    1191: '我們是強大的',
    1192: '最受歡迎的數字資產交易平台，獲得市場上最強大、最便捷的技術分析和交易工具。',
    1193: '種類繁多的加密貨幣',
    1194: '您的交易數據受到端到端加密的保護，確保只有您可以訪問您的個人信息。',
    1195: '最低交易費用',
    1196: '相信我們的註冊用戶',
    1197: '24小時交易量',
    1198: '+420 億',
    1199: '+3000 萬',
    1200: '多個國家設立本地化交易服務中心，打造融合多種業態的區塊鏈生態系統 ',
    1201: '使用法幣購買數字資產，快速進入數字資產世界',
    1202: 'APP下載',
    1203: '我們的優勢',
    1204: '豐富的幣種和幣種信息，提供幣種、合約、法幣交易等多種交易方式，專業的行情和深度。'
}
