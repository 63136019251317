import axios from 'axios'
import { Message } from 'element-ui'
import { refreshToken } from '../api'
import router from '../router'
import utils from '@/utils/misc'
let isRefreshing = false
let requests = []
// create an axios instance
const baseUrl = '/api'

// const baseUrl = '/api'
const service = axios.create({
  baseURL: baseUrl, // url = base url + request url
  timeout: 60000 // request timeout
})
const notErorringCode = [
  10030009
]
// request interceptor
service.interceptors.request.use(
  config => {
    const agentIdentify = utils.getLs('agentIdentify')
    if (agentIdentify) {
      // fb8d5144ca1b424b9e9443e5b723c34b
      config.headers['Agent-Identify'] = agentIdentify
    }
    const token = utils.getLs('token')
    if (token) {
      config.headers['Authorization'] = token
    }
    config.headers['Lang'] = utils.getLs('locale') || (window.configObj ? window.configObj.defaultLang : 'zh-EN')
    config.headers['Client-Version'] = '1.0.0'
    config.headers['Platform'] = navigator.platform
    config.headers['Device-Id'] = 'from official'
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(async response => {
  const res = response.data
  if (res.code !== 200) {
    if (response.config.url === '/refreshToken' || [900004,900005,900006,900007].includes(res.code)) { // 刷新token失败或者账号冻结，跳转登录页面
      utils.removeLs('token')
      router.push(`/login?redirect=${router.currentRoute.fullPath}`)
      throw new Error('server return error code:' + res.code)
    }
    if (res.code === 900003) { // token过期
      // if (!utils.getLs('token')) {
      //   // router.push(`/login?redirect=${router.currentRoute.fullPath}`)
      //   utils.removeLs('token')
      //   return
      // }
      response.config.url = response.config.url.replace(baseUrl, '')
      if (!isRefreshing) {
        isRefreshing = true
        if (!utils.getLs('token')) return
        const refreshObj = await refreshToken({ refreshToken: utils.getLs('refreshToken') }).finally(() => {
          isRefreshing = false
        })
        if (!refreshObj || !refreshObj.data || !refreshObj.data.token) {
          return
        }
        utils.setLs('token', refreshObj.data.token)
        axios.defaults.headers.common['Authorization'] = refreshObj.data.token
        response.config.headers['Authorization'] = refreshObj.data.token
        // 已经刷新了token，将所有队列中的请求进行重试
        requests.forEach(cb => cb(refreshObj.data.token))
        requests = []
        return service(response.config)
      } else {
        // 正在刷新token，将返回一个未执行resolve的promise
        return new Promise((resolve) => {
          // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
          requests.push((token) => {
            response.config.headers['Authorization'] = token
            resolve(service(response.config))
          })
        })
      }
    } else {
      if (notErorringCode.includes(response.data.code)) return res
      Message({
        message: res.msg,
        type: 'error',
        duration: 5 * 1000
      })
      throw new Error('server return error code:' + response.data.code)
    }
  } else {
    return res.data || res
  }
},
error => {
  // Message({
  //   message: error.message,
  //   type: 'error',
  //   duration: 5 * 1000
  // })
  return Promise.reject(error)
})

export default service
