<template>
  <div class="service">
    <iframe :src="url" frameborder="0" width="100%" height="700"></iframe>
  </div>
</template>

<script>
  import { AES_Decrypt } from '../utils/crypto'

  export default {
    name: "ServicePage",
    data() {
      return {};
    },
    computed: {
      url() {
        const QU = this.$route.query.url
        console.log(QU)
        console.log(AES_Decrypt)
        let u = AES_Decrypt(QU)
        console.log(u)
        return u
      },
    },
  };
</script>

<style lang="scss" scoped>
  .service {
    min-height: 700px;
  }
</style>
