<template>
    <section class="tp-banner-container">
        <div class="tp-banner"  :class="{'timex': isTimeX}">
            <div class="tp-caption active animated " v-animated="{ className: 'fadeInDown' }">
            <div class="logoBox">
                <h1>{{ $t("homeTitle_1_1") }}</h1>
                <div class="text-box">
                <h3 class="text-grey">{{ $t("homeTitle_1_2") }}</h3>
                </div>
                <div class="btn flex-nw">
                <a href="#" class="d-btn" @click="goToDownload">
                    <!-- <img src="@/assets/img/down_ios_active.png" alt="" /> -->
                    <span>IOS {{$t('1127')}}</span>
                </a>
                <a href="#" class="d-btn" @click="goToDownload">
                  <span>Android {{$t('1127')}}</span>
                    <!-- <img src="@/assets/img/down_android.png" alt="" /> -->
                </a>
                <a href="#" class="d-btn" @click="goToH5">
                  <span class="active">H5 {{$t('1128')}}</span>
                    <!-- <img src="@/assets/img/down_h5.png" alt="" /> -->
                </a>
                </div>
            </div>
            </div>
            <div v-if="isTimeX" class="timexBanner">
            <img src="@/assets/img/timex/banner.png" alt="">
            </div>
        </div>
    </section>
    
</template>

<script>
 export default {
    props: ['modle', 'isTimeX'],
    methods: {
        goToDownload() {
            window.location.href = window.configObj.downloadUrl;
        },
        goToH5() {
            window.location.href = location.origin + "/h5/";
        }
    }
 }   
</script>

<style lang="scss" scoped>
 
.tp-banner-container {
  max-height: none;
  background: url("~@/assets/img/home5/v5-home_bg.png") no-repeat ;
  background-size: 100%;
  height: auto;
  padding-bottom: 300px;
  .tp-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: none;
    max-width: 80vw;
    margin: 0 auto;
    background-size: 100%;
    overflow: hidden;
    // max-height: 824px;
    &:after {
      // position: absolute;
      // content: '';
      // left: 0;
      // top: 0;
      // width: 100%;
      // height: 100%;
      // z-index: -1;
      // background: #000050;
      // opacity: .85;
    }
    &.timex{
      background: none;
        display: flex;
      .tp-caption{
          flex:3;
          padding:0 10%;
          color: #fff;
          .logoBox .text-box h3{
            color: #fff;
          }
      }
        .timexBanner{
          flex:3;
          background-size: cover;
        }
    }
    .tp-caption {
      text-align: center;
      color: #fff;
      width: 100%;
      max-width: 90vw;
      padding: 150px 0 0;
      display: flex;
      justify-content: flex-end;
      .logoBox {
        text-align: left;
        max-width: 600px;
        h1 {
          font-family: 'Arial';
          font-style: normal;
          font-weight: 700;
          font-size: 60px;
          line-height: 69px;
          text-transform: uppercase;
          color: #5D2146;
          margin-bottom: 108px;
        }
        .text-box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          // height: 110px;
          max-width: 700px;
          margin-bottom: 130px;

          h3 {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            color: #AF9AAB;
          }
        }
        .btn {
          // width: 150px;
          display: flex;
          justify-content: space-between;
          .d-btn {
            display: inline-block;
        
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
              display: inline-block;
              width: 190px;
              height: 70px;
              border: 1px solid #E01697;
              border-radius: 22px;
              font-family: 'Arial';
              font-style: normal;
              font-weight: 700;
              font-size: 20px;
              line-height: 80px;
              color: #C21DD0;
              display: flex;
              justify-content: center;
              align-items: center;
              &.active,&:hover {
                background: linear-gradient(90deg, #E01697 0%, #C01ED5 100%);
                box-shadow: 0px 26px 24px -15px rgba(193, 30, 211, 0.502177);
                color: #fff;
              }
            }
            // margin-right: 15px;
          }
          
          // cursor: pointer;
          // border: 1px solid blue;
          // margin-right: 30px;
          // display: block;
          // img {
          //   margin-right: 30px;
          // }
        }
      }
    }
  }
}
.tp-banner-container-2 {
  max-height: none;
  height: 110vh;
  .tp-banner-2 {
    background: url("~@/assets/img/banner.png") no-repeat;
    background-size: 100% 116%;
    .logoBox-2 {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .left {
        text-align: left;
        width: 50%;
        h1 {
          font-size: 85px;
        }
        .text-box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 110px;
          margin-bottom: 50px;

          h3 {
            font-size: 45px;
            font-weight: 100;
            color: #fff;
          }
          &::before {
            position: absolute;
            content: "";
            transform: translate(-50px, -50px);
            width: 200px;
            height: 2px;
            border-top: 5px solid #fff;
          }
        }
        margin-right: 130px;
      }
      .btn {
        width: 20%;
        cursor: pointer;
        margin-right: 30px;
        img {
          margin: 0 30px 30px 0;
        }
      }
    }
  }
}
@media screen  and (max-width: 800px) { 
  .flex-nw {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;
    a {
      margin-top: 10px;
    }
  }
  .tp-banner-container{
    padding-bottom: 50px;
  }
  .tp-banner-container .tp-banner .tp-caption{
    padding-top: 230px;
    .logoBox h1{
      font-size: 36px;
      margin-bottom: 40px;
    }
    .logoBox .text-box{
      margin-bottom: 40px;
      h3{
        font-size: 20px;
      }
    }
  }
} 
</style>
