import viLocale from 'element-ui/lib/locale/lang/vi'
export default {
  ...viLocale,
  1127: 'tải xuống',
  1128: 'Đăng nhập',
  1130: 'Tiền tệ',
  1131: 'giá',
  1132: 'Thay đổi',
  1133: 'Đồ thị',
  1134: 'Chi tiết',
  1135: 'Tạo danh mục đầu tư tiền điện tử của bạn ngay bây giờ',
  1136: '{appName} có nhiều tính năng khiến nó trở thành nơi tốt nhất để bắt đầu giao dịch',
  1137: 'Quản lý danh mục đầu tư của bạn',
  1138: 'Mua và bán các loại tiền kỹ thuật số phổ biến và theo dõi tất cả chúng ở một nơi',
  1139: 'Mua định kỳ',
  1140: 'Đầu tư vào tiền điện tử một cách từ từ bằng cách sắp xếp các giao dịch mua hàng ngày, hàng tuần hoặc hàng tháng',
  1141: 'Bảo vệ kho quỹ',
  1142: 'Để tăng cường bảo mật, hãy giữ tiền của bạn trong kho tiền và trì hoãn việc rút tiền',
  1143: 'Ứng dụng di động',
  1144: 'Luôn dẫn đầu thị trường với ứng dụng {appName} dành cho Android hoặc iOS',
  1145: 'Mặc dù giao dịch bằng đòn bẩy trên các cặp ngoại lai là rất tốt, nhưng sẽ tốt hơn nếu có một máy chủ Discord chứa đầy meme và alpha',
  1146: 'Liên hệ với chúng tôi',

  11081: 'Nền tảng giao dịch ưu tú: Giữ giao dịch nhanh chóng, ổn định và hiệu quả',
  11031: 'Hỗ trợ nhiều PC và hệ điều hành di động',
  1126: 'Nền tảng giao dịch',
  11261: 'Nền tảng giao dịch ngoại hối phổ biến nhất',
  11191: 'Mở giao dịch mọi lúc, mọi nơi',
  nav0: 'Trang chủ',
  nav1: 'Báo giá',
  nav2: 'Giao dịch tiền xu',
  nav3: 'Giao dịch hàng hóa tương lai',
  nav3_2: 'Giao dịch hợp đồng',
  nav3_3: 'Tài khoản hợp đồng',
  nav4: 'quản lý tài chính',
  nav5: 'Mô tả Điều khoản',
  nav6: 'Thỏa thuận Người dùng',
  nav7: 'Chính sách Bảo mật',
  nav8: 'Tuyên bố pháp lý',
  nav9: 'Đặc điểm giao dịch',
  nav10: 'Trợ giúp thành viên mới',
  nav11: 'Câu hỏi thường gặp',
  nav12: 'Tỷ lệ giao dịch',
  nav13: 'Về chúng tôi',
  nav14: 'dịch vụ',
  operate: 'Hoạt động',
  homeTitle_1_1: 'Nền tảng giao dịch tài sản kỹ thuật số hàng đầu thế giới',
  homeTitle_1_2: 'Nền tảng giao dịch tài sản kỹ thuật số phổ biến nhất, tiếp cận với các công cụ giao dịch và phân tích kỹ thuật mạnh mẽ và thuận tiện nhất trên thị trường. ',
  register: 'đăng ký',
  nowtrans: 'Giao dịch ngay bây giờ',
  logIn: 'Đăng nhập',
  whats: 'WhatsApp',
  dservice: 'Dịch vụ',
  home_1_2: 'Chứng khoán Carrod là ngoại hối đáng tin cậy của bạn',
  home_1_3: 'Kim loại quý, nền tảng CFD',
  home_2_1: 'Các khoản tiền của khách hàng được lưu trữ an toàn trong quỹ tín thác tách biệt cấp AA của ngân hàng toàn cầu',
  home_2_2: 'Chênh lệch thấp hơn và máy chủ ổn định hơn',
  footer1: `Cảnh báo rủi ro: Các sản phẩm tài chính do công ty cung cấp bao gồm các hợp đồng chênh lệch ('CFD') và các sản phẩm tài chính phức tạp khác. Giao dịch CFD có mức độ rủi ro cao vì đòn bẩy có thể có lợi cho bạn và chống lại bạn. Do đó, CFD có thể không phù hợp với tất cả các nhà đầu tư vì có thể mất tất cả vốn đầu tư của bạn. Bạn không bao giờ nên đầu tư số tiền mà bạn không thể để mất. Trước khi giao dịch các sản phẩm tài chính phức tạp được cung cấp, vui lòng đảm bảo rằng bạn hiểu các rủi ro liên quan. Bạn được cấp một quyền hạn chế, không độc quyền, không thể chuyển nhượng để sử dụng IP được cung cấp trên trang web này chỉ cho các mục đích cá nhân và phi thương mại liên quan đến các dịch vụ được cung cấp trên trang web. `,
  footer2: 'Xin lưu ý rằng thông tin trên trang web này không hướng đến cư dân của bất kỳ quốc gia hoặc khu vực tài phán nào khác với luật và quy định được mô tả trên trang web. Carrod Securities Co Ltd không phải là cố vấn tài chính và chỉ cung cấp cho bạn lời khuyên chung , Không có ý kiến ​​hoặc khuyến nghị nào được đưa ra đối với việc mua lại, nắm giữ hoặc bán giao dịch ký quỹ. ',
  1: 'Tên sản phẩm',
  2: 'Tỷ suất sinh lợi hàng ngày mong đợi',
  3: 'Ngày hết hạn sản phẩm',
  4: 'Số tiền đầu tư tối thiểu',
  5: 'Hoạt động',
  6: 'Đăng ký & Mua hàng',
  7: 'Quản lý tiền còn lại',
  8: 'Quản lý tài chính linh hoạt, thu nhập hàng ngày',
  9: 'Về chúng tôi',
  10: 'Thỏa thuận người dùng',
  11: 'Chính sách Bảo mật',
  12: 'Tuyên bố pháp lý',
  13: 'Quy tắc giao dịch',
  14: 'Điều khoản và Điều kiện',
  15: 'Dịch vụ',
  16: 'Trợ giúp thành viên mới',
  17: 'Câu hỏi thường gặp',
  18: 'Tỷ lệ giao dịch',
  19: 'Hệ thống giao dịch hợp đồng thứ hai tiền tệ kỹ thuật số',
  20: 'Nhanh chóng, thuận tiện, không phân bổ, kiểm soát rủi ro',
  21: 'Giới thiệu hệ thống',
  22: 'Với sự phát triển nhanh chóng của hợp đồng tương lai tiền kỹ thuật số, phần lớn người dùng nắm giữ ngày càng nhiều tài sản, và các phương thức giao dịch của tiền ảo cũng trở nên đa dạng. Người dùng cũng ngày càng theo đuổi các phương thức giải quyết giao dịch nhanh chóng và hiệu quả. Vì lý do này, Hợp đồng thứ hai cũng đã được coi trọng rất nhiều. Hợp đồng thứ hai được đưa ra bởi đám mây dung hợp lẫn nhau là một hệ thống giao dịch cho các giao dịch hợp đồng tiền ảo được thanh toán bằng xu. Hệ thống hỗ trợ giao dịch theo giá thị trường và giao dịch theo giới hạn giá; người dùng có thể mua quyền chọn mua và đặt, với mức đầu tư vốn nhỏ, ngưỡng thấp và chu kỳ thanh toán ngắn, được chia thành 1 phút, 2 phút, 3 phút, v.v. Hệ thống có thể được cấu hình một cách độc lập. Hệ thống có hệ thống kiểm soát rủi ro chặt chẽ, thiết lập mức tăng và giảm giá tối đa và tối thiểu, nếu vượt quá giới hạn, tỷ lệ cược sẽ tự động trở về không. ',
  23: 'Nguồn dòng K',
  24: 'Giao dịch chủ yếu dựa vào báo giá thị trường ngoại hối',
  25: 'Đơn vị tiền tệ hỗ trợ',
  251: 'Phương thức gửi tiền',
  252: 'Tiền gửi tiền',
  26: 'Nạp tiền qua địa chỉ ví',
  27: 'Mua thông qua giao dịch C2C',
  28: 'Đáp ứng nhu cầu của nền tảng phát hành tiền xu',
  281: 'Mua qua giao dịch OTC,',
  282: 'Nền tảng không cần phải chịu rủi ro tiền tệ thả nổi',
  29: 'Thiết kế Mẫu Hợp đồng',
  30: 'Phương thức giao dịch',
  31: 'Thiết lập thời gian giải quyết',
  32: 'Hướng giao dịch',
  33: 'Kiểm soát giao dịch',
  34: 'Kiểm soát rủi ro',
  35: 'Giới hạn Mua',
  36: 'Ngăn chặn việc khách hàng mất tiền trên diện rộng',
  38: 'Đặt thời hạn đặt hàng',
  39: 'Đảm bảo đủ thời gian thanh toán để rút thăm',
  40: 'Thiết lập dòng, giảm dòng',
  41: 'Đặt lại tỷ lệ cược kích hoạt để ngăn các giao dịch đơn phương',
  42: 'Tránh thua lỗ lớn',
  43: 'Nút đóng một lần nhấp',
  44: 'Khách hàng có thể nhấp vào nút để đóng vị thế trong những trường hợp đặc biệt',
  46: 'Giám sát dịch vụ',
  47: 'Giám sát toàn diện các dịch vụ, đảm bảo phản ứng kịp thời với các lỗi dịch vụ và gửi email thông báo lỗi kịp thời',
  48: 'Hỗ trợ dịch vụ',
  49: 'Chúng tôi tiếp cận khách hàng một cách chu đáo, chúng tôi nhiệt tình, chu đáo, kiên nhẫn và cẩn thận, chúng tôi có đội ngũ R&D phần mềm chuyên nghiệp, giám sát và quản lý nền tảng nghiêm ngặt, và đội ngũ dịch vụ một cửa chuyên nghiệp. ',
  50: 'Một, chọn cặp giao dịch',
  51: 'hai, kiểm tra giá',
  52: 'Nhìn vào giá giao dịch thị trường và chọn mức giá thích hợp để đặt hàng. ',
  53: 'Ba, mua sau khi điền đơn đặt hàng',
  54: 'Chọn cặp giao dịch để mua (giao dịch tiền tệ sang tiền tệ đề cập đến việc trao đổi một tài sản kỹ thuật số lấy một tài sản kỹ thuật số khác)',
  55: 'Điền giá mua và khối lượng mua vào và đợi thị trường giao dịch. ',
  57: 'Hiểu giao dịch tiền tệ trong vài giây',
  58: 'Giao dịch an toàn và đáng tin cậy, nhanh chóng',
  59: 'Báo giá thương mại',
  60: 'Chỗ',
  601: 'Cung cấp tài sản kỹ thuật số tiên tiến, CFD và nền tảng giao dịch hàng hóa cho các nhà đầu tư toàn cầu',
  61: 'Điểm là gì? ',
  62: 'Giao ngay là tương lai. Hợp đồng giao ngay và hợp đồng tương lai tương tự nhau, sự khác biệt chính là sự khác biệt về đòn bẩy, ký quỹ, thời gian giao dịch, v.v. ',
  63: 'Hợp đồng tương lai',
  64: 'Hợp đồng tương lai là gì?',
  65: 'Hợp đồng tương lai, được gọi là hợp đồng tương lai, là một cách giao dịch theo thời gian. Bằng cách ký kết hợp đồng, người mua và người bán đồng ý giao một số lượng hàng hóa giao ngay tại một thời điểm, giá cả và các điều kiện giao dịch khác được chỉ định. ',
  66: 'Nguồn vốn ủy thác',
  67: 'Tài trợ ủy thác là gì?',
  68: 'Tài chính phi tập trung là tài chính dựa trên blockchain không dựa vào các tổ chức tài chính như môi giới, sàn giao dịch hoặc ngân hàng để cung cấp các công cụ tài chính, nhưng sử dụng các hợp đồng thông minh trên blockchain cho các hoạt động tài chính',
  69: 'C2C',
  70: 'C2C là gì',
  71: 'C2C, còn được gọi là "riêng tư với riêng tư" là một cách giao dịch, nó đề cập đến hình thức giao dịch giữa người với người ',
  72: 'Dịch vụ giao dịch một cửa',
  73: 'tên',
  74: 'giá',
  75: '24H thăng trầm',
  76: 'Tập 24H',
  77: 'Báo giá',
  78: 'Tổng hợp an toàn ổn định',
  79: 'Bảo mật cấp độ tài chính',
  80: 'Công nghệ bảo mật bằng không kiến ​​thức không thiên vị bảo vệ quyền riêng tư của người dùng, nhiều chữ ký, kiến ​​trúc phân lớp và các thiết kế bảo mật khác. ',
  81: 'Công cụ kết hợp hiệu suất cao',
  82: 'Áp dụng kiến ​​trúc cụm phân tán tiên tiến và các phương pháp phát triển dịch vụ vi mô. Hỗ trợ đồng thời hơn 3 triệu giao dịch mỗi giây. ',
  83: '24 giờ dịch vụ không bị gián đoạn',
  84: 'Dịch vụ khách hàng trực tuyến 24 giờ một ngày, bảy ngày một ngày, để trả lời tất cả các loại câu hỏi cho bạn. ',
  85: 'Mở giao dịch mọi lúc, mọi nơi',
  86: 'Bản sửa đổi mới, sẵn sàng đáp ứng nhu cầu giao dịch của các tình huống khác nhau',
  87: '24 * 7 trong mọi thời tiết, chế độ hoạt động toàn thời gian, bộ phận chăm sóc khách hàng sẽ giải đáp các thắc mắc liên quan cho bạn trong thời gian sớm nhất. ',
  88: 'cặp giao dịch',
  89: 'Giá mới nhất',
  90: 'tăng và giảm',
  91: 'Giá cao nhất',
  92: 'giá thấp nhất',
  93: 'Khối lượng',
  94: 'Đăng nhập',
  95: 'Đăng ký',
  fee: {
    1: 'Tỷ lệ giao dịch',
    2: 'Phí giao dịch tiền xu',
    3: 'Phí giao dịch hợp đồng'
  },
  law: {
    1: 'Tuyên bố pháp lý',
    2: 'Bài báo đầu tiên',
    3: 'Nền tảng này cam kết tạo ra một môi trường giao dịch tài sản kỹ thuật số tuân thủ, an toàn và đáng tin cậy cho người dùng và nghiêm cấm người dùng sử dụng trang web này để tham gia vào tất cả các hoạt động kinh doanh bất hợp pháp hoặc các hoạt động bất hợp pháp như rửa tiền, buôn lậu, hối lộ thương mại và thao túng thị trường độc hại. Để đạt được mục tiêu này, chúng tôi cố gắng hết sức để xây dựng các chương trình chống rửa tiền và chống khủng bố nghiêm ngặt, đồng thời thực hiện các biện pháp kiểm soát rủi ro bảo mật hiệu quả để bảo vệ tối đa quyền và lợi ích hợp pháp của người dùng và duy trì uy tín thương hiệu tốt cho chính nền tảng ',
    4: 'Bài báo thứ hai',
    5: 'Khi cơ quan có thẩm quyền xuất trình các tài liệu điều tra tương ứng để yêu cầu trang web này hợp tác điều tra người dùng được chỉ định hoặc thực hiện các biện pháp như niêm phong, đóng băng hoặc chuyển tài khoản người dùng, trang web này sẽ xác minh tính hợp lệ và xác thực của tư pháp Sau khi quan hệ tình dục, theo yêu cầu của các cơ quan có thẩm quyền được xác định bởi nền tảng để hỗ trợ cung cấp dữ liệu người dùng tương ứng hoặc thực hiện các hoạt động tương ứng, dẫn đến rò rỉ quyền riêng tư của người dùng, tài khoản không hoạt động và các tổn thất khác gây ra cho người dùng, v.v., điều này trang web Chúng tôi không chịu bất kỳ trách nhiệm nào. ',
    6: 'Điều 3',
    7: 'Tất cả người dùng sử dụng dịch vụ của trang web này nên biết trước khi giao dịch: tài sản kỹ thuật số không được hỗ trợ bởi bất kỳ chính phủ hoặc ngân hàng trung ương nào và giá tài sản kỹ thuật số biến động lớn, việc nắm giữ hoặc sử dụng tài sản kỹ thuật số có rủi ro cao. Trước khi đưa ra quyết định giao dịch, bạn nên lý trí và thận trọng về tình hình tài chính của mình để đánh giá xem nó có phù hợp với bạn không ',
    8: 'Điều 4',
    9: 'Bất kỳ ai đăng nhập vào trang web này theo bất kỳ cách nào hoặc sử dụng các dịch vụ của trang web này trực tiếp hoặc gián tiếp được coi là tự nguyện chấp nhận các ràng buộc của tuyên bố trang web này. '
  },
  rules: {
    1: 'Quy tắc giao dịch',
    2: 'Sau khi tạo đơn hàng, người mua nên:',
    3: '1. Hoàn tất thanh toán trong thời gian thanh toán được chỉ định trong đơn đặt hàng, sau đó nhấp vào "Tôi đã thanh toán";',
    4: '2. Sử dụng phương thức thanh toán tên thật của tôi, theo số tiền đặt hàng và tài khoản thanh toán do đơn đặt hàng chỉ định để hoàn tất thanh toán đơn đặt hàng trong thời gian thực;',
    5: '3. Nếu bạn chọn phương thức thanh toán không theo thời gian thực để thanh toán cho đơn hàng của mình, bạn phải được sự đồng ý trước của người bán',
    6: '4. Lệnh thanh toán không được ghi "tiền kỹ thuật số, BTC, Bitcoin" và các thông tin khác;',
    7: 'Nguyên tắc mà người bán nên tuân theo',
    8: 'Sau khi tạo đơn hàng, người bán nên',
    9: '1. Cung cấp tài khoản thanh toán được nền tảng chứng nhận;',
    10: '2. Sau khi nhận được đầy đủ thanh toán từ người mua, hãy hoàn tất việc phát hành trong thời gian quy định trong đơn đặt hàng;',
    11: '3. Nếu không có sự đồng ý của người mua, không thể yêu cầu người mua / khách hàng hủy đơn hàng;',
    12: '4. Không phản đối giá của một đơn đặt hàng đã được tạo ra mà không có sự đồng ý của người mua;'
  },
  help: {
    1: 'Trợ giúp thành viên mới',
    2: 'Lệnh giới hạn',
    3: 'Một lệnh mua / bán ở một mức giá cụ thể hoặc tốt hơn. ',
    4: 'Lệnh thị trường',

    5: 'Một lệnh mua hoặc bán ngay lập tức theo giá thị trường hiện tại. ',
    6: 'Lệnh giới hạn dừng',
    7: 'Một lệnh bán hoặc đóng một vị thế ở một mức giá đã định sau khi thị trường đạt đến một mức giá cụ thể để kiểm soát lỗ. ',
    8: 'Lệnh Chốt lời Giới hạn',
    9: 'Tương tự như lệnh dừng giới hạn, nó cũng là lệnh bán hoặc đóng một vị thế ở một mức giá đã định sau khi thị trường đạt đến một mức giá cụ thể để kiểm soát lợi nhuận. ',
    10: 'Lệnh dừng thị trường',
    11: 'Một lệnh bán hoặc đóng một vị thế theo giá thị trường sau khi thị trường đạt đến một mức giá cụ thể, được sử dụng để kiểm soát lỗ. ',
    12: 'Lệnh Chốt lời Thị trường',
    13: 'Tương tự như lệnh cắt lỗ thị trường, nó cũng là lệnh bán hoặc đóng một vị trí ở mức giá thị trường sau khi thị trường đạt đến một mức giá cụ thể để kiểm soát lợi nhuận. ',
    14: 'Biên độ mở là gì? ',
    15: 'Giao dịch ký quỹ nghĩa là bạn chỉ cần trả một tỷ lệ ký quỹ nhất định để mua và bán hợp đồng. Theo đòn bẩy đã chọn, tỷ lệ ký quỹ là bội số nhỏ hơn của giá trị hợp đồng. Anh ta có thể phóng đại lợi nhuận của bạn, nhưng cũng có thể khiến bạn gặp thêm rủi ro. ',
    16: 'Thanh lý là gì và vị thế bán là gì? ',
    17: 'Khi số tiền còn lại trong tài khoản của bạn ít hơn lãi và lỗ thả nổi theo yêu cầu của hợp đồng giao dịch, vị thế của bạn sẽ bị đóng bởi sàn giao dịch, còn được gọi là thanh lý. Vì giá tại thời điểm đóng một vị thế được xác định theo giá thị trường, khi giá giao dịch cực kỳ bất lợi, số tiền của bạn có thể không đủ để trang trải giao dịch và gây ra lỗ, dẫn đến giá trị âm trong tài khoản của bạn. Tình huống này được gọi là chụp quá mức. '
  },
  96: 'Chọn cặp giao dịch',
  97: 'Kiểm tra giá',
  98: 'Mua sau khi điền đơn đặt hàng',
  99: 'Đăng nhập tài khoản',
  100: 'Chào mừng bạn trở lại! Đăng nhập bằng email hoặc số điện thoại của bạn ',
  101: 'Hộp thư',
  102: 'Di động',
  103: 'Quên mật khẩu? ',
  104: 'Đăng ký ngay bây giờ',
  105: 'mật khẩu',
  106: 'Vui lòng nhập số điện thoại di động',
  107: 'Định dạng số điện thoại không chính xác',
  108: 'Vui lòng nhập địa chỉ email của bạn',
  109: 'Lỗi định dạng email',
  110: 'Vui lòng nhập mật khẩu của bạn',
  111: 'Mật khẩu có ít nhất 6 chữ số',
  112: 'Mật khẩu lên đến 32 ký tự',
  113: 'Ngôn ngữ và khu vực',
  114: 'Tạo tài khoản {appName}',
  115: 'Sử dụng email hoặc số điện thoại di động của bạn để đăng ký',
  116: 'Mã đề xuất',
  117: 'Đồng ý',
  118: 'và',
  119: 'Thỏa thuận người dùng',
  120: 'Chính sách Bảo mật',
  121: 'Đã đăng ký? ',
  122: 'Mã xác minh',
  123: 'gửi',
  124: 'Vui lòng nhập mã xác minh',
  125: 'Mã xác minh phải có 6 chữ số',
  126: 'Đặt lại mật khẩu',
  127: 'Được',
  128: 'Sử dụng email hoặc điện thoại di động của bạn để đặt lại mật khẩu đăng nhập',
  129: 'Xác thực tên thật',
  130: 'Hoàn thành xác thực tên thật sẽ giúp bảo vệ an toàn tài khoản, tăng giới hạn rút tiền và thẩm quyền giao dịch',
  131: 'Khử xác thực',
  132: 'Xác thực',
  133: 'Được sử dụng để đăng nhập, rút ​​tiền, truy xuất mật khẩu và sửa đổi cài đặt bảo mật',
  134: 'Đã xác minh',
  135: 'Quản lý mật khẩu',
  136: 'Mật khẩu đăng nhập',
  137: 'Mật khẩu tài trợ',
  138: 'Được sử dụng để bảo vệ bảo mật tài khoản',
  139: 'Để đảm bảo tiền',
  140: 'Cài đặt cá nhân',
  141: 'sở thích màu sắc',
  142: 'Cài đặt',
  143: 'Sửa đổi',
  144: 'Đặt màu của biểu đồ hình nến, mua lên, mua xuống',
  145: 'Phương pháp giá',
  146: 'Đơn vị tài khoản,',
  147: 'mặc định',
  148: 'Xanh lên và đỏ xuống',
  149: 'Đỏ lên và xanh xuống',
  150: 'Sửa đổi mật khẩu đăng nhập',
  151: 'Sửa đổi mật khẩu quỹ',
  152: 'Mật khẩu cũ',
  153: 'Mật khẩu mới',
  154: 'Vui lòng nhập mật khẩu cũ',
  155: 'Số điện thoại mới',
  156: 'Hộp thư mới',
  157: 'Số điện thoại ràng buộc',
  158: 'Hộp thư ràng buộc',
  159: 'Sửa đổi số điện thoại',
  160: 'Sửa đổi hộp thư',
  161: 'Đã xác thực',
  162: 'Đặt biệt hiệu',
  163: 'Vui lòng nhập biệt hiệu',
  164: 'Hủy bỏ',
  165: 'Quốc gia',
  166: 'tên',
  167: 'họ',
  168: 'Loại tài liệu',
  169: 'số tài liệu',
  170: 'Vui lòng chọn một quốc gia',
  171: 'Vui lòng nhập tên',
  172: 'Vui lòng nhập họ',
  173: 'Vui lòng nhập số ID',
  174: 'Vui lòng tải lên ảnh mặt trước của giấy tờ tùy thân',
  175: 'Vui lòng tải lên ảnh mặt sau của giấy tờ tùy thân',
  176: 'Vui lòng tải lên ảnh chứng minh thư',
  177: 'Gửi',
  178: 'Xác thực',
  179: 'ID',
  180: 'Hộ chiếu',
  181: 'Bước tiếp theo',
  182: 'Tải lên ảnh ID và hoàn tất xác thực',
  183: 'Tải lên không thành công',
  184: 'Lỗi định dạng hình ảnh',
  185: 'Hình ảnh quá lớn',
  186: 'Đang tải',
  187: 'Tổng quan về tài sản',
  188: 'Vuốt',
  189: 'Rút tiền',
  190: 'Nạp tiền',
  191: 'Hồ sơ tài chính',
  192: 'đóng băng',
  193: 'Có sẵn',
  194: 'Tổng số',
  195: 'Ẩn tiền tệ nhỏ',
  196: 'tiền tệ',
  197: 'Định giá USDT',
  198: 'Tìm kiếm',
  199: 'Nạp tiền mạng',
  200: 'Địa chỉ gửi tiền',
  201: 'Địa chỉ gửi đi',
  202: 'Số tiền ký quỹ',
  203: 'Ảnh chụp màn hình tính phí',
  204: 'Ghi chú',
  205: 'Sao chép địa chỉ',
  206: 'Sao chép thành công',
  207: 'trở lại',
  208: 'tùy chọn',
  209: 'Vui lòng nhập số tiền gửi',
  210: 'Vui lòng số hợp lệ',
  211: 'Vui lòng nhập địa chỉ tiền tệ',
  212: 'Vui lòng tải lên ảnh chụp màn hình của khoản tiền gửi',
  213: 'Địa chỉ rút tiền',
  214: 'Số tiền rút',
  215: 'Số dư khả dụng',
  216: 'Tất cả',
  217: 'phí xử lý',
  218: 'Vui lòng nhập số tiền rút',
  219: 'Vui lòng nhập mật khẩu quỹ',
  220: 'Mật khẩu tài trợ phải có 6 chữ số',
  221: 'Mật khẩu mặc định',
  222: 'Bạn chưa ràng buộc ví tương ứng! ',
  223: 'để thêm',
  224: 'Đã gửi thành công',
  225: 'Thêm ví bộ sưu tập',
  226: 'Địa chỉ',
  227: 'Vui lòng nhập một mạng',
  228: 'Vui lòng nhập địa chỉ',
  229: 'Mạng',
  230: 'Mạng rút tiền',
  231: 'Độ dài địa chỉ là 32 bit thấp nhất',
  232: 'Chuyển tiền',
  233: 'Số tiền chuyển',
  234: 'Vui lòng nhập số tiền chuyển',
  235: 'Số dư không đủ',
  236: 'Có thể vuốt',
  237: 'Vuốt hướng',
  238: 'Vuốt',
  239: 'từ',
  240: 'to',
  241: 'Hồ sơ tài chính',
  242: 'Hồ sơ gửi tiền',
  243: 'Hồ sơ rút tiền',
  244: 'Hồ sơ chuyển giao',
  245: 'số lượng',
  246: 'Trạng thái',
  247: 'thời gian sáng tạo',
  248: 'Xác nhận thời gian',
  249: 'Không còn nữa',
  250: 'giá',
  253: 'cặp giao dịch',
  254: 'lợi',
  255: 'Không có dữ liệu',
  256: 'tích lũy',
  257: 'Cao',
  258: 'thấp',
  259: 'Số lượng',
  260: 'trên',
  261: 'Nhận',
  262: 'số lượng',
  263: 'Giao dịch tương lai',
  264: 'Tăng giá',
  265: "Bearish",
  266: 'Tên sản phẩm',
  267: 'Xem hợp đồng',
  268: 'Số tiền mua',
  269: 'Vui lòng nhập số tiền mua hàng',
  270: 'Phí giao dịch',
  271: 'Mua tối thiểu',
  272: 'Trạng thái',
  273: 'Được giao',
  274: 'Đã giao hàng',
  275: 'Đơn hàng đã được đặt thành công',
  276: 'Tên sản phẩm',
  277: 'Lãi và lỗ',
  278: 'Giá mua',
  279: 'giá giao hàng',
  280: 'Tiếp tục giao dịch',
  283: 'thu nhập',
  284: 'Xác nhận đơn hàng',
  285: 'Lịch sử trật tự',
  286: 'Lệnh đang chờ xử lý',
  287: 'thời gian sáng tạo',
  288: 'Mua',
  289: 'Bán',
  290: 'Số lượng giao dịch',
  291: 'Số tiền giao dịch',
  292: 'Hợp đồng',
  293: 'Thời gian giao hàng',
  294: 'giá giao hàng',
  295: 'giây',
  296: 'phút',
  297: 'Khi nào',
  298: 'ngày',
  299: 'Thời gian còn lại',
  300: 'Xem thêm',
  301: 'Giao dịch tiền xu',
  302: 'Số tiền bán',
  303: 'Vui lòng nhập số lượng bán',
  304: 'Giao dịch tiền tệ Fiat',
  305: 'Tài sản',
  306: 'Đăng xuất',
  307: 'Đăng ký thành công',
  308: 'Vui lòng nhập một đề xuất?',
  309: 'Thành công',
  310: 'Không thành công',
  311: 'Xác nhận',
  312: 'Phương thức thanh toán',
  313: 'Địa chỉ rút tiền',
  314: 'Xóa',
  315: 'Hoạt động',
  316: 'Ví của bạn',
  317: 'Bạn có chắc chắn muốn xóa không? ',
  318: 'Xóa thành công',
  319: 'Xác thực không thành công',
  320: 'Xác thực lại',
  330: 'Ví rút tiền kỹ thuật số',
  331: 'Ràng buộc thẻ ngân hàng và rút về tiền tệ fiat',
  332: 'Vui lòng nhập một địa chỉ hợp lệ',
  1000: 'ngày',
  1001: 'Đang tiến hành',
  1002: 'đóng cửa',
  1003: 'quy tắc',
  1004: 'Lợi thế sản phẩm',
  1005: 'Mọi lưu trữ và truy xuất',
  1006: 'Phân phối đã hết hạn',
  1007: 'Cổ tức hàng ngày',
  1008: 'Quan tâm trực tiếp',
  1009: 'Đảm bảo tài trợ 100%',
  1010: 'Thu nhập bình thường trong kỳ nghỉ',
  1011: 'Lãi suất ngày hôm sau',
  1012: 'Lợi thế sản phẩm',
  1013: 'Thành viên gửi 200.000 USDT trên nền tảng, chọn sản phẩm quản lý tài sản với thời hạn 14 ngày và thu nhập hàng ngày là 0,5% và thu nhập hàng ngày như sau: 200.000*0,5%=1.000USDT Tức là sau 14 ngày, bạn có thể nhận được thu nhập 14.000 USDT và thu nhập được phát hành hàng ngày. Hãy gửi tiền gốc và số tiền này sẽ tự động được trả lại vào tài khoản của bạn khi đáo hạn.',
  1014: 'Đơn hàng được quản lý',
  1015: 'Được quản lý',
  1016: 'Đã hoàn thành',
  1017: 'Được chuộc',
  1018: 'Không có dữ liệu',
  1019: 'Số tiền mua',
  1020: 'Năng suất hàng ngày',
  1021: 'TIN TƯỞNG. 6',
  1022: 'Thời gian thanh toán',
  1023: 'Ngày quy đổi',
  1024: 'Hết hạn',
  1025: 'Những ngày gần đây (thu nhập hàng ngày)',
  1026: 'Thời gian cổ tức',
  1027: 'Mỗi ngày',
  1028: 'Số tiền ký quỹ',
  1029: 'Hoàn trả khi đến hạn',
  1030: 'Số dư khả dụng',
  1031: 'Tổng thu nhập ước tính',
  1032: 'Chuyển tiền',
  1033: 'Tất cả',
  1034: 'Giới hạn đơn',
  1035: 'Phí xử lý',
  1036: 'Tôi đã đọc và đồng ý',
  1037: 'Thỏa thuận Dịch vụ Tài chính Nền tảng',
  1038: 'Được',
  1039: 'Mua',
  1040: 'Mua',
  1041: 'để bán',
  1042: 'Chuyển khoản ngân hàng',
  1043: 'Vui lòng nhập số tiền',
  1044: 'Loại tiền tệ Fiat',
  1045: 'Số lượng bán được',
  1046: 'Có sẵn tối đa',
  1047: 'Tất cả',
  1048: 'Đơn giá tham khảo',
  1049: 'Phương thức thanh toán của bạn',
  1050: 'Tên Ngân hàng',
  1051: 'Số tài khoản ngân hàng',
  1052: 'Tên',
  1053: 'Bạn chưa ràng buộc phương thức thanh toán tương ứng và không thể bán. ',
  1054: 'Hồ sơ đặt hàng',
  1055: 'Bộ lọc',
  1056: 'Loại',
  1057: 'Trạng thái',
  1058: 'Đang xử lý',
  1059: 'Vượt qua',
  1060: 'Không thành công',
  1061: 'Số lượng',
  1062: 'Thành công',
  1063: 'Nhiệm vụ đang được thực thi',
  1064: 'Tính toán kết quả ngày hôm nay',
  1065: 'Thu nhập tích lũy',
  1066: 'Đặt hàng trong ký quỹ',
  1067: 'Tài khoản ủy thác chưa được mở',
  1068: 'Mở một tài khoản ủy thác, tận hưởng thu nhập thụ động ổn định và làm cho việc đánh giá tài sản dễ dàng hơn. ',
  1069: 'Đăng ký kích hoạt nghĩa là bạn đã đọc và đồng ý',
  1070: '"Thỏa thuận Dịch vụ Tài chính Nền tảng"',
  1071: 'Ứng dụng đang mở',
  1072: 'Hủy bỏ',
  1073: 'Đang thực thi ...',
  1074: 'Sự chuộc lỗi',
  1075: 'Bạn có chắc chắn muốn đổi quà không? ',
  1076: 'Bạn cần phải trả các khoản bồi thường thiệt hại đã thanh lý để đổi lại sớm, số tiền này sẽ được khấu trừ lần này tùy theo loại hình quản lý tài chính của bạn',
  1077: ', Xác nhận đổi quà? ',
  1078: 'đã gửi',
  1079: 'Vui lòng nhập mã xác minh',
  1080: 'gợi ý',
  1081: 'thu nhập',
  1082: 'thời gian',
  1083: 'Thêm phương thức thanh toán fiat',
  1084: 'Trạng thái đơn hàng',
  1085: 'Tiền tệ',
  1086: 'Số lượng tiền tệ fiat',
  1087: 'Số tài khoản ngân hàng',
  1088: 'Phương thức thanh toán bằng tiền Pháp',
  1089: 'Thêm phương thức thanh toán',
  1090: 'Phương thức thanh toán hiện tại',
  1091: 'Tiền tệ Fiat',
  1092: 'Mã ngân hàng',
  1093: 'Bạn có chắc chắn muốn xóa phương thức thanh toán không? ',
  1094: 'Vui lòng nhập số tài khoản ngân hàng',
  1095: 'Vui lòng nhập tên ngân hàng',
  1096: 'Vui lòng nhập tiền tệ fiat',
  1097: 'Hãy chọn',
  1098: 'Vui lòng nhập tên',
  1099: 'Chi nhánh',
  1100: 'Không giới hạn',
  1101: 'Bạn chưa được xác thực bằng tên thật',
  1102: 'Giấy trắng',
  1103: 'Tính năng của chúng tôi',
  1104: 'Chúng tôi bảo mật',
  1105: 'Chúng ta đang hùng mạnh',
  1106: 'Hỗ trợ tốt nhất',
  1107: 'Chúng tôi là toàn cầu',
  1108: 'Nó hoạt động như thế nào',
  1109: 'Người dùng ở hơn 20 quốc gia và khu vực đang sử dụng nền tảng của chúng tôi và được công nhận rộng rãi',
  1110: 'Mã hóa dữ liệu nâng cao',
  1111: 'Nhiều loại tiền điện tử phong phú',
  '1111_2': 'Nhiều loại kim loại quý và tiền điện tử',
  1112: '90 + Tiền điện tử được liệt kê ',
  1113: 'Dữ liệu giao dịch của bạn được bảo mật thông qua mã hóa đầu cuối, đảm bảo rằng chỉ bạn mới có quyền truy cập vào thông tin cá nhân của mình.',
  1114: '43 tỷ',
  1115: 'Khối lượng giao dịch 24h',
  1116: '30 triệu',
  1117: 'Người dùng đã đăng ký tin tưởng chúng tôi',
  1118: 'Phí giao dịch thấp nhất',
  1119: 'Tải xuống',
  1120: 'Tên tổ chức tài chính',
  1121: 'tên chi nhánh',
  1122: 'số văn phòng chi nhánh',
  1123: 'Số tài khoản',
  1124: 'Tên người nhận',
  1125: 'Kinjo',
  1129: 'Thành phố Hugh',


  1148: 'Nền tảng tiền điện tử đáng tin cậy nhất ',
  1149: 'Đây là lý do tại sao bạn nên chọn chúng tôi',
  1150: 'Chúng tôi bảo mật',
  1151: 'Công nghệ bảo mật và quyền riêng tư không có kiến thức không thiên vị để bảo vệ quyền riêng tư của người dùng, nhiều chữ ký, kiến trúc phân lớp và các thiết kế bảo mật khác',
  1152: 'Hỗ trợ tốt nhất',
  1153: '24 * 7 giờ trong mọi thời tiết, chế độ hoạt động toàn thời gian, dịch vụ khách hàng sẽ giải đáp các thắc mắc liên quan của bạn trong thời gian sớm nhất',
  1154: 'We`re Global',
  1155: 'Người dùng ở hơn 20 quốc gia và khu vực đang sử dụng nền tảng của chúng tôi và được công nhận rộng rãi',
  1156: 'Chúng tôi mạnh mẽ',
  1157: 'Nền tảng giao dịch tài sản kỹ thuật số phổ biến nhất, quyền truy cập vào các công cụ giao dịch và phân tích kỹ thuật mạnh mẽ và thuận tiện nhất trên thị trường ',
  1158: 'Nhiều loại tiền điện tử phong phú',
  1159: 'Hơn 90 loại tiền điện tử được liệt kê',
  1160: 'Mã hóa dữ liệu nâng cao',
  1161: 'Dữ liệu giao dịch của bạn được bảo mật thông qua mã hóa đầu cuối, đảm bảo rằng chỉ bạn mới có quyền truy cập vào thông tin cá nhân của mình.',
  1162: 'Chúng tôi hỗ trợ nhiều loại tiền tệ kỹ thuật số phổ biến nhất',
  1163: 'Nhiều quốc gia đã thiết lập giao dịch bản địa hóa',
  1164: 'Sử dụng tiền tệ hợp pháp để mua tài sản kỹ thuật số và nhanh chóng bước vào thế giới tài sản kỹ thuật số',
  1165: 'Giao dịch để đi mọi nơi mọi lúc',
  1166: 'Quét tôi để tải xuống',
  1167: 'Tỷ giá hối đoái',
  1168: 'Mua và bán hơn 250 loại tiền điện tử với tỷ giá hối đoái được tải lên gần đây',
  1169: 'Luôn cập nhật thông tin về ứng dụng và ứng dụng máy tính để bàn của chúng tôi',

  1170: 'Tên',
  1171: 'Giá bán',
  1172: 'Biến đổi',
  1173: 'Đồ thị',
  1174: 'Buôn bán',
  1175: 'Chi tiết',
  1176: 'Là',
  1177: 'Chắc chắn',
  1178: 'Chúng ta có',
  1179: 'Toàn cầu',
  1180: 'Mạnh mẽ',
  1181: 'Nhiều loại phong phú',
  1182: 'Tiền điện tử',
  1183: 'Dữ liệu nâng cao ',
  1184: 'Mã hóa',
  1185: 'sẵn sàng để bắt đầu',
  1186: 'Chúng tôi là nền tảng giao dịch tài sản kỹ thuật số hàng đầu thế giới. ',
  1187: 'Chúng tôi là nền tảng giao dịch tài sản kỹ thuật số hàng đầu thế giới. Chúng tôi không ngừng nỗ lực cải thiện các tính năng bảo mật để đảm bảo an toàn cho tài sản kỹ thuật số của khách hàng. Mô hình của chúng tôi là mang đến cho khách hàng những công trình có giá trị bền vững.',

  1188: 'Tổng cung',
  1189: 'Chế độ hoạt động 24 * 7 trong mọi thời tiết, toàn thời gian, bộ phận chăm sóc khách hàng sẽ giải đáp các thắc mắc liên quan cho bạn trong thời gian sớm nhất',
  1190: 'Chúng tôi là toàn cầu',
  1191: 'Chúng tôi rất khỏe',
  1192: 'Nền tảng giao dịch tài sản kỹ thuật số phổ biến nhất, có được các công cụ giao dịch và phân tích kỹ thuật mạnh mẽ và thuận tiện nhất trên thị trường.',
  1193: 'Nhiều loại tiền điện tử',
  1194: 'Dữ liệu giao dịch của bạn được bảo vệ bằng mã hóa đầu cuối, đảm bảo rằng chỉ bạn mới có thể truy cập thông tin cá nhân của mình.',
  1195: 'Phí giao dịch tối thiểu',
  1196: 'Tin tưởng người dùng đã đăng ký của chúng tôi',
  1197: 'Khối lượng giao dịch 24 giờ',
  1198: '+42 tỷ',
  1199: '+30 triệu',
  1200: 'Một số quốc gia đã thiết lập các trung tâm dịch vụ giao dịch được bản địa hóa để tạo ra một hệ sinh thái blockchain tích hợp nhiều hình thức kinh doanh',
  1201: 'Sử dụng tiền tệ fiat để mua tài sản kỹ thuật số và nhanh chóng bước vào thế giới tài sản kỹ thuật số',
  1202: 'Tải xuống APP',
  1203: 'Lợi thế của chúng tôi',
  1204: 'Thông tin tiền tệ và tiền tệ phong phú, cung cấp tiền tệ, hợp đồng, giao dịch tiền tệ hợp pháp và các phương thức giao dịch khác, thị trường chuyên nghiệp và chuyên sâu.'
}
