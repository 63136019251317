export const LANG_OPTIONS = [
    {
      lang: "en-US",
      name: "English",
      flag: require("../assets/img/en-US.png"),
    },
    {
      lang: "zh-TW",
      name: "中文繁体",
      flag: require("../assets/img/zh-TW.png"),
    },
    {
      lang: "zh-CN",
      name: "中文简体",
      flag: require("../assets/img/zh-CN.png"),
    },
    {
      lang: "fr-FR",
      name: "français",
      flag: require("../assets/img/fr-FR.png"),
    },
    {
      lang: "ja-JP",
      name: "日本語",
      flag: require("../assets/img/ja-JP.png"),
    },
    {
      lang: "ko-KR",
      name: "한국어.",
      flag: require("../assets/img/ko-KR.png"),
    },
    {
      lang: "ms-MY",
      name: "Malay",
      flag: require("../assets/img/ms-MY.png"),
    },
    {
      lang: "ru-RU",
      name: "русский",
      flag: require("../assets/img/ru-RU.png"),
    },
    {
      lang: "de-DE",
      name: "Deutsche",
      flag: require("../assets/img/de-DE.png"),
    },
    {
      lang: "it-IT",
      name: "italiano",
      flag: require("../assets/img/it-IT.png"),
    },
    {
      lang: "es-ES",
      name: "Español",
      flag: require("../assets/img/es-ES.png"),
    },
    {
      lang: "th-TH",
      name: "ภาษาไทย",
      flag: require("../assets/img/th-TH.png"),
    },
    {
      lang: "pt-PT",
      name: "Português",
      flag: require("../assets/img/pt-PT.png"),
    },
    {
      lang: "vi-VN",
      name: "Tiếngviệt",
      flag: require("../assets/img/vi-VN.png"),
    },
    {
      lang: "tr-TR",
      name: "Türkiye",
      flag: require("../assets/img/tr-TR.png"),
    },
  ]