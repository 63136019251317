<template>
  <Dialog @onClosed="onClosed">
        <div slot="head">{{$t('1003')}}</div>
        <div slot="body">
            <div class="rules-area">
                <div class="rules-product">
                    <span class="ml10 title">{{$t('1004')}}</span>
                    <div class="rules-row">
                         <div class="rules-row-item">
                             <img src="../assets/img/finance1.png" alt="">
                             <div class="des">
                                <span class="title">{{$t('1005')}}</span>
                                <span>{{$t('1006')}}</span>
                            </div>
                         </div>
                         <div class="rules-row-item">
                                <img src="../assets/img/finance2.png" alt="">
                            <div class="des">
                                <span class="title">{{$t('1007')}}</span>
                                <span>{{$t('1008')}}</span>
                            </div>
                         </div>
                    </div>
                    <div class="rules-tag">
                        <div class="tag">{{$t('1009')}}</div>
                        <div class="tag">{{$t('1010')}}</div>
                        <div class="tag">{{$t('1011')}}</div>
                    </div>
                </div>
                <span class="ml10 title">{{$t('1012')}}</span>
                <div class="rules-des">
                     <p>{{$t('1013')}}</p>
                </div>

            </div>
        </div>
        <div slot="foot"></div>
    </Dialog>
</template>

<script>
import Dialog from "../components/Dialog";
export default {
  name: "Financial",
  props: ["dialogData"],
  components: { Dialog },
  data() {
    return {
      hadSwich: 0,
      cateList: [],
      products: []
    };
  },
  methods: {
    onClosed() {
      this.$emit('onClosed' ,this.isShown)
    }
  },
};
</script>

<style lang="scss" scoped>
.rules-area {
    padding: 20px;
   .rules-product {
       margin-bottom: 30px;
       .rules-row {
           display: flex;
           width: 100%;
           padding: 20px;
           .rules-row-item {
               width: 250px;
               display: inline-flex;
               img {
                   width: 80px;
               }
               .des {
                   margin-left: 20px;
                   display: flex;
                   flex-direction: column;
                   justify-content: center;
               }
           }
       }
       .rules-tag {
           margin: 10px; 
           display: inline-flex;
           .tag {
               border-radius: 10px;
               margin-right: 10px;
               background-color: rgba(26,187,151,.3);
               padding: 2px 20px;
           }
       }
   }
   .rules-des {
       padding: 10px;
   }
}
 .title {
           font-weight: 800;
       }
</style>