export const formatTime = (stamp) => {
  if (typeof stamp === 'string' && /\d{2}:\d{2}:\d{2}/.test(stamp)) return stamp
  const d = new Date(stamp * 1000)
  const h = `0${d.getHours()}`.slice(-2)
  const m = `0${d.getMinutes()}`.slice(-2)
  const s = `0${d.getSeconds()}`.slice(-2)
  return `${h}:${m}:${s}`
}
export const formatDate = (stamp) => {
  const d = new Date(stamp)
  const M = `0${d.getMonth() + 1}`.slice(-2)
  const D = `0${d.getDate()}`.slice(-2)
  const h = `0${d.getHours()}`.slice(-2)
  const m = `0${d.getMinutes()}`.slice(-2)
  return `${M}-${D} ${h}:${m}`
}
export const formatDateWithY = (stamp) => {
  const d = new Date(stamp)
  const Y = d.getFullYear()
  const M = `0${d.getMonth() + 1}`.slice(-2)
  const D = `0${d.getDate()}`.slice(-2)
  return `${Y}-${M}-${D}`
}
export const formatDateTime = (stamp) => {
  if (!stamp) return '--'
  const d = new Date(stamp * 1000)
  const Y = d.getFullYear()
  const M = `0${d.getMonth() + 1}`.slice(-2)
  const D = `0${d.getDate()}`.slice(-2)
  const h = `0${d.getHours()}`.slice(-2)
  const m = `0${d.getMinutes()}`.slice(-2)
  const s = `0${d.getSeconds()}`.slice(-2)
  return `${Y}-${M}-${D} ${h}:${m}:${s}`
}
export const hexToRgb = (hex) => {
  if (typeof hex === 'string' && hex.indexOf('rgb') === 0) return
  return 'rgb(' + parseInt('0x' + hex.slice(1, 3)) + ',' + parseInt('0x' + hex.slice(3, 5)) + ',' + parseInt('0x' + hex.slice(5, 7)) + ')'
}
