<template>
  <div class="wrapper">
    <header >
      <banner/>
    </header>

    <div class="v-container">
      <coins-list />
    </div>
    <how-it-works />

    <our-feature />

    <trading-platform @banner="onPlatItem"/>
    
   <download-area /> 
  <BannerModal v-if="showBannerContent" v-model="showBannerContent" :content="curContent"></BannerModal>
  </div>
</template>
<script>
import BannerModal from "@/components/BannerModal";
import { getCarousels } from "@/api";
import utils from "@/utils/misc"
import tradingMixin from '@/mixins/tradingMixin'
import Banner from '@/pages/theme/home/widgets/banner'
import CoinsList from '@/pages/theme/home/widgets/coins-list'
import HowItWorks from '@/pages/theme/home/widgets/how-it-works'
import OurFeature from '@/pages/theme/home/widgets/our-feature'
import DownloadArea from '@/pages/theme/home/widgets/download-area'
import TradingPlatform from '@/pages/theme/home/widgets/trading-platform'
export default {
  name: "Home",
  props: ["lang"],
  mixins: [tradingMixin],
  data() {
    return {
      // banners: [],
      showBannerContent: false,
      curContent: {}
    };
  },
  components: {
    TradingPlatform,
    Banner,
    CoinsList,
    BannerModal,
    HowItWorks,
    OurFeature,
    DownloadArea
  },
  // beforeMount() {
  //   this.getBanners();
  // },
  computed: {
    isTimeX() {
      return utils.getLs('agentIdentify') === 'e4ee504c-670f-4c82-a610-5b8aa2351ad7'
    }
  },
  methods: {
    onPlatItem(item) {
      this.curContent = item
      this.showBannerContent = true
    }
  },
};
</script>

<style lang="scss" scoped>
.text-grey {
  color: #91929E!important;
}  
.wrapper {
  background: #0E0E22 ;
  color:#91929E;
}  




// .timex .tp-banner-container{
//   height: auto;
//   padding-top: 100px;
//   padding-bottom: 40px;
//   background: #fff;
// }

.modle2 {
  width: 100%;
  display: flex;
  padding: 60px 0;
  justify-content: center;
  color: #fff;
  background: #1d1d37;
  .logoBox {
    // width: 1200px;
    max-width: 650px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .l {
      width: 600px;
    }
    .r {
      width: 300px;
    }
  }
}
.tp-banner-3 {
  background: url("~@/assets/img/banner.png") no-repeat;
  background-size: cover;
}
.d-btn{
  display:inline-block;
  height: 60px;
  width: 220px;
  display: inline-block;
  line-height: 60px;
  // background: #fff;
  color: #0088ff;
  // border: 1px solid #0088ff;
  transition: all 0.3s ease-in;
  font-weight: 700;
  font-family: "Noto Sans", sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  margin-right: 25px;
  &:hover{
    // background: #0088ff;
    // color: #fff;
  }
  &:last-child{
    // background: #0088ff;
    // margin-right: 0;
    // color: #fff;
    // &:hover{
    //   background: #fff;
    //   color: #0088ff;
    // }
  }
}
@media screen and (max-width: 1200px) {
  .imgarea{
    display: none;
  }
   
  .data24 .data-item h3{
    font-size: 30px;
  }
  .our-feature h2, .howworks h2, .download-area h2{
    font-size: 24px;
  }
  .work-item{
    margin-bottom: 30px;
  }
  .our-feature .single h3{
    font-size: 18px;
  }
  // .timex .tp-banner-container{
  //   height: auto;
  //   padding-top: 55px;
  //   padding-bottom: 40px;
  //   background: #fff;
  // }
  
  .tp-banner-container {
    // padding-top: 100px;
    .tp-banner {
      height: 80vh;
      &.timex{
          background: none;
          display: flex;
          flex-direction: column-reverse;
        .tp-caption{
            min-width: 100vw;
            padding:10px;
            color: #333;
            .logoBox .text-box h3{
              color: #333;
            }
            .logoBox .text-box{
              padding: 20px;
              margin:20px 0;
            }
            .logoBox h1 {
              width: 100%;
              font-size: 30px;
              text-align: center;
            }
        }
          .timexBanner{
            flex:3;
            background-size: cover;
          }

      }
      .tp-caption {
        padding: 0 10px;
        .logoBox {
          h1 {
            display: none;
            font-size: 24px;
            width: 260px;
            //  margin: 0 auto;
          }
          .text-box {
            width: 100%;
            height: 100px;
            flex-direction: column;
            margin-top: 46px;
            h3 {
              font-size: 18px;
            }
          }
          .text {
            bottom: 15%;
            p {
              width: 100vw;
              padding: 0 15px;
            }
          }
          .btn {
            justify-content: center;
            flex-wrap: wrap;
            margin-right: 0;
            a{
              margin-right: 0;
              margin-bottom: 8px;
              font-size: 14px;
              height: 60px;
              width: 200px;
              line-height: 60px;
            }
          }
        }
      }
      &::after {
        height: calc(100vh - 100px);
      }
    }
  }
  .tp-banner-container-2 {
      background: #1d1d37;
      height: 140vh;
            .tp-banner-2{
            background-size: 319%;
            background-position: -84vw -9vh;
            .logoBox-2 {
                flex-wrap: wrap;
                .left {
                    width: 100%;
                    h1 {
                        font-size: 17vw;
                    }
                    .text-box {
                    margin: 50px 0 0 ;
                    padding: 50px 0 0; 
                    &::before{
                        transform: translate(0, -90px);  
                    }   
                    } 
                }
               .btn {
                  background: #1d1d37;
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;
                  justify-content: center;
                   margin: 50px 0 0 ;
                   width: 100%;
                   padding: 30px;
                   img {
                       margin: 10px;
                   }
               } 
            }
        }
  }
  .modle2 {
    padding: 0 0 60px; 
    .logoBox{
        width: 100%;
        flex-wrap: wrap;
        .l, .r {
            width: 100%;
            padding: 30px;  
        }
    }
  }
}
</style>