export default {
  20001: 'RecTickerData', // 20001-服务端推送现货行情数据
  20002: 'RecKlineData', // 20002-服务端推送现货K线数据
  20003: 'RecKlineData', // 20003-服务端推送期货K线数据
  20004: 'RecTimeData', // 20004-服务端推送分时图数据
  20008: 'RecTimeData', // 20008-服务端推送分时图数据
  20009: 'HeartBeat', // 20009-心跳
  10001: 'SendSubscribeTicker', // 10001-客户端订阅行情数据
  10002: 'SendSubscribeInfo', // 10002-客户端订阅现货K线数据
  10003: 'SendSubscribeInfo', // 10003-客户端订阅期货K线数据
  10004: 'SendSubscribeRealTime', // 10004-客户端订阅现货分时数据
  10008: 'SendSubscribeRealTime', // 10008-客户端订阅期货分时数据
  10005: 'CancelSubscribe', // 10005-取消订阅
  10009: 'HeartBeat', // 10009-心跳
  10006: 'SendSubscribeTicker', // 10009-心跳
  20006: 'RecTickerData', // 20001-服务端推送期货行情数据
  10007: 'SendSubscribeDepth', // 10007-客户端订阅20档挂单数据
  10010: 'SendSubscribeDepth', // 10007-客户端订阅20档挂单数据
  20007: 'RecDepthInfo', // 20007-服务端推送20档挂单数据
  20010: 'RecDepthInfo', // 20010-服务端推送20档挂单数据(期货)
}
