<template>
  <div class="main-coins">
    <div class="title-box">
      <h1>{{$t('1167')}}</h1>
      <h3>{{$t('1168')}}</h3>
    </div>
    <div class="coins-list">
      <div class="list-header">
        <div class="flex1">#</div>
        <div class="flex6">{{$t('1170')}}</div>
        <div class="flex5">{{$t('1171')}}</div>
        <div class="flex3">{{$t('1172')}}</div>
        <div class="flex3 chart">{{$t('1173')}}</div>
        <div class="flex3 tc Detail-box">{{$t('1174')}}</div>
      </div>
      <ul>
        <li v-for="(item, index) in mainCoins">
          <router-link to="/spot">
            <div class="flex1">{{index + 1}}</div>
            <div class="flex6 name-info">
              <img :src="coinMsg[item.coin]?coinMsg[item.coin].logoUri:''">
              <span class="currency-type">{{item.symbol}}</span>
              <span class="currency-name">{{item.coin }}</span>
            </div>
            <div class="flex5 money-title">
              USDT {{item.price}}
            </div>
            <div class="flex3 change" :class="[item.change > 0? 'green-color ' : 'red-color']">
              {{item.change > 0 ? '+'+ item.change : item.change}}%
            </div>
            <div class="flex3 k-line chart">
              <img v-if="item.change > 0" src="~@/assets/img/home7/green1.png">
              <img v-else src="~@/assets/img/home7/green.png">
            </div>
            <div class="flex3 tc Detail-box">
              <div class="Detail">{{$t('1175')}}</div>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import utils from '@/utils/misc'
export default {
  data() {
    return {
    }
  },
  computed: {
    mainCoins() {
      const hotCoins = ['BTC', 'ETH', 'DOGE', 'EOS', 'FIL']
      const arr = []
      for (const key in this.$store.state.spotList) {
        arr.push(this.$store.state.spotList[key])
      }
      console.log(arr.filter(item => hotCoins.includes(item.coin)))
      return arr.filter(item => hotCoins.includes(item.coin))
    },
    coinMsg() {
      return this.$store.state.coins
    }
  },
}    
</script>


<style lang="scss" scoped>
@media screen and (max-width: 1000px) {
  .main-coins {
    .coins-list {
      .chart {
        display: none !important;
      }

      .Detail-box {
        display: none !important;
      }

      ul {
        li {

          .name-info {
            img {
              margin-right: 2px !important;
              width: 30px !important;
              height: 30px !important;
            }

            .currency-type {
              font-size: 12px !important;
              margin-right: 1px !important;
            }

            .currency-name {
              font-size: 12px !important;
            }
          }

          .money-title {
            font-size: 12px;
          }

          .change {
            font-size: 12px !important;
          }
        }
      }
    }
  }
}

.main-coins {
  margin-top: 100px;

  .title-box {
    h1 {
      color: #291029;
    }

    h3 {
      color: #291029;
      margin-top: 20px;
    }
  }

  .coins-list {
    margin-top: 40px;
    background: rgba(255, 255, 255, 0.43);
    box-shadow: 0px 4px 50px rgba(116, 71, 117, 0.25);
    border-radius: 20px;
    padding: 0 20px;
    padding-bottom: 10px;

    .list-header {
      display: flex;
      padding: 20px 0;
      border-bottom: 1px solid #291029;
    }

    ul {
      li {
        a{
          display: flex;
          align-items: center;
          font-size: 16px;
          padding: 15px 0;
        }
        .name-info {
          display: flex;
          align-items: center;
          word-wrap: break-word;

          img {
            width: 35px;
            height: 35px;
            margin-right: 15px;
          }

          .currency-type {
            font-weight: bold;
            font-size: 18px;
            margin-right: 15px;
          }

          .currency-name {}
        }

        .money-title {
          font-weight: bold;
        }

        .k-line {
          img {
            width: 70%;
          }
        }

        .Detail {
          width: 90px;
          height: 40px;
          border-radius: 10px;
          text-align: center;
          line-height: 40px;
          border: 1px solid #291029;
          cursor: pointer;
          margin: 0 auto;
        }
      }
    }

    .tc {
      text-align: center;
    }

    .flex1 {
      flex: 1;
    }

    .flex5 {
      flex: 5;
    }

    .flex3 {
      flex: 3;
    }

    .flex4 {
      flex: 4;
    }

    .flex6 {
      flex: 6;
    }
  }

  .green-color {
    color: #48D49E;
  }

  .red-color {
    color: #E4474B;
  }
}
</style>
