<template>
  <div class="download-area">
    <div class="bg section">
      <div class="v-container">
      <div class="content">
        <h3>{{ $t(1105) }}</h3>
        <p :title="$t('homeTitle_1_2')">{{  $t('homeTitle_1_2') }}</p>
      </div>
      </div>
    </div>
    <div class="bg section c-right">
      <div class="v-container">
      <div class="content">
        <h3>{{ $t(1110) }}</h3>
        <p :title="$t(1113)">{{  $t(1113) }}</p>
      </div>
      </div>
    </div>
  </div>
</template>
 
 <script>
export default {};
</script>
 
 <style lang="scss" scoped>
  .download-area {
  text-align: center;
  .section{
    padding: 130px 0 0;
    height:780px;
    background-image: url('./bg-left.png');
    background-size: auto 780px;
    background-position: 0 0;
    background-repeat: no-repeat;
    &.c-right{
      background-image: url('./bg-right.png');
      background-position: right top;
      .v-container{
        justify-content:flex-start;
      }
    }
    .v-container{
      display:flex;
      align-items:center;
      justify-content:flex-end;
      .content{
        max-width:500px;
        h3{
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 600;
          font-size: 60px;
          color: #5D2146;
        }
        p{
          margin-top:80px;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 34px;
          color: #91929E;
        }
      }
    }
  }
  .v-container {
  }
  h2 {
    margin-bottom: 40px;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 600;
    font-size: 72px;
    color: #5d2146;
  }
  .sub-title {
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    margin-top: 14px;
    color: #91929e;
    margin-bottom: 40px;
  }
  .part-icon {
    font-size: 45px;
    margin-bottom: 20px;
    color: #0088ff;
    .fa {
      font-weight: 900;
    }
  }
  .single {
    text-align: center;
    padding: 40px 30px;
    margin-bottom: 30px;
    transition: all 0.3s ease-in;
    box-shadow: 0px 0px 9px 2px #f5f5f5;
    color: #666;
    font-size: 16px;
    min-height: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    h3 {
      color: #000050;
      margin-bottom: 16px;
      font-size: 24px;
      font-weight: 600;
    }
    p {
      line-height: 24px;
    }
    &:hover {
      box-shadow: 0px 0px 19px 12px #f5f5f5;
    }
  }
  p {
    font-size: 16px;
    margin-bottom: 40px;
  }
  .d-btn {
    display: block;
    // width: 214px;
    // height: 58px;
    margin: 20px auto !important;
  }
}
@media screen and (max-width: 1200px) {
  .imgarea {
    display: none;
  }
  .flex-box {
    flex-direction: column;
  }
  .download-area .v-container h2 {
    font-size: 55px;
  }
  .download-area .bg.section{
    // background-image:url('none');
    background-size:0;
    padding: 40px 0;
    height: auto;
    .v-container .content {
      h3{
      font-size: 36px;
      }
      p{
        font-size: 24px;
        margin-top: 40px;
      }
    }
  }
}
.items-center {
  align-items: center;
}


</style>