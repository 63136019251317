<!--公共的modal-->

<!--
element-ui dialog slot
title, Dialog 标题区的内容
footer, Dialog 按钮操作区的内容
—, Dialog 的内容
-->

<!--
modal slot
head, modal 标题区的内容&ndash;&gt;
body, modal 的内容
foot, modal 按钮操作区的内容
-->

<template>
  <el-dialog
    :custom-class="customClass"
    :visible="isShown"
    :append-to-body="isAppendToBody"
    :close-on-click-modal="false"
    :close-on-press-escape="closeOnPressEscape"
    :modal="needModal"
    :show-close="showClose"
    :class="{'el-dialog--xxlarge': size === 'xxlarge','el-dialog--xlarge': size === 'xlarge','el-dialog--large':size ==='large', 'el-dialog--xsmall':size ==='xsmall'}"
    :top="top"
    @close="close"
    @open="open"
  >
    <span slot="title">
      <slot name="head"></slot>
    </span>

    <slot name="body"></slot>

    <span slot="footer">
      <slot name="foot"></slot>
    </span>
  </el-dialog>
</template>

<script>
/* eslint-disable */
export default {
  name: 'Dialog',
  props: {
    visible: Boolean,
    title: String,
    onClosed: Function,
    onOpen: Function,
    top: String,
    size: {
      default: 'small',
      type: String
    },
    closeOnPressEscape: {
      default: true,
      type: Boolean
    },
    isAppendToBody: {
      default: false,
      type: Boolean
    },
    customClass: {
      default: 'custom-dialog',
      type: String
    },
    showClose: {
      default: true,
      type: Boolean
    },
    needModal: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {}
  },

  computed: {
    isShown() {
      return this.visible
    }
  },

  mounted() {},

  methods: {
    close() {
      this.$emit('onClosed')
    },
    open() {
      this.$emit('onOpen')
    }
  }
}
</script>

<style lang="scss">
$pd: 20px;
.el-dialog--xsmall .custom-dialog {
    width: 470px;
}
.el-dialog--small .custom-dialog {
    width: 570px;
}
.el-dialog--large .custom-dialog {
    width: 670px;
}
.el-dialog--xlarge .custom-dialog {
    width: 760px;
}
.el-dialog--xxlarge .custom-dialog {
    width: 940px;
}
.el-dialog__header {
    position: relative;
    padding: 0px 2;
    font-size: 16px;
    color: #333333;
    line-height: 50px;
    &:after {
        content: ' ';
        position: absolute;
        left: $pd;
        right: $pd;
        bottom: 0;
        height: 1px;
        background: #eee;
        z-index: 10;
    }

    .el-tag {
        padding: 0 10px;
    }
}
.el-dialog__close {
    font-size: 20px;
    color: #999999;
}

.el-dialog__body {
    padding: 20px $pd 0;
}

.el-dialog__footer {
    padding-left: $pd;
    padding-right: $pd;
    padding-bottom: $pd;
    .el-button {
        width: 80px;
        font-size: 14px;
    }
}

.el-form-item {
    margin-bottom: 20px;
}

//统一设置弹窗内 文本域的高度
.el-textarea {
    textarea {
        height: 75px;
        min-height: 75px;
    }
}

//弹窗中的日期，统一显示100%
.el-date-editor {
    &.el-input {
        width: 100%;
    }
}

//限制宽度，超过显示...
.head-long {
    display: block;
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
