import deLocale from 'element-ui/lib/locale/lang/de'
export default {
    ...deLocale,
    1127: 'herunterladen',
    1128: 'Anmelden',
    1129: 'Außermarktlich',
    1130: 'Währung',
    1131: 'Preis',
    1132: 'Ändern',
    1133: 'Grafik',
    1134: 'Details',
    1135: 'Erstellen Sie jetzt Ihr Kryptowährungsportfolio',
    1136: '{appName} hat mehrere Funktionen, die es zum besten Ort machen, um mit dem Handel zu beginnen',
    1137: 'Ihr Portfolio verwalten',
    1138: 'Kaufen und verkaufen Sie beliebte digitale Währungen und verfolgen Sie sie alle an einem Ort',
    1139: 'Wiederkehrende Käufe',
    1140: 'Investieren Sie langsam in Kryptowährungen, indem Sie tägliche, wöchentliche oder monatliche Käufe vereinbaren',
    1141: 'Tresorschutz',
    1142: 'Bewahren Sie Ihr Geld für zusätzliche Sicherheit in einem Tresor auf und verzögern Sie Auszahlungen',
    1143: 'Mobile App',
    1144: 'Bleiben Sie mit der App {appName} für Android oder iOS auf dem Markt',
    1145: 'Während der gehebelte Handel mit exotischen Paaren großartig ist, wäre es noch besser, einen Discord-Server voller Meme und Alpha zu haben',
    1146: 'Kontaktieren Sie uns',

    11081: 'Elite-Handelsplattform: Halten Sie den Handel schnell, stabil und effizient',
    11031: 'Unterstützt mehrere PC- und mobile Betriebssysteme',
    1126: 'Handelsplattform',
    11261: 'Beliebteste Forex-Handelsplattform',
    11191: 'Transaktionen jederzeit und überall öffnen',
    nav0: 'Startseite',
    nav1: 'Zitate',
    nav2: 'Münztransaktion',
    nav3: 'Vertragstransaktion',
    nav3_2: 'Vertragstransaktion',
    nav3_3: 'Vertragskonto',
    nav4: 'Finanzmanagement',
    nav5: 'Begriffsbeschreibung',
    nav6: 'Benutzervereinbarung',
    nav7: 'Datenschutzerklärung',
    nav8: 'Rechtliche Erklärung',
    nav9: 'Handelsspezifikation',
    nav10: 'Hilfe für Anfänger',
    nav11: 'FAQ',
    nav12: 'Transaktionsgebührensatz',
    nav13: 'Über uns',
    nav14: 'Dienst',
    operate: 'Operation',
    homeTitle_1_1: 'Die weltweit führende Handelsplattform für digitale Vermögenswerte',
    homeTitle_1_2: 'Die beliebteste Handelsplattform für digitale Vermögenswerte, Zugang zu den leistungsstärksten und bequemsten technischen Analyse- und Handelstools auf dem Markt. ',
    register: 'Jetzt registrieren',
    nowtrans: 'Transaktion sofort',
    logIn: 'Einloggen',
    whats: 'WhatsApp',
    service: 'Kundendienst',
    home_1_2: 'Carrod Securities ist Ihre vertrauenswürdige Devisenbörse',
    home_1_3: 'Edelmetalle, CFD-Plattform',
    home_2_1: 'Kundengelder werden sicher im globalen, von Banken getrennten Treuhandfonds auf AA-Ebene aufbewahrt',
    home_2_2: 'Weniger Spreads und stabiler Server',
    footer1: `Risikowarnung: Die vom Unternehmen angebotenen Finanzprodukte umfassen Differenzkontrakte ('CFDs') und andere komplexe Finanzprodukte. Der Handel mit CFDs birgt ein hohes Risiko, da Leverage gleichzeitig gut und schlecht für Sie sein kann. Daher sind CFDs möglicherweise nicht für alle Anleger geeignet, da sie Ihr gesamtes Anlagekapital verlieren können. Sie sollten niemals Geld anlegen, dessen Verlust Sie sich nicht leisten können. Bevor Sie mit den angebotenen komplexen Finanzprodukten handeln, stellen Sie bitte sicher, dass Sie die damit verbundenen Risiken verstehen. Ihnen wird ein beschränktes, nicht ausschließliches und nicht übertragbares Recht eingeräumt, das auf dieser Website bereitgestellte IP nur für persönliche und nicht kommerzielle Zwecke im Zusammenhang mit den auf der Website bereitgestellten Diensten zu nutzen. `,
    footer2: 'Bitte beachten Sie, dass sich die Informationen auf dieser Website nicht an Einwohner eines Landes oder einer Gerichtsbarkeit richten, die von den auf der Website beschriebenen Gesetzen und Vorschriften abweichen. Carrod Securities Co Ltd ist kein Finanzberater und wird Ihnen nur allgemein beraten. . wird keine Meinungen oder Vorschläge zum Erwerb, Halten oder Verkauf von Margin-Handel abgeben.',
    1: 'Produktname',
    2: 'Erwartete Tagesrendite',
    3: 'Produktschluss',
    4: 'Anfangsinvestitionsbetrag',
    5: 'Bedienung',
    6: 'Kauf',
    7: 'Restwährungsmanagement',
    8: 'Flexibles Finanzmanagement, jeden Tag gewinnen',
    9: 'Über uns',
    10: 'Benutzervereinbarung',
    11: 'Datenschutzerklärung',
    12: 'Rechtliche Erklärung',
    13: 'Handelsregeln',
    14: 'Begriffsbeschreibung',
    15: 'Dienst',
    16: 'Anfängerhilfe',
    17: 'Häufig gestellte Fragen',
    18: 'Transaktionsgebührensatz',
    19: 'Zweites Kontrakthandelssystem für digitale Währungen',
    20: 'Schnell, bequem, keine Umlage, Risiko beherrschbar',
    21: 'Systemeinführung',
    22: 'Mit der rasanten Entwicklung digitaler Währungs-Futures hält die Mehrheit der Nutzer immer mehr Vermögenswerte, und auch die Handelsmethoden für virtuelle Währungen sind vielfältiger geworden. Die Nutzer setzen zunehmend auf schnelle und effiziente Transaktionsabwicklungsmethoden, daher wurde auch der zweite Vertrag hoch gelobt. Der zweite von Mutual Cloud gestartete Kontrakt ist ein Handelssystem zur Abwicklung von virtuellen Währungskontrakten in Cent-Einheiten. Das System unterstützt Marktpreishandel und Limitpreishandel; Benutzer können Call- und Put-Optionen kaufen, mit geringem Kapitaleinsatz, niedrigem Schwellenwert und kurzem Abwicklungszyklus, unterteilt in 1 Minute, 2 Minuten, 3 Minuten usw., und das System kann unabhängig konfiguriert werden. Das System verfügt über ein rigoroses Risikokontrollsystem, bei dem die höchsten und niedrigsten Schwankungen festgelegt werden. Wenn das Limit überschritten wird, werden die Quoten automatisch auf Null zurückgesetzt. ',
    23: 'K-Linienquelle',
    24: 'Der Handel hängt hauptsächlich von den Marktnotierungen externer Börsen für den Handel ab',
    25: 'Unterstützungswährung',
    251: 'Einzahlungsmethode',
    252: 'Einzahlungsmünzen',
    26: 'Aufladen über Wallet-Adresse',
    27: 'Kauf über C2C-Transaktion',
    28: 'Erfüllen Sie die Anforderungen der Plattform zur Ausgabe von Münzen',
    281: 'Kauf über OTC-Transaktion',
    282: 'Die Plattform muss das Risiko des Währungs-Floating nicht tragen',
    29: 'Vertragsmodellgestaltung',
    30: 'Handelsmethode',
    31: 'Einstellung der Abrechnungszeit',
    32: 'Handelsrichtung',
    33: 'Transaktionskontrolle',
    34: 'Risikokontrolle',
    35: 'Kauflimit',
    36: 'Großschäden durch Kunden verhindern',
    38: 'Termin für die Auftragserteilung festlegen',
    39: 'Gewährleisten Sie genügend Liquidationszeit für die Auslosung',
    40: 'Auf- und Ableitungen einrichten',
    41: 'Trigger die Quoten auf Null, um einseitige Transaktionen zu verhindern',
    42: 'Großschäden vermeiden',
    43: 'Ein-Klick-Schließen-Schaltfläche',
    44: 'Kunden können in Extremsituationen auf die Schaltfläche klicken, um eine Position zu schließen',
    46: 'Dienstüberwachung',
    47: 'Umfassende Überwachung der Dienste, um eine rechtzeitige Reaktion auf Dienstausfälle sicherzustellen und rechtzeitig E-Mail-Benachrichtigungen über Fehler einzuleiten',
    48: 'Serviceunterstützung',
    49: 'Wir nähern uns den Kunden von ganzem Herzen, wir sind enthusiastisch, aufmerksam, geduldig und vorsichtig. Wir haben ein professionelles Software-Forschungs- und Entwicklungsteam, eine strenge Plattformüberwachung und -verwaltung und ein professionelles One-Stop-Service-Team.',
    50: 'Eins, Handelspaar auswählen',
    51: 'Zwei, Preis prüfen',
    52: 'Schauen Sie sich den Markttransaktionspreis an und wählen Sie den richtigen Preis, um eine Bestellung aufzugeben. ',
    53: 'Drei, kaufen nach Ausfüllen der Bestellung',
    54: 'Wählen Sie das Transaktionspaar, das gekauft werden muss (Währungs-zu-Währungs-Transaktion bezieht sich auf die Verwendung eines digitalen Vermögenswerts zum Austausch gegen einen anderen digitalen Vermögenswert)',
    55: 'Geben Sie den Kaufpreis und das Kaufvolumen ein und warten Sie auf die Markttransaktion. ',
    57: 'Währungstransaktionen in Sekunden verstehen',
    58: 'Sichere und zuverlässige, schnelle Transaktion',
    59: 'Handelskurse',
    60: 'Auf Lager',
    601: 'Bereitstellung fortschrittlicher digitaler Vermögenswerte, CFDs und Rohstoffhandelsplattformen für globale Anleger',
    61: 'Was ist die Stelle? ',
    62: 'Spot ist für Futures. Spot und Futures sind ähnlich, der Hauptunterschied liegt im Unterschied in Leverage und Margin, Transaktionslieferzeit usw.',
    63: 'Zukünfte',
    64: 'Was sind Futures?',
    65: 'Futures-Kontrakte, auch Futures genannt, sind eine zeitübergreifende Handelsmethode. Mit der Unterzeichnung eines Vertrages verpflichten sich Käufer und Verkäufer, eine bestimmte Menge Spot gemäß der angegebenen Zeit, des Preises und anderer Handelsbedingungen zu liefern. ',
    66: 'Finanzmanagement vertrauen',
    67: 'Was ist Treuhandfinanzierung?',
    68: "Dezentrale Finanzierung ist eine Art Blockchain-basierte Finanzierung. Sie verlässt sich nicht auf Finanzinstitute wie Broker, Börsen oder Banken, um Finanzinstrumente bereitzustellen, sondern nutzt intelligente Verträge auf der Blockchain, um Finanzaktivitäten durchzuführen.",
    69: 'C2C',
    70: 'Was ist C2C',
    71: 'C2C, auch bekannt als "Private-to-Private" ist eine Transaktionsform, es bezieht sich auf die Form der Transaktion von Individuum zu Individuum',
    72: 'Handelsservice aus einer Hand',
    73: 'Name',
    74: 'Preis',
    75: '24H Höhen und Tiefen',
    76: '24H-Lautstärke',
    77: 'Zitate',
    78: 'Stabile und sichere Aggregation',
    79: 'Sicherheitsschutz auf Finanzniveau',
    80: 'Unvoreingenommene Zero-Knowledge-Proof-Privacy- und Vertraulichkeitstechnologie zum Schutz der Privatsphäre der Benutzer, mehrerer Signaturen, mehrschichtiger Architektur und anderer Sicherheitskonzepte. ',
    81: 'Hochleistungs-Matching-Engine',
    82: 'Adoptieren Sie fortschrittliche verteilte Cluster-Architektur und Microservice-Entwicklungsmethoden. Unterstützt mehr als 3 Millionen Transaktionen pro Sekunde gleichzeitig. ',
    83: '24 Stunden ununterbrochener Dienst',
    84: 'Der Kundenservice ist 24 Stunden am Tag, sieben Tage die Woche online, um Ihnen verschiedene Fragen zu beantworten. ',
    85: 'Offene Transaktionen jederzeit und überall',
    86: 'Brandneue Überarbeitung, um den Handelsanforderungen verschiedener Szenarien jederzeit gerecht zu werden',
    87: '24 * 7 Stunden Allwetter, Vollzeit-Betriebsmodus, Kundenservice wird Ihre diesbezüglichen Anfragen so schnell wie möglich beantworten. ',
    88: 'Handelspaar',
    89: 'Neuester Preis',
    90: 'Cambio',
    91: 'Höchster Preis',
    92: 'Niedrigster Preis',
    93: 'Lautstärke',
    94: 'Anmelden',
    95: 'Registrieren',
    fee: {
        1: 'Transaktionsgebührensatz',
        2: 'Währungstransaktionsgebühr',
        3: 'Vertragstransaktionsgebühr'
    },
    law: {
        1: 'Rechtliche Erklärung',
        2: 'Erster Artikel',
        3: 'Diese Plattform ist bestrebt, eine gesetzeskonforme, sichere und zuverlässige Umgebung für den Handel mit digitalen Vermögenswerten zu schaffen, und verbietet es Benutzern, diese Website zu nutzen, um Geldwäsche, Schmuggel, kommerzielle Bestechung, böswillige Marktmanipulation und andere illegale Handelsaktivitäten oder illegale Aktivitäten zu betreiben Aktivitäten. Zu diesem Zweck tun wir unser Bestes, um strenge Anti-Geldwäsche- und Anti-Terror-Programme zu formulieren und wirksame Maßnahmen zur Kontrolle von Sicherheitsrisiken zu ergreifen, um den Schutz der legitimen Rechte und Interessen der Nutzer zu maximieren und einen guten Markenruf auf der Plattform zu erhalten selbst',
        4: 'Zweiter Artikel',
        5: 'Wenn die zuständigen Behörden entsprechende Ermittlungsunterlagen vorlegen und diese Website auffordern, bei Ermittlungen gegen benannte Nutzer mitzuarbeiten, oder wenn Maßnahmen wie das Verschließen, Einfrieren oder Übertragen von Nutzerkonten ergriffen werden, überprüft diese Website die Gültigkeit und Authentizität gerichtlicher Dokumente Nach dem Sex werden in Übereinstimmung mit den Anforderungen der von der Plattform bestimmten zuständigen Behörde, die bei der Bereitstellung entsprechender Benutzerdaten oder bei der Durchführung entsprechender Vorgänge hilft, Datenschutzverluste des Benutzers, Kontoinoperabilität und andere den Benutzern verursachte Verluste usw Website Wir übernehmen keine Verantwortung. ',
        6: 'Artikel 3',
        7: 'Alle Benutzer, die die Dienste dieser Website nutzen, sollten vor dem Handel wissen: Digitale Vermögenswerte werden von keiner Regierung oder Zentralbank unterstützt und der Preis digitaler Vermögenswerte schwankt stark, das Halten oder Verwenden digitaler Vermögenswerte birgt ein höheres Risiko. Bevor Sie eine Handelsentscheidung treffen, sollten Sie mit Ihrer eigenen finanziellen Situation rational und umsichtig sein, um festzustellen, ob sie für Sie geeignet ist.',
        8: 'Artikel 4',
        9: 'Jeder, der sich in irgendeiner Weise auf dieser Website einloggt oder direkt oder indirekt die Dienste dieser Website nutzt, erklärt sich freiwillig mit den Einschränkungen dieser Website-Erklärung einverstanden. '
    },
    rules: {
        1: 'Handelsregeln',
        2: 'Nach dem Erstellen einer Bestellung sollte der Käufer:',
        3: '1, schließe die Zahlung innerhalb der in der Bestellung angegebenen Zahlungsfrist ab und klicke dann auf "Ich habe bezahlt";',
        4: '2, verwenden Sie meine Real-Name-Zahlungsmethode, bestellen Sie die Zahlung gemäß dem Bestellbetrag und dem in der Bestellung angegebenen Zahlungskonto, um die Bestellzahlung in Echtzeit abzuschließen;',
        5: "3. Wenn Sie eine Zahlungsmethode ohne Echtzeit-Zahlung wählen, um die Bestellung zu bezahlen, müssen Sie im Voraus die Zustimmung des Verkäufers einholen",
        6: '4. Der Zahlungsauftrag darf keine "digitale Währung, BTC, Bitcoin" und andere Informationen enthalten;',
        7: 'Grundsätze, die der Verkäufer befolgen sollte',
        8: 'Nachdem die Bestellung generiert wurde, sollte der Verkäufer',
        9: '1. Geben Sie ein von der Plattform zertifiziertes Empfangskonto an;',
        10: '2, nach Erhalt der vollständigen Zahlung des Käufers, die Freigabe innerhalb der in der Bestellung angegebenen Zeit abschließen;',
        11: "3. Ohne Zustimmung des Käufers ist der Käufer/Kundendienst nicht verpflichtet, die Bestellung zu stornieren.",
        12: '4. Ohne Zustimmung des Käufers ist kein Widerspruch gegen den erstellten Bestellpreis zulässig;'
    },
    help: {
        1: 'Hilfe für Anfänger',
        2: 'Limit Order',
        3: 'Ein Auftrag zum Kauf/Verkauf zu einem bestimmten Preis oder besser. ',
        4: 'Marktordnung',

        5: 'Ein Auftrag zum sofortigen Kauf oder Verkauf zum aktuellen Marktpreis. ',
        6: 'Limit-Stop-Loss-Order',
        7: 'Ein Auftrag zum Verkauf oder Schließen einer Position zu einem festgelegten Preis, nachdem der Markt einen bestimmten Preis erreicht hat, um Verluste zu kontrollieren. ',
        8: 'Limit Price Take Profit Order',
        9: 'Ähnlich einer Stop-Limit-Order ist es auch eine Order zum Verkauf oder Schließen einer Position zu einem festgelegten Preis, nachdem der Markt einen bestimmten Preis erreicht hat, der zur Gewinnkontrolle verwendet wird. ',
        10: 'Markt-Stop-Loss-Order',
        11: 'Ein Auftrag zum Verkauf oder Schließen einer Position zum Marktpreis, nachdem der Markt einen bestimmten Preis erreicht hat, um Verluste zu kontrollieren. ',
        12: 'Marktpreis-Take-Profit-Order',
        13: 'Ähnlich wie eine Market-Stop-Loss-Order ist es auch eine Order zum Verkauf oder Schließen einer Position zum Marktpreis, nachdem der Markt einen bestimmten Preis erreicht hat, der zur Gewinnkontrolle verwendet wird. ',
        14: 'Wie hoch ist die Marge für die Eröffnung einer Position? ',
        15: 'Margin Trading bedeutet, dass Sie nur einen bestimmten Prozentsatz der Margin zahlen müssen, um den Kontrakt zu kaufen und zu verkaufen. Je nach gewähltem Hebel ist die Margin Ratio ein kleineres Vielfaches des Kontraktwertes. Es kann Ihre Gewinne steigern, Sie können aber auch zusätzlichen Risiken ausgesetzt sein. ',
        16: 'Was ist eine Liquidation und was ist eine Punktion? ',
        17: 'Wenn das verbleibende Guthaben auf Ihrem Konto geringer ist als der vom Handelsvertrag geforderte variable Gewinn und Verlust, wird Ihre Position von der Börse zwangsweise geschlossen, auch als Liquidation bekannt. Da der Preis beim Schließen der Position anhand des Marktpreises bestimmt wird, haben Sie bei einem extrem ungünstigen Transaktionspreis möglicherweise nicht genügend Mittel, um den Verlust der Transaktion zu decken, was zu einem negativen Wert auf Ihrem Konto führt als Positions-Shorting bezeichnet. '
    },
    96: 'Handelspaar auswählen',
    97: 'Preis prüfen',
    98: 'Kaufen nach Ausfüllen der Bestellung',
    99: 'Kontoanmeldung',
    100: 'Willkommen zurück! Melden Sie sich mit Ihrer E-Mail oder Telefonnummer an',
    101: 'Postfach',
    102: 'Mobil',
    103: 'Passwort vergessen? ',
    104: 'Jetzt registrieren',
    105: 'Passwort',
    106: 'Bitte geben Sie Ihre Telefonnummer ein',
    107: 'Fehler beim Formatieren der Mobiltelefonnummer',
    108: 'Bitte E-Mail eingeben',
    109: 'Mailbox-Formatfehler',
    110: 'Bitte geben Sie ein Passwort ein',
    111: 'Passwort ist mindestens 6 Zeichen lang',
    112: 'Passwort ist bis zu 32 Bit lang',
    113: 'Sprache und Region',
    114: '{appName}-Konto erstellen',
    115: 'Registrieren Sie sich mit Ihrer E-Mail- oder Handynummer',
    116: 'Empfehlungscode',
    117: 'Zustimmen',
    118: 'und',
    119: 'Benutzervereinbarung',
    120: 'Datenschutzerklärung',
    121: 'Angemeldet? ',
    122: 'Verifizierungscode',
    123: 'Senden',
    124: 'Bitte geben Sie den Bestätigungscode ein',
    125: 'Bestätigungscode muss 6-stellig sein',
    126: 'Passwort zurücksetzen',
    127: 'OK',
    128: 'Verwenden Sie Ihre E-Mail oder Ihr Mobiltelefon, um das Login-Passwort zurückzusetzen',
    129: 'Echtname-Authentifizierung',
    130: 'Der Abschluss der Echtnamen-Authentifizierung trägt zum Schutz der Kontosicherheit bei, erhöht die Auszahlungslimits und die Transaktionsberechtigungen',
    131: 'Gehe zur Authentifizierung',
    132: 'Authentifizierung',
    133: 'Wird verwendet, um sich anzumelden, Münzen abzuheben, Passwörter abzurufen, Sicherheitseinstellungen zu ändern',
    134: 'Verifiziert',
    135: 'Passwortverwaltung',
    136: 'Login-Passwort',
    137: 'Fondspasswort',
    138: 'Wird verwendet, um die Kontosicherheit zu schützen',
    139: 'Wird verwendet, um die Sicherheit von Geldern zu schützen',
    140: 'Persönliche Einstellungen',
    141: 'Farbpräferenz',
    142: 'Einstellungen',
    143: 'Ändern',
    144: 'Legen Sie den Candlestick-Chart fest, kaufen Sie Farben auf und ab',
    145: 'Preisfindungsmethode',
    146: 'Kontobewertungseinheit,',
    147: 'Standard',
    148: 'Grün steigt und Rot fällt',
    149: 'Rot oben und grün unten',
    150: 'Anmeldepasswort ändern',
    151: 'Fondspasswort ändern',
    152: 'Altes Passwort',
    153: 'Neues Passwort',
    154: 'Bitte geben Sie das alte Passwort ein',
    155: 'Neue Telefonnummer',
    156: 'Neues Postfach',
    157: 'Mobilfunknummer binden',
    158: 'Postfach binden',
    159: 'Mobiltelefonnummer ändern',
    160: 'Postfach ändern',
    161: 'Authentifiziert',
    162: 'Einen Spitznamen festlegen',
    163: 'Bitte geben Sie ihren Spitznamen ein',
    164: 'Abbrechen',
    165: 'Land',
    166: 'Vorname',
    167: 'Nachname',
    168: 'Zertifikatstyp',
    169: 'Zertifikatsnummer',
    170: 'Bitte wählen Sie ein Land',
    171: 'Bitte Vornamen eingeben',
    172: 'Bitte geben Sie den Nachnamen ein',
    173: 'Bitte geben Sie die ID-Nummer ein',
    174: 'Bitte laden Sie das Vorderfoto des Ausweises hoch',
    175: 'Bitte laden Sie ein Foto von der Rückseite Ihres Ausweises hoch',
    176: 'Bitte laden Sie ein Foto Ihres Handheld-Ausweises hoch',
    177: 'Senden',
    178: 'Zertifizierung',
    179: 'Ausweis',
    180: 'Reisepass',
    181: 'Weiter',
    182: 'Passfotos hochladen und Authentifizierung abschließen',
    183: 'Hochladen fehlgeschlagen',
    184: 'Bildformatfehler',
    185: 'Bild ist zu groß',
    186: 'Laden',
    187: 'Asset-Übersicht',
    188: 'Überweisung',
    189: 'Rückzug',
    190: 'Einzahlungsmünzen',
    191: 'Finanzunterlagen',
    192: 'Einfrieren',
    193: 'Verfügbar',
    194: 'Gesamt',
    195: 'Kleine Währung verstecken',
    196: 'Währung',
    197: 'USDT-Bewertung',
    198: 'Suche',
    199: 'Netzwerk aufladen',
    200: 'Pfandadresse',
    201: 'Coin-Out-Adresse',
    202: 'Aufladebetrag',
    203: 'Screenshot der Münzeinzahlung',
    204: 'Bemerkungen',
    205: 'Adresse kopieren',
    206: 'Kopie erfolgreich',
    207: 'Rückkehr',
    208: 'Optional',
    209: 'Bitte geben Sie die Anzahl der aufzuladenden Münzen ein',
    210: 'Bitte eine gültige Nummer',
    211: 'Bitte geben Sie die Währungsadresse ein',
    212: 'Bitte laden Sie einen Screenshot der Münzeinzahlung hoch',
    213: 'Abzugsadresse',
    214: 'Auszahlungsbetrag',
    215: 'Verfügbares Guthaben',
    216: 'Alle',
    217: 'Bearbeitungsgebühr',
    218: 'Bitte geben Sie den Betrag der abgehobenen Münzen ein',
    219: 'Bitte geben Sie das Fondspasswort ein',
    220: 'Fondspasswort muss 6-stellig sein',
    221: 'Standardpasswort',
    222: 'Sie haben die entsprechende Brieftasche noch nicht gebunden! ',
    223: 'Gehe zum Hinzufügen',
    224: 'Einreichung erfolgreich',
    225: 'Sammlungs-Wallet hinzufügen',
    226: 'Adresse',
    227: 'Bitte geben Sie das Netzwerk ein',
    228: 'Bitte geben Sie die Adresse ein',
    229: 'Netzwerk',
    230: 'Abhebungsnetz',
    231: 'Adresslänge sind die niedrigsten 32 Bit',
    232: 'Geldtransfer',
    233: 'Überweisungsbetrag',
    234: 'Bitte Überweisungsbetrag eingeben',
    235: 'Unzureichendes Guthaben',
    236: 'Kann übertragen werden',
    237: 'Wischrichtung',
    238: 'Überweisung',
    239: 'Von',
    240: 'zu',
    241: 'Finanzunterlagen',
    242: 'Münzeinzahlungsaufzeichnung',
    243: 'Abhebungsprotokoll',
    244: 'Datensätze übertragen',
    245: 'Menge',
    246: 'Status',
    247: 'Erzeugte Zeit',
    248: 'Zeit bestätigen',
    249: 'Nicht mehr',
    250: 'Preis',
    253: 'Handelspaar',
    254: 'Cambio',
    255: 'Noch keine Daten',
    256: 'Suma',
    257: 'Hoch',
    258: 'Niedrig',
    259: 'Handelsvolumen',
    260: 'Öffnen',
    261: 'Empfangen',
    262: 'Betrag',
    263: 'Terminhandel',
    264: 'bullish',
    265: 'Bearisch',
    266: 'Produktname',
    267: 'Vertrag anzeigen',
    268: 'Betrag kaufen',
    269: 'Bitte geben Sie den Kaufbetrag ein',
    270: 'Transaktionsgebühr',
    271: 'Mindestkauf',
    272: 'Status',
    273: 'Ausstehende Lieferung',
    274: 'Geliefert',
    275: 'Bestellung erfolgreich aufgegeben',
    276: 'Produktname',
    277: 'Gewinn und Verlust',
    278: 'Preis kaufen',
    279: 'Lieferpreis',
    280: 'Weiterhandeln',
    283: 'Gewinn',
    284: 'Bestellung bestätigen',
    285: 'Historische Befehle',
    286: 'Aufträge in Wartestellung',
    287: 'Erzeugte Zeit',
    288: 'Kaufen',
    289: 'Verkaufen',
    290: 'Anzahl Transaktionen',
    291: 'Transaktionsbetrag',
    292: 'Vertrag',
    293: 'Lieferzeit',
    294: 'Lieferpreis',
    295: 'Sekunden',
    296: 'Minuten',
    297: 'Stunde',
    298: 'Tag',
    299: 'Restzeit',
    300: 'Mehr anzeigen',
    301: 'Währungstransaktion',
    302: 'Verkaufsbetrag',
    303: 'Bitte geben Sie den Verkaufsbetrag ein',
    304: 'Fiat-Währungstransaktion',
    305: 'Vermögenswerte',
    306: 'Abmelden',
    307: 'Erfolgreich registriert',
    308: 'Bitte geben Sie eine Empfehlung ein',
    309: 'Erfolg',
    310: 'Fehlgeschlagen',
    311: 'Bestätigen',
    312: 'Zahlungsmethode',
    313: 'Abzugsadresse',
    314: 'Löschen',
    315: 'Operation',
    316: 'Ihre Brieftasche',
    317: 'Möchten Sie wirklich löschen? ',
    318: 'Erfolgreich löschen',
    319: 'Authentifizierung fehlgeschlagen',
    320: 'Rezertifizierung',
    330: 'Geldbörse für die Abhebung digitaler Vermögenswerte',
    331: 'Bankkarte binden, Bargeld in Fiat-Währung abheben',
    332: 'Bitte geben Sie eine gültige Adresse ein',
    1000: 'Tag',
    1001: 'In Bearbeitung',
    1002: 'Geschlossen',
    1003: 'Regeln',
    1004: 'Produktvorteil',
    1005: 'Speichere wie du nimmst',
    1006: 'Dividende abgelaufen',
    1007: 'Tagesbonus',
    1008: 'Live-Einlagenzins',
    1009: '100% Kapitalgarantie',
    1010: 'Normales Einkommen während der Ferien',
    1011: 'Ruhe am nächsten Tag',
    1012: "Produktvorteil",
    1013: 'Mitglieder zahlen 200.000 USDT auf der Plattform ein, wählen ein Vermögensverwaltungsprodukt mit einer Laufzeit von 14 Tagen und einem täglichen Einkommen von 0,5 %, und das tägliche Einkommen ist wie folgt: 200.000 * 0,5 % = 1.000 USDT Das heißt, nach 14 Tagen Sie kann 14.000 USDT an Einkommen erhalten, und das Einkommen wird täglich ausgegeben. Zahlen Sie das Kapital ein und es wird bei Fälligkeit automatisch auf Ihr Konto zurückerstattet.',
    1014: 'Gewahrsamsverfügung',
    1015: 'Hosting',
    1016: 'Abgeschlossen',
    1017: 'Eingelöst',
    1018: 'Noch keine Daten',
    1019: 'Kaufbetrag',
    1020: 'Tagesrendite',
    1021: 'VERTRAUEN.6',
    1022: 'Zahlungszeitpunkt',
    1023: 'Einlösungsdatum',
    1024: 'Abgelaufen',
    1025: 'Kürzlich (Tageseinkommen)',
    1026: 'Dividendenzeit',
    1027: 'Jeden Tag',
    1028: 'Depotbetrag',
    1029: 'Rückgabe nach Ablauf',
    1030: 'Verfügbares Guthaben',
    1031: "Geschätztes Gesamteinkommen",
    1032: 'Geldtransfer',
    1033: 'Alle',
    1034: 'Einzelgrenze',
    1035: 'Bearbeitungsgebühr',
    1036: 'Ich habe gelesen und zugestimmt',
    1037: "Plattform-Finanzmanagement-Dienstleistungsvertrag",
    1038: 'OK',
    1039: 'Jetzt bewerben',
    1040: 'Kaufen',
    1041: 'Verkaufen',
    1042: 'Überweisung',
    1043: 'Bitte Betrag eingeben',
    1044: 'Fiat-Währungskategorie',
    1045: 'Verkaufte Menge',
    1046: 'Maximal zum Verkauf verfügbar',
    1047: 'Alle',
    1048: 'Referenz-Einheitspreis',
    1049: 'Ihre Zahlungsmethode',
    1050: 'Bankname',
    1051: 'Bankkontonummer',
    1052: 'Name',
    1053: 'Sie haben die entsprechende Zahlungsmethode nicht gebunden und können nicht verkauft werden. ',
    1054: 'Auftragsdatensatz',
    1055: 'Filter',
    1056: 'Typ',
    1057: 'Status',
    1058: 'Verarbeitung',
    1059: 'Bestanden',
    1060: 'Fehlgeschlagen',
    1061: 'Menge',
    1062: 'Erfolg',
    1063: 'Aufgabe ausführen',
    1064: `Berechnen Sie die heutigen Ergebnisse`,
    1065: "kumuliertes Einkommen",
    1066: 'Ordnung in Untersuchungshaft',
    1067: 'Treuhandkonto ist nicht eröffnet',
    1068: "Eröffnen Sie ein Kommunikations-Treuhandkonto, genießen Sie ein stabiles passives Einkommen und erleichtern Sie die Wertsteigerung.",
    1069: 'Aktivierung beantragen bedeutet, dass Sie diese gelesen und akzeptiert haben',
    1070: '"Plattform-Finanzmanagement-Dienstleistungsvertrag"',
    1071: 'Aktivierung beantragen',
    1072: 'Abbrechen',
    1073: 'Ausführen...',
    1074: 'Erlösung',
    1075: "Möchtest du es wirklich einlösen?",
    1076: 'Die vorzeitige Rückzahlung erfordert die Zahlung eines pauschalierten Schadensersatzes, der diesmal entsprechend Ihrer Art der Finanzverwaltung abgezogen wird',
    1077: ', Ist die Einlösung bestätigt?',
    1078: 'Gesendet',
    1079: 'Bitte geben Sie den Bestätigungscode ein',
    1080: 'Aufforderung',
    1081: 'Gewinn',
    1082: 'Zeit',
    1083: 'Zahlungsmethode in legaler Währung hinzufügen',
    1084: 'Bestellstatus',
    1085: 'Währung',
    1086: 'Nummer der gesetzlichen Währung',
    1087: 'Bankkontonummer',
    1088: 'Methode zum Sammeln von fettem Geld',
    1089: 'Sammlungsmethode hinzufügen',
    1090: 'Bestehende Zahlungsmethode',
    1091: 'Fiat-Währung',
    1092: 'Bankleitzahl',
    1093: 'Möchten Sie die Zahlungsmethode wirklich löschen?',
    1094: 'Bitte geben Sie die Bankkontonummer ein',
    1095: 'Bitte geben Sie den Banknamen ein',
    1096: 'Bitte geben Sie die gesetzliche Währung ein',
    1097: 'Bitte wählen',
    1098: 'Bitte geben Sie Ihren Namen ein',
    1099: 'Zweig',
    1100: 'Unbegrenzt',
    1101: 'Sie haben Ihren richtigen Namen noch nicht bestätigt',
    1102: 'Weißbuch',
    1103: 'Unsere Features',
    1104: 'Wir sind sicher',
    1105: 'Wir sind mächtig',
    1106: 'Beste Unterstützung',
    1107: 'Wir sind global',
    1108: 'Wie es funktioniert',
    1109: 'Benutzer in mehr als 20 Ländern und Regionen nutzen unsere Plattform und sind weithin anerkannt',
    1110: 'Erweiterte Datenverschlüsselung',
    1111: 'Reiche Vielfalt an Kryptowährungen',
    '1111_2': 'Große Auswahl an Edelmetallen und Kryptowährungen',
    1112: '90+ Kryptowährungen aufgelistet',
    1113: 'Ihre Transaktionsdaten werden durch Ende-zu-Ende-Verschlüsselung gesichert, wodurch sichergestellt wird, dass nur Sie Zugriff auf Ihre persönlichen Daten haben.',
    1114: '43 Milliarden',
    1115: '24h Handelsvolumen',
    1116: '30 Millionen',
    1117: 'Registrierte Benutzer, die uns vertrauen',
    1118: 'Niedrigste Transaktionsgebühren',
    1119: 'Herunterladen',
    1120: 'Name des Finanzinstituts',
    1121: 'Zweigname',
    1122: 'Zweigstellennummer',
    1123: 'Kontonummer',
    1124: 'Name des Empfängers',
    1125: 'Kinjo',

    1148: 'Die vertrauenswürdigste Kryptowährungsplattform',
    1149: 'Hier sind einige Gründe, warum Sie sich für uns entscheiden sollten',
    1150: 'Wir sind sicher',
    1151: 'Unvoreingenommene Zero-Knowledge-sichere Datenschutz- und Vertraulichkeitstechnologie zum Schutz der Privatsphäre der Benutzer, mehrerer Signaturen, mehrschichtiger Architektur und anderer Sicherheitsdesigns ',
    1152: 'Beste Unterstützung',
    1153: '24 * 7 Stunden Allwetter, Vollzeitbetrieb, der Kundendienst wird Ihre diesbezüglichen Anfragen so schnell wie möglich beantworten',
    1154: 'Wir sind global',
    1155: 'Benutzer in mehr als 20 Ländern und Regionen nutzen unsere Plattform und sind weithin anerkannt',
    1156: 'Wir sind mächtig',
    1157: 'Die beliebteste Handelsplattform für digitale Vermögenswerte, Zugang zu den leistungsstärksten und bequemsten technischen Analyse- und Handelstools auf dem Markt',
    1158: 'Reiche Auswahl an Kryptowährungen',
    1159: ' 90+ Kryptowährungen aufgelistet',
    1160: 'Erweiterte Datenverschlüsselung',
    1161: 'Ihre Transaktionsdaten werden durch Ende-zu-Ende-Verschlüsselung gesichert, sodass nur Sie Zugriff auf Ihre persönlichen Daten haben.',
    1162: 'Wir unterstützen eine Vielzahl der beliebtesten digitalen Währungen',
    1163: 'Viele Länder haben lokalisierte Transaktionen eingerichtet',
    1164: 'Verwenden Sie die gesetzliche Währung, um digitale Assets zu kaufen und schnell in die Welt der digitalen Assets einzusteigen',
    1165: 'Handeln Sie weiter, um jederzeit überall hingehen zu können',
    1166: 'Scannen Sie mich zum Herunterladen',
    1167: 'Wechselkurse',
    1168: 'Kaufen und verkaufen Sie über 250 Kryptowährungen mit kürzlich hochgeladenen Wechselkursen',
    1169:'Stay in the know with our app and desktop client',
    1170:'Name',
    1171:'Preis',
    1172:'Veränderung',
    1173:'Diagramm',
    1174:'Handeln',
    1175:'Detail',
    1176:'War',
    1177:'Sicher',
    1178:'Wir haben',
    1179:'Global',
    1180:'Mächtig',
    1181:'Reiche Auswahl an',
    1182:'Kryptowährung',
    1183:'Erweiterte Daten',
    1184:'Verschlüsselung',
    1185:'Bereit anzufangen',
    1186:'Wir sind die weltweit führende Handelsplattform für digitale Vermögenswerte.',
    1187:'Wir sind die weltweit führende Handelsplattform für digitale Vermögenswerte. Wir sind ständig bestrebt, die Sicherheitsfunktionen zu verbessern, um die Sicherheit der digitalen Assets unserer Kunden zu gewährleisten. Unser Modell ist es, Kundenprojekte mit nachhaltigem Wert zu bringen.',

    1188: 'Gesamtversorgung',
    1189: '24 * 7 Allwetter-, Vollzeit-Betriebsmodus, der Kundendienst wird relevante Anfragen so schnell wie möglich für Sie beantworten',
    1190: 'Wir sind global',
    1191: 'Wir sind stark',
    1192: 'Die beliebteste Handelsplattform für digitale Vermögenswerte, erhalten Sie die leistungsstärksten und bequemsten technischen Analyse- und Handelstools auf dem Markt.',
    1193: 'Große Auswahl an Kryptowährungen',
    1194: 'Ihre Transaktionsdaten werden durch Ende-zu-Ende-Verschlüsselung geschützt, sodass nur Sie auf Ihre persönlichen Daten zugreifen können.',
    1195: 'Mindesttransaktionsgebühr',
    1196: 'Vertrauen Sie unseren registrierten Benutzern',
    1197: '24-Stunden-Handelsvolumen',
    1198: '+42 Milliarden',
    1199: '+30 Mio',
    1200: 'Mehrere Länder haben lokalisierte Transaktionsdienstzentren eingerichtet, um ein Blockchain-Ökosystem zu schaffen, das mehrere Geschäftsformen integriert',
    1201: 'Verwenden Sie Fiat-Währung, um digitale Assets zu kaufen und schnell in die Welt der digitalen Assets einzusteigen',
    1202: 'APP download',
    1203: 'Unser Vorteil',
    1204: 'Umfangreiche Währungs- und Währungsinformationen, Bereitstellung von Währungen, Verträgen, legalen Währungstransaktionen und anderen Transaktionsmethoden, professioneller Markt und Tiefe.'
}
