<template>
  <div class="wraper deal" :class="{'m0': modle === '2'}">
    <div
      class="Box row active animated "
      v-animated="{ className: 'fadeInRight' }"
    >
      <div class="col-12">
        <div v-if="modle === '1'" class="wraper-box" >
          <div class="title-box">
            <div class="title-box-text">
              <h1 class="title">{{ $t('19') }}</h1>
              <h1>{{ $t('20') }}</h1>
            </div>
          </div>

          <!-- 內文 -->
          <div class="content-box">
            <div class="content-box-text">
              <!-- <h1 class="title">{{ selectedLangMetas.artical.title }}</h1> -->
              <div
                v-for="(articalItem, k) in selectedLangMetas.artical.articalItems"
                :key="`data_${k}`"
                class="des-big"
                :class="{'des-big-w': k == 2 || k == 4, 'des-big-pur': k == 5}"
              >
                <div class="des-title-box" :class="{'first-box': k === 0}">
                    <!-- <img :src="articalItem.t_icon" class="des-title-icon"> -->
                    <h1 class="des-title">{{ articalItem.t }}</h1>
                    <p>{{ articalItem.des }}</p>
                    <p class="des">{{ articalItem.desp }}</p>
                    <div v-if="articalItem.icons.length > 0">
                        <!-- 合約模型設計 -->
                        <div v-if="articalItem.dealModel" class="icon-box icon-box-hasColor">
                            <div class="img-box img-box-color-l" :class="{'img-box-color-r': m == 1 , 'img-box-color-br': m == 3, 'img-box-color-bl': m == 2}" v-for="(imgs,m) in articalItem.icons" :key="m">
                                <img :src="imgs.img" :alt="imgs.name">
                                <div class="mb10">
                                    <p>{{imgs.name}}</p>
                                </div>
                            </div>
                        </div>
                        <!-- 風險控制 -->
                         <div v-else-if="articalItem.purple" class="icon-box icon-box-hasColor icon-box-purple">
                            <div class="img-box-purple" v-for="(imgs,m) in articalItem.icons" :key="m">
                                <img :src="imgs.img" :alt="imgs.name">
                                <div class="mb10">
                                    <p>{{imgs.name}}</p>
                                </div>
                                <div v-html="imgs.des" class="img-box-des"></div>
                            </div>
                        </div>
                        <div v-else class="icon-box">
                            <div class="img-box" :class="{'img-box-padding': k == 3}" v-for="(imgs,m) in articalItem.icons" :key="m">
                                <img :src="imgs.img" :alt="imgs.name">
                                <div class="mb10">
                                    <p>{{imgs.name}}</p>
                                </div>
                                <div v-html="imgs.des" class="img-box-des"></div>
                            </div>
                        </div>
                    </div>
              
                    <img v-if="articalItem.mainPic" class="mainPic" :src="articalItem.mainPic" />
                    
                </div>    
                <div
                  v-for="(desItem, i) in articalItem.desItems"
                  :key="`data_b_${i}`"
                  class="des"
                >
                  <p
                    class="title"
                    :class="{ 'margin-top-little': i === 0 }"
                  >
                    {{ desItem.t }}
                  </p>
                   <p v-html="desItem.p"></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="modle === '2'" class="wraper-box" >
          <div class="title-box title-box-2">
            <div class="title-box-text">
              <h1 class="title">{{ $t('19') }}</h1>
              <h1>{{ $t('20') }}</h1>
            </div>
          </div>

          <!-- 內文 -->
          <div class="content-box content-box-2">
            <div class="content-box-text">
              <!-- <h1 class="title">{{ selectedLangMetas.artical.title }}</h1> -->
             <commonInfo2
								v-for="item in selectedLangMetas2"
								:key="item.title"
								:datas="item"
              ></commonInfo2> 
            </div>
						<div class="modle-2-footer-des">
							<div class="deal-footer-items" v-for="(m2,mIndex) in selectedLangMetas.artical.articalItems[3].icons" :key="mIndex">
								<h2>{{mIndex+1}},{{m2.name}}</h2><br/>
								{{m2.des}}
							</div>
						</div>
							<div class="deal-footer-2">
								<h1>{{selectedLangMetas.artical.articalItems[6].t}}</h1><br/>
								<p>{{selectedLangMetas.artical.articalItems[6].des}}</p>
							</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonInfo2 from "../components/CommonInfo2";
export default {
  name: "Deal",
  props: ['lang'],    
  data() {
    return {
    };
  },
    components: {
        commonInfo2
    },
   computed: {
    modle() {
      return window.configObj.officialModel
    },     
    selectedLangMetas() {
      return {
        artical: {
            articalItems: [
                {
                    t: this.$t('21'),
                    desp: this.$t('22'),
                    icons: []
                },
                {
                    t: this.$t('23'),
                    des: this.$t('24'),
                    icons: [
                        {
                            img: require('../assets/img/icons/deal_1.png'),
                            name: 'HuoBi'
                        },
                        {
                            img: require('../assets/img/icons/deal_2.png'),
                            name: 'BIANACE'
                        },
                        {
                            img: require('../assets/img/icons/deal_3.png'),
                            name: 'OKEX'
                        },
                        {
                            img: require('../assets/img/icons/deal_4.png'),
                            name: 'COINBASE'
                        },
                        {
                            img: require('../assets/img/icons/deal_5.png'),
                            name: 'more'
                        }
                    ]
                },
                {
                    t: this.$t(25),
                    icons: [],
                    mainPic: require('../assets/img/deal_main_x.png')
                },
                {
                    t: this.$t(251),
                    des: '',
                    icons: [
                        {
                            img: require('../assets/img/icons/deal_6.png'),
                            name: this.$t(252),
                            des: this.$t(26)
                        },
                        {
                            img: require('../assets/img/icons/deal_7.png'),
                            name: 'C2C',
                            des: `${this.$t(27)}, ${this.$t(28)}`
                        },
                        {
                            img: require('../assets/img/icons/deal_8.png'),
                            name: 'OTC',
                            des: `${this.$t(281)}\n ${this.$t(282)}`
                        }
                    ]
                },
                {
                    t: this.$t(29),
                    des: '',
                    dealModel: true,
                    icons: [
                        {
                            img: require('../assets/img/icons/deal_9.png'),
                            name: this.$t(30),
                        },
                        {
                            img: require('../assets/img/icons/deal_10.png'),
                            name: this.$t(31),
                        },
                        {
                            img: require('../assets/img/icons/deal_11.png'),
                            name: this.$t(32),
                        },
                        {
                            img: require('../assets/img/icons/deal_12.png'),
                            name: this.$t(33),
                        }
                    ]
                },
                {
                    t: this.$t(34),
                    des: '',
                    purple: true,
                    icons: [
                        {
                            img: require('../assets/img/icons/deal_13.png'),
                            name: this.$t(35),
                            des: this.$t(36)
                        },
                        {
                            img: require('../assets/img/icons/deal_14.png'),
                            name: this.$t(38),
                            des: this.$t(39)
                        },
                        {
                            img: require('../assets/img/icons/deal_15.png'),
                            name: this.$t(40),
                            des: this.$t(41)
                        },
                        {
                            img: require('../assets/img/icons/deal_16.png'),
                            name: this.$t(42),
                            des: this.$t(43)
                        },
                        {
                            img: require('../assets/img/icons/deal_17.png'),
                            name: this.$t(44),
                            des: ''
                        },
                        {
                            img: require('../assets/img/icons/deal_18.png'),
                            name: this.$t(46),
                            des: this.$t(47)
                        }
                    ]
                },
                {
                    t: this.$t(48),
                    des: this.$t(49),
                    icons: [],
                    mainPic: require('../assets/img/合约交易-服务支持.png'),
                }
            ]
        }
      };
    },
    selectedLangMetas2() {
      const metas = {};
      metas.deal_1 = {
        type: "center",
        class: "deal deal-1",
        modle2title: require("../assets/img/标题1.png"),
        modle2titleImg: '',
				modle2text: this.$t('22'),
        title: this.$t("72"),
      };
      metas.deal_2 = {
        type: "center",
        class: "deal deal-2",
				modle2Reverse: true,
        modle2title: require("../assets/img/标题2.png"),
        modle2titleImg: require("../assets/img/合约交易_图1.png"),
        title: this.$t("78"),
        des1_bottom: ""
      };
      metas.deal_3 = {
        type: "spaceAround",
        class: "deal deal-3",
        modle2title: require("../assets/img/标题3.png"),
        modle2titleImg: require("../assets/img/合约交易_图2.png"),
        title: this.$t("85"),
        title2: this.$t("86"),
        des: this.$t("87"),
        // img: require("../assets/img/bottom-d-right.png")
      };
        metas.deal_4 = {
				type: "spaceAround",
        class: "deal deal-4",
				modle2Reverse: true,	
        modle2title: require("../assets/img/标题4.png"),
        modle2titleImg: require("../assets/img/合约交易_图3.png"),
        title: 'APP',
        title2: this.$t("86"),
        des: this.$t("87"),
        // img: require("../assets/img/bottom-d-right.png")
      };
      return metas;
    },
  }
};
</script>

<style lang="scss" scoped>
.wraper {
  margin: 0 0 100px;
  .row {
    justify-content: space-around;
  }
  .wraper-box {
    display: flex;
    align-items: center;
    text-align: left;
    background: #F4F5FD;
    // background: green;
    flex-wrap: wrap;
    padding: 0;
    .title-box {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      .title-box-text {
        width: 85%;
        max-width: 1200px;
        .title {
          font-size: 95px;
          font-weight: 500;
          margin-bottom: 40px;
        }
        p {
          font-size: 15px;
          margin: 15px 0;
        }
      }
    }
    .content-box {
      width: 100%;
      color: #222;
      display: flex;
      justify-content: center;
    //   padding-top: 119px;
      .content-box-text {
        width: 100%;
        text-align: center;
        .des-big {
            width: 100%;
            display: inline-flex;
            justify-content: center;
            padding: 0 15px;
            transform: translateY(-200px);
            .des-title-box {
                display: inline-flex;
                flex-wrap: wrap;
                font-size: 24px;
                max-width: 1200px;
                // align-items: center;
                justify-content: center;
                margin: 63px 0 60px 0;
                .des {
                    text-align: left;
                }
                h1 {
                    width: 100%;
                    text-align: center;
                    margin: 40px 0;
                }
                .des-title-icon{
                width: 40px;
                height: 40px;
                margin-right: 10px;
                }
                &.first-box{
                    background: #fff;
                    border: 1px solid #222;
                    border-radius: 30px;
                    padding: 20px 40px 60px;
                    box-shadow: 8px 8px 20px 0px #0000008f;
                }
                .icon-box {
                    display: inline-flex;
                    margin: 80px 0 ;
                    width: 100%;
                    .img-box {
                        flex: 1 1 30%;
                        background: #fff;
                        margin: 0 40px;
                        text-align: center;
                        font-size: 18px;
                        border-radius: 10px;
                        box-shadow: 8px 8px 20px 0px #00000040;
                        img {
                            padding: 20px;
                            width: 100%;
                            max-width: 150px;
                        }
                        .img-box-des {
                           text-align: center;
                            font-size: 16px;
                            // max-width: 200px;
                        }
                        &.img-box-padding {
                            padding: 47px;
                        }
                        &.img-box-color-l {
                            flex: 1 1 40%;
                            margin: 20px;
                            padding: 17px;
                            color: #fff;
                            border-radius: 0 50px 0 50px;
                            background: #6885f9;
                        }
                        &.img-box-color-r {
                            flex: 1 1 40%;
                            margin: 20px;
                            border-radius: 50px 0 50px 0;
                            background: #00e1b4;
                        }
                        &.img-box-color-bl {
                            flex: 1 1 40%;
                            margin: 20px;
                            border-radius: 50px 0 50px 0;
                            background: #fb9c48;
                        }
                        &.img-box-color-br {
                            flex: 1 1 40%;
                            margin: 20px;
                            border-radius: 0 50px 0 50px;
                            background: #996ffa;
                        }
                    }
                    .img-box-purple {
                            text-align: center;
                            font-size: 18px;
                            flex: 1 1 30%;
                            margin: 20px;
                            padding: 17px;
                            color: #fff;
                            border-radius: 0 50px 0 50px;
                            background: transparent;
                        img {
                            padding: 20px;
                            width: 100%;
                            max-width: 180px;
                        }
                        .img-box-des {
                           text-align: center;
                            font-size: 16px;
                            // max-width: 200px;
                        }
                    }
                    &.icon-box-hasColor {
                        flex-wrap: wrap;
                    }
                    &.icon-box-purple {
                        margin: 20px 0;
                    }
                }
                .mainPic {
                    margin: 30px 0;
                    width: 80%;
                }
            }
            &.des-big-w {
                background: #fff;
            }
            &.des-big-pur {
                background: #5B32BF;
                color: #fff;
            }
        }
        .des {
          color: #999;
          font-size: 16px;
          text-align: left;
          .title {
            padding-left: 8px;
            transform: translateX(-12px);
            margin: 50px 0 15px;
            font-size: 22px;
            color: #fff;
          }
          .margin-top-little {
            margin: 15px 0 15px;
          }
        }
      }
    }
  }
}
.deal {
    margin: 0;
}
.forloop-box {
  display: flex;
  justify-content: space-around;
  margin: 80px auto;
  .forloop-items {
    display: flex;
    flex-direction: column;
    width: 20vw;
    max-width: 300px;
    .text {
      background-color: #00d8b4;
      padding: 15px;
    }
  }
}
.deal {
		.wraper-box {
			.title-box {
					height: 78vh;      
					background-image: url(../assets/img/合约交易-banner.png);
			}
			.title-box-2 {
					height: 70vh;      
					background-image: url(../assets/img/banner_2.png);
						.title-box-text {
									.title {
											font-size: 75px;
									}
						}
			}
			.content-box-2{
				flex-wrap: wrap;
					.content-box-text{
							.des-big {
									transform: translateY(0);
							}
					}
					.modle-2-footer-des{
						padding: 20px;
						margin-bottom: 100px;
						color:#fff;
						display: flex;
						justify-content: space-around;
						width: 100%;
						max-width: 1200px;
						.deal-footer-items {
							width: 200px;
						}
					}
						.deal-footer-2{
							width: 100%;
							text-align: center;
							color:#fff;
							margin-bottom: 100px;
					}
			}
		}
		&.m0{
			.wraper-box {
					background: #1d1d37;
			}
		}
	}

@media (max-width: 1200px) {
  .deal {
      margin: 0 0 10px;
    .wraper-box {
      .title-box {
        background-position-x: 77%;
        height: 50vh;
        .title-box-text {
          text-align: left; 
           padding: 0 8px;  
          h1{  
          font-size: 18px;
          color: rgb(215, 215, 215);
          }
          .title {
            font-size: 40px;
            margin-bottom: 0;
            color: #fff;
          }
        }
      }
      .content-box .content-box-text{
          .des-big{
          transform: translateY(0px);
            .des-title-box {
                width: 100%;
                padding: 0 10px;
                font-size: 18px;
                margin: 40px 0;
                .des-title {
                    font-size: 30px;
                    margin: 10px 0;
                }
                .icon-box {
                    flex-wrap: wrap;
                    margin: 30px 0;
                    .img-box {
                        margin: 10px 40px;
                    }
                    .img-box-purple {
                        margin: 10px;
                        padding: 0px;
                        .img-box-des {
                            font-size: 14px;
                        }
                    }
                }
            }
          }
      }
    }
  }
}
</style>