<template>
    <div class="howworks text-center" :style="{background: bgColor}">
      <div class="v-container">
        <h2 class="title">{{ $t(1126) }}</h2>
        <p class="sub-title">{{$t(11261)}}</p>
        <el-row :gutter="20">
          <el-col :xl="8" :lg="8" :md="12" v-for="(item, i) in banners" :key="i">
            <div class="work-item" @click="bannerClick(item)">
               <div>
                 <img :src="item.bannerUri" />
               </div>
              <h2 v-if="item.title">{{ item.title }}</h2>
              <span v-if="item.summary">{{ item.summary }}</span>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
</template>

<script>
import { getCarousels } from "@/api";    
export default {
    data() {
        return {
            banners: [],
            showBannerContent: false,
            curContent: {}
        }
    },
    props: {
      bgColor: {
        type: String,
        default: '#0E0E22'
      }
    },
    beforeMount() {
        this.getBanners();
    },
    methods: {
        bannerClick(item) {
            if (!item.extra) return
            this.curContent = item
            this.showBannerContent = true
            this.$emit('banner', this.curContent)
        },
        getBanners() {
            getCarousels().then((res) => {
                this.banners = res.list || [];
            });
        },
    }
}    
</script>

<style lang="scss" scoped>
    @media screen and (max-width: 1200px) {
        .work-item {
            margin-top: 30px!important;
        }
    }
.howworks{
  // background: #0E0E22;
  padding: 40px 0;
  padding-bottom: 120px;
  h2{
    // margin-bottom: 40px;
    color: #fff;
    font-size: 36px;
    font-weight: 700;
  }
  .sub-title {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .work-item{
    img{
      width: 100%;
      margin-bottom: 20px;
      cursor: pointer;
      transition: all .4s;
      border-radius: 4px;
      &:hover{
        transform:scale(1.05);
      }
    }
    span{
      font-size: 16px;
      color: #666;
      line-height: 24px
    }
  }
}
</style>