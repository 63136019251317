<template>
 <div class="download-area">
    <div class="v-container down-box">
      <div class="down-left">
        <div class="down-info">
          <div>
            <h1>{{$t('1185')}}?</h1>
          </div>
          <div>
            <h3>{{$t('1186')}}</h3>
          </div>
          <div class="but-box">
            <div class="but" @click="goToDownload">
              IOS {{$t('1127')}}
              <img src="~@/assets/img/home7/down3.png" />
            </div>
            <div class="but" @click="goToDownload">
              Android {{$t('1127')}}
              <img src="~@/assets/img/home7/down1.png" />
            </div>
            <div class="but" @click="goToH5">
              H5 {{$t('1128')}}
              <img src="~@/assets/img/home7/down2.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="down-right">
        <img src="~@/assets/img/home7/iPhone.png" />
      </div>
    </div>    
  </div>   
</template>

<script>
export default {
    computed: {
        downloadUrl() {
            return window.configObj.downloadUrl
        }
    },
    methods: {
        goToDownload() {
            window.location.href = window.configObj.downloadUrl;
        },
        goToH5() {
            window.location.href = location.origin + "/h5/";
        },
    }
}    
</script>

<style lang="scss" scoped> 
    
.download-area{
  width: 1720px;
  background: #291029;
  height: 500px;
  margin: 0 auto;
  color: #fff;
  .down-box{
    display: flex;
    .down-left{
      flex: 1;
      display: flex;
      align-items: center;
      height: 500px;
      h1{
        font-size: 40px;
        font-weight: bold;
      }
      h3{
        font-size: 16px;
        padding: 30px  0;
      }
      .down-info{
      }
      .but-box{
        display: flex;
        .but{
          width: 220px;
          height: 58px;
          background: #fff;
          display: flex;
          color: #000;
          cursor: pointer;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          margin-right: 15px;
          img{
            width: 24px;
            margin-left: 15px;
          }
        }
      }
    }
    .down-right{
      flex: 1;
      height: 500px;
      img{
        height: 90%;
        position: relative;
        left: -20px;
        top: 5%;
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .download-area{
    width: 100% !important;
    height: auto !important;
    .down-right{
      display: none !important;
    }
    .down-left{
      display: block !important;
    }
    .down-box{
      display: block !important;
    }
    .but-box{
      display: block !important;
      .but{
        margin-top: 15px;
      }
    }
  }
}
</style>