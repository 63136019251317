
<template>
  <modal
    :visible="value"
    @onClosed="doClose"
  >
    <span slot="head">{{ content.title||content.summary }}</span>
    <div slot="body">
        <div v-html="content.extra"></div>
    </div>
  </modal>
</template>
<script>
import Modal from '@/components/Modal'
import utils from '@/utils/misc.js'
export default {
  name: 'BannerModal',
  components: {Modal},
  props: {
    value: Boolean,
    content: Object
  },

  data() {
    return {
        curLang: utils.getLs('locale')||window.configObj.defaultLang||'en-US',
    }
  },
  computed: {
  },
  methods: {
    doClose() {
      this.$emit('input', false)
    }
  }
}
</script>
<style lang="scss" scoped>
</style>