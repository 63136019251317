<!--公共的 dialog modal-->

<!--
modal slot
head, modal 标题区的内容&ndash;&gt;
body, modal 的内容
foot, modal 按钮操作区的内容
-->

<template>
<div>
    <div class="dialog" :class="{'large': size === 'large', 'xlarge': size === 'xlarge'}">
      <div class="dialog-header">   
        <span slot="title">
            <slot name="head">
            </slot>
        </span>
         <div class="dialog__close el-icon-close" @click="close"></div>
      </div>

      <slot name="body"></slot>

      <span slot="footer">
        <slot name="foot"></slot>
      </span>
    </div>

  <!-- 點背景可以關閉 -->
   <div class="dialog-bg-1" @click="close"/>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "Dialog",
  props: {
    size: {
      default: "large",
      type: String,
    }
  },

  data() {
    return {};
  },

  computed: {
 
  },

  methods: {
    close() {
      this.$emit("onClosed");
    }
  },
};
</script>

<style lang="scss">
$pd: 20px;
.xsmall {
  width: 470px;
}
.small {
  width: 570px;
}
.large {
  width: 800px;
}
.xlarge {
  width: 1200px;
}
.dialog-bg-1 {
   z-index: 1999;
   position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;
   background: #0000008c;
   display: flex;
   align-items: center;
   justify-content: center;  
}
 .dialog {  
    z-index: 2000;
    position: fixed;
    top: 50%;
	margin: 0 auto;
    right: 0;
    bottom: 0;
    left: 0;
    transform:(translateY(- 50%));
    min-width: 800px;
    height: 580px;
    background: #fff;
    box-shadow: 0 1px 3px #999;
    border-radius: 10px;
    .dialog-header {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 0px 20px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        // background: $primaryColor;
        line-height: 70px;
        }
        .dialog__close {
            cursor: pointer;
            font-weight: 100 !important;
            font-size: 20px;
            display: flex;
            align-items: center;
        }

        .dialog__body {
        padding: 20px $pd 0;
        max-height: 60vh;
        overflow-y: scroll;
        }

        .dialog__footer {
        padding-left: $pd;
        padding-right: $pd;
        padding-bottom: $pd;
        .button {
            min-width: 80px;
            font-size: 14px;
        }
    }
 }

.form-item {
  margin-bottom: 20px;
}

//统一设置弹窗内 文本域的高度
.textarea {
  textarea {
    height: 75px;
    min-height: 75px;
  }
}

//弹窗中的日期，统一显示100%
.date-editor {
  &.el-input {
    width: 100%;
  }
}

//限制宽度，超过显示...
.head-long {
  display: block;
  width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
