<template>
 <div class="download-area">
      <div class="title-box">
          <h1>{{$t('1165')}}</h1>
          <h3>{{$t('1169')}}</h3>
      </div>
      <div class="download-info">
        <div class="left-box">
          <div class="qr-code-box">
            <div class="qr-code-left">
              <vue-qr :size="180" :text="downloadUrl" style="width: 80px; height: 80px;"></vue-qr>
            </div>
            <div class="qr-code-right">
              <p>{{$t('1165')}}</p>
              <div>iOS & Android</div>
            </div>
          </div>
          <div @click="goToDownload" class="down-but active-but">IOS {{$t('1127')}}</div>
          <div @click="goToDownload" class="down-but active-but">ANDROID {{$t('1127')}}</div>
          <div @click="goToH5" class="down-but">H5 {{$t('1128')}}</div>
        </div>
        <div class="right-box">
          <img src="@/assets/img/home6/Mockup.png" />
        </div>
      </div>
      <div class="downloads">
        <ul>
          <li>
            <p>+43 {{$t('1143')}}</p>
            <div>{{$t('1115')}}</div>
          </li>
          <li>
            <p>+{{$t('1116')}}</p>
            <div>{{$t('1117')}}</div>
          </li>
          <li>
            <p>< 0.10%</p>
            <div>{{$t('1118')}}</div>
          </li>
        </ul>
      </div>
    </div>   
</template>

<script>
import vueQr from 'vue-qr'
export default {
    components: {
        vueQr 
    },
    computed: {
        downloadUrl() {
            return window.configObj.downloadUrl
        }
    },
    methods: {
        goToDownload() {
            window.location.href = window.configObj.downloadUrl;
        },
        goToH5() {
            window.location.href = location.origin + "/h5/";
        },
    }
}    
</script>

<style lang="scss" scoped> 
    
.download-area{
  .title-box{
    margin-top: 150px;
    h1{
      color: #fff;
    }
    h3{
      color: #8393AF;
      margin-top: 20px;
    }
  }
  .download-info{
    display: flex;
    margin-top: 100px;
    .left-box{
      width: 40%;
      .qr-code-box{
        display: flex;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 12px;
        width: 350px;
        padding: 15px;
        align-items: center;
        .qr-code-left{
          img{
            width: 80px;
            height: 80px;
          }
        }
        .qr-code-right{
          flex: 1;
          padding-left: 20px;
          div{
            font-weight: bold;
            font-size: 18px;
            margin-top: 10px;
          }
        }
      }
      .down-but{
        width: 350px;
        height: 60px;
        // background: red;
        border-radius: 10px;
        margin-top: 15px;
        border: 1px solid #FD5A79;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #FD5A79;
        cursor: pointer;
        &:hover{
          background: linear-gradient(207.67deg, #FDAE8F 3.43%, #FD1C68 104.7%);
          border: none;
          color: #fff;
        }
      }
      .active-but{
        background: linear-gradient(207.67deg, #FDAE8F 3.43%, #FD1C68 104.7%) !important;
        border: none !important;
        color: #fff !important;
      }
    }
    .right-box{
      img{
        height: 500px;
        margin-top: -80px;
      }
    }
  }
  .downloads{
    margin-top: 30px;
    ul{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      li{
        width: 30%;
        padding: 30px 0;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 12px;
        text-align: center;
        div{
          margin-top: 5px;
        }
        p{
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
  }
}
@media screen  and (max-width: 1000px) {
  .right-box{
    display: none;
  }
  .downloads{
    margin-top: 30px;
    ul{
      li{
        width: 100% !important;
        padding: 30px 0;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 12px;
        text-align: center;
        margin-top: 10px;
        div{
          margin-top: 5px;
        }
        p{
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>