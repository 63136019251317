<template>
  <section :class="datas.class" class="wraper">
    <div class="container">
      <div class="active animated " v-animated="{ className: 'fadeInRight' }">

        <div v-if="datas.type === 'table'" class="table">
             <simpleTable :tableDatas="tData" />
        </div>

        <div v-else class="spaceAround-content" :class="{'reverse': datas.modle2Reverse}">
          <div class="left">
            <div>
              <img :src="datas.modle2title" />  
              <h1>{{ datas.title }}</h1>
              <div v-if="datas.class === 'home-4'" class="des">
                <h3>{{$t("81")}}</h3>
                <p class="mt10">{{$t("82")}}</p>
               </div>
            </div>
            <div v-if="datas.class === 'home-6'" class="col-12 forloop-box">
              <div class="info">
                <h3>{{ datas.title2 }}</h3>
              </div>
              <div
                v-for="(item, i) in datas.items"
                :key="i"
                class="forloop-items pointer"
                @click="goDownload(i)"
              >
                <img :src="item" />
              </div>
            </div>
          </div>
          <div class="right">
            <img v-if="datas.modle2titleImg" :src="datas.modle2titleImg" alt="" />
            <p v-if="datas.modle2text">{{datas.modle2text}}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import simpleTable from "../components/SimpleTable";
import { createSocket, sendWSPush } from '../utils/socket'
export default {
  name: "CommonInfo",
  props: ["datas"],
  data() {
    return {
      coinList: [
        {
          name: 'USDT', channel: 'top_all_usdt'
        },
        {
          name: 'QCC', channel: 'top_all_qc'
        },
        {
          name: 'BTC', channel: 'top_all_btc'
        },
        {
          name: 'LTC', channel: 'top_all_ltc'
        }],
      currentCoin: 'top_all_usdt',
      tData:{  
      tableHead: this.datas ? this.datas.tableHead : [],
      tableData: [
        {
          name: "BTC / USDT",
          icon: "btc",
          price: "43828.87",
          time: -9.87,
          timeOk: "43828.87",
          datImg: 2,
        },
        {
          name: "USDT / USDT",
          icon: "usdt",
          price: "43828.87",
          time: 9.87,
          timeOk: "43828.87",
          datImg: 1,
        },
        {
          name: "ETH / USDT",
          icon: "eth",
          price: "43828.87",
          time: 9.87,
          timeOk: "43828.87",
          datImg: 1,
        },
        {
          name: "BTC / USDT",
          icon: "btc",
          price: "43828.87",
          time: 9.87,
          timeOk: "43828.87",
          datImg: 1,
        },
        {
          name: "BTC / USDT",
          icon: "btc",
          price: "43828.87",
          time: 9.87,
          timeOk: "43828.87",
          datImg: 1,
        },
      ]},
    };
  },
  created() {
    createSocket()
  },
  beforeMount() {
    this.tabCoin(this.currentCoin)
  },
  methods: {
    goDownload(i) {
      if (i !== 2) {
        location.href = window.configObj.downloadUrl
      } else {
        location.href = location.protocol+(process.env.NODE_ENV === 'development' ? '': '//www.')+window.configObj.domainUrl + '/h5/'
      }
    },
    getDataByName(name, list) {
      for (const item of list) {
        if (item.market.indexOf(name) > -1) {
          return item
        }
      }
    },
    tabCoin(channel) {
      this.currentCoin = channel
      var params = {
        event: 'addChannel',
        channel: channel
      }
      sendWSPush(params)
      window.addEventListener('onmessageWS', (e) => {
        var data = JSON.parse(e.detail.data.data.replace('(', '').replace(')', ''))
        if (data.channel === this.currentCoin) {
          const hotCoins = ['btc','eth','fil','eos','doge']
          this.tData.tableData = hotCoins.map(hot => {
            let curObj
            for (let item of data.datas) {
              if (item.showName.toLowerCase() === hot) {
                curObj = {
                  name: `${item.showName} / USDT`,
                  icon: hot,
                  price: `$${item.lastPrice}`,
                  time: item.riseRate+'%',
                  timeOk: item.vol,
                  datImg: item.riseRate>0?1:2,
                }
                break;
              }
            }
            return curObj
          })
          this.$store.quotes = data.datas
        }
      })
    }
  },
  computed: {},
  components: {simpleTable},
};
</script>

<style lang="scss" scoped>
.wraper {
  background: #1d1d37;
  color: #fff;
  padding: 60px 0;
  .content {
    text-align: center;
    .forloop-box {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 80px auto;
      h1 {
        font-size: 30px;
        color: #222;
        width: 100%;
        margin: 15px 0 80px;
      }
      .forloop-items {
        cursor: pointer;
        flex: 0 1 30%;
        margin: 0 5px;
        // max-width: 400px;
        padding: 0 10px;
        img {
          width: 100%;
        }
      }
    }
  }
 
  .spaceAround-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .left {
      width: 50%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      h1 {
        font-size: 30px;
        width: 100%;
        margin: 15px 0 0 180px;
        transform: translateY(-30px);
      }
      .des {
          margin: 15px 0 0 180px;
      }
      .forloop-box {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 15px 0 0 180px;
        .info {
          width: 100%;
          margin-bottom: 30px;
        }
        .forloop-items {
          flex: 0 1 30%;
          //   margin: 0 5px;
          img {
            width: 100%;
          }
        }
      }
    }
    .right {
      width: 36%;
      padding: 15px 15px 15px 30px;
      img {
        width: 100%;
      }
    }
    &.reverse{
        flex-direction: row-reverse;
    }
  }
}
.deal {
    margin: 0;
		.left  img{
			width: 321px !important; 
		}
        .right p {
            text-align: left;
        }
}
.home-4 .content .forloop-box .forloop-items {
  flex: 0 1 30%;
  margin: 0 9px;
  padding: 0 25px;
  h2 {
    margin: 20px 0 10px;
  }
  span {
    margin: 10px 0;
  }
  img {
    max-width: 160px;
  }
}
.home-5 {
  //   background: no-repeat center/cover url(../assets/img/banner_4.jpg);
  .post {
    text-align: center;
    align-items: center;
    height: 500px;
  }
}

.home-6 {
  //   background: no-repeat center/cover url(../assets/img/banner_5.jpg);
  .post {
    text-align: left;
    width: 500px;
    align-items: center;
    height: 300px;
    .text-box .title {
      font-size: 30px;
    }
  }
}
@media (max-width: 1200px) {
  .wraper {
    padding: 10px 0;
    .text {
      padding: 50px 0 20px;
      .left-side {
        margin-bottom: 30px;
        .title {
          &::before {
          }
        }
      }
      .right-side {
        display: inline-flex;
        flex-wrap: nowrap;
        width: 100%;
        justify-content: center;
        .btns {
          padding: 5px 10px;
          font-size: 10px;
          color: #fff;
          border: 1px solid #ffb738;
          cursor: pointer;
        }
        .btns-selected {
          color: #ffb738;
        }
        .btns-left {
          border-radius: 30px 0 0 30px;
        }
        .btns-right {
          border-radius: 0 30px 30px 0;
        }
      }
    }
    .content {
      .forloop-box {
        margin: 20px auto;
        h1 {
          margin: 10px 0;
        }
        .forloop-items {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          margin: 10px;
          padding: 5px 0;
          //   max-width: 50vw;
          flex: 0 0 100%;
          img {
            // height: 70px;
          }
          .text {
            display: flex;
            flex-direction: column;
            padding: 10px 0;
            .title {
              margin: 20px 0;
              font-size: 16px;
            }
          }
        }
      }
      .des-bottom {
        font-size: 15px;
        max-width: 900px;
        margin: 0 auto;
        color: rgb(196, 196, 196);
      }
    }
    .spaceAround-content {
        flex-wrap: wrap;
        margin: 20px 0;
        .left{
            width: 100%;
            display: flex;
            justify-content: center;
            h1 {
                margin: 10px 0;
            }
            .forloop-box {
                margin: 10px auto;
            }
            .des {
                margin: 0;
                width: 80vw;
            }
        }
        .right{
            width: 100%;
            display: flex;
            justify-content: center;
            img {
              max-width: 400px;  
            }
        }
    }
  }
  .home-3 {
    // margin-bottom: 150px;
  }
  .home-4 {
    // height: 96vh;
    display: flex;
    align-items: center;
    // margin-bottom: 37px;
  }
  .home-6 {
    margin: 0;
    background-size: contain;
    background-position: bottom;
    .post {
      width: 100%;
      text-align: center;
      align-items: baseline;
      height: 175px;
      .text-box .title {
        font-size: 22px;
      }
    }
  }
}
</style>