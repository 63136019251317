<template>
  <section class="tp-banner-container">
    <div class="tp-banner" :class="{'timex': isTimeX}">
      <div>
        <h1>{{ $t("homeTitle_1_1") }}</h1>
      </div>
      <div>
        <h3 class="text-grey">{{ $t("homeTitle_1_2") }}</h3>
      </div>
      <div class="but-box">
        <div class="but but-bg" @click="goToH5">H5 {{$t('1128')}}</div>
        <div class="but" @click="goToDownload">IOS {{$t('1127')}}</div>
        <div class="but" @click="goToDownload">Android {{$t('1127')}}</div>
      </div>
    </div>
  </section>

</template>

<script>
export default {
  props: ['modle', 'isTimeX'],
  methods: {
    goToDownload() {
      window.location.href = window.configObj.downloadUrl;
    },
    goToH5() {
      window.location.href = location.origin + "/h5/";
    }
  }
}   
</script>

<style lang="scss" scoped>
.tp-banner-container {
  width: 100%;
  margin: 0 auto;
  background: url("~@/assets/img/home8/bj.png") no-repeat;
  background-size: cover;
  height: 1000px;

  // padding-top: 1000px;
  .tp-banner {
    padding-left: 40%;
    padding-top: 200px;
    h1 {
      font-size: 52px;
      width: 600px;
      font-family: Arial-Black;
      font-weight: bold;
    }

    h3 {
      font-size: 24px;
      color: #91929E;
      width: 600px;
      margin-top: 50px;
    }

    .but-box {
      display: flex;
      margin-top: 50px;

      .but {
        width: 220px;
        height: 60px;
        font-size: 18px;
        font-weight: bold;
        border: 2px solid #81EEDC;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        cursor: pointer;
        color: #81EEDC;

        // &:hover {
        //   background: #81EEDC;
        // }
      }

      // .but-bg {
      //   background: #81EEDC;
      // }
    }
  }
}

@media screen and (max-width: 1000px) {
  .tp-banner-container {
    .tp-banner{
      padding: 10px !important;
      h1{
        width: 100%;
        padding-top: 120px !important;
      }
    }
    .but-box{
        display: block !important;
      .but{
        width: 100% !important;
        margin-top: 20px;
      }
    }
  }
}
</style>
